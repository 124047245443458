<script setup lang="ts">
import type { DeviceI, OperationI } from "@lxc/app-device-types";
import {
  AppDeviceState,
  OperationState,
  OperationType,
} from "@lxc/app-device-types";
import type { Ref } from "vue";
import deviceService from "~/services/device.service";
import { useUserSession } from "~/stores/useUserSession";
import { ObjectType } from "~/types";
import { Roles } from "~/types/roles";
import LxcError from "~/utils/LxcError";
import {
  NotificationKey,
  showNotificationError,
  showNotificationSuccess,
} from "~/utils/notifications-tools";

const props = defineProps<{
  device: DeviceI;
  operation: OperationI;
}>();
const emit = defineEmits(["change"]);

const { t } = useI18n();
const store = useUserSession();

const isCancellable = ref(false);
const isEditable = ref(false);
const isAllowedToUpdate = computed(
  () =>
    props.device.state === AppDeviceState.ACTIVATED &&
    ((props.operation.definition.type === OperationType.FIRMWARE_UPGRADE_DVC &&
      store.userSession?.roles.includes(Roles.DVTM_DVT_FIRM_UPDATE)) ||
      (props.operation.definition.type === OperationType.CRTCLT_RENEWAL_DVC &&
        store.userSession?.roles.includes(Roles.DVTM_DVT_CRT_RENEW))),
);

function checkOperationStatus() {
  switch (props.operation.definition.state) {
    case OperationState.PENDING:
    case OperationState.RUNNING:
    case OperationState.RETRY:
      isCancellable.value = isAllowedToUpdate.value ?? false;
      break;
  }
  if (props.operation.definition.state === OperationState.PENDING) {
    isEditable.value = isAllowedToUpdate.value ?? false;
  }
}

const [confirmCancelOperationDialogVisible, toggleConfirmCancelVisible] =
  useToggle();
const [
  updateCertificateOperationDialogVisible,
  toggleUpdateCertificateOperationVisible,
] = useToggle();
const [
  updateFirmwareOperationDialogVisible,
  toggleUpdateFirmwareOperationVisible,
] = useToggle();

const operationIdArray: Ref<string[]> = ref([]);
async function cancelOperation(deviceId: string, operationId: string) {
  operationIdArray.value.push(operationId);
  const cancelOperationsResponse = await deviceService.cancelOperations(
    deviceId,
    operationIdArray.value,
  );

  if (LxcError.check(cancelOperationsResponse)) {
    showNotificationError(t(NotificationKey.error));
  } else {
    showNotificationSuccess(t(NotificationKey.success));
    toggleConfirmCancelVisible();
    emit("change");
  }
}

enum Action {
  CANCEL = "CANCEL",
  UPDATE = "UPDATE",
}

function onSelectAction(action: Action) {
  switch (action) {
    case Action.CANCEL:
      toggleConfirmCancelVisible();
      break;
    case Action.UPDATE:
      if (
        props.operation.definition.type === OperationType.CRTCLT_RENEWAL_DVC
      ) {
        toggleUpdateCertificateOperationVisible();
      } else {
        toggleUpdateFirmwareOperationVisible();
      }
      break;
    default:
      break;
  }
}

onMounted(checkOperationStatus);
</script>
<template>
  <lxc-dropdown @command="onSelectAction">
    <el-dropdown-item :command="Action.CANCEL" :disabled="!isCancellable">
      {{ t("device.operation.cancel.tooltip.enabled") }}
    </el-dropdown-item>
    <el-dropdown-item :command="Action.UPDATE" :disabled="!isEditable">
      {{ t("device.operation.update.tooltip.enabled") }}
    </el-dropdown-item>
  </lxc-dropdown>

  <lxc-confirm-modal
    :is-dialog-visible="confirmCancelOperationDialogVisible"
    :title="t('device.operation.cancel.confirm.title')"
    :description="t('device.operation.cancel.confirm.description')"
    @cancel="toggleConfirmCancelVisible"
    @confirm="cancelOperation(device.id!, operation.definition.id)"
  />
  <lxc-update-certificate-modal
    :is-dialog-visible="updateCertificateOperationDialogVisible"
    :object="device"
    :object-type="ObjectType.DEVICE"
    is-operation-update
    :operation="operation"
    @update:toggle-dialog="toggleUpdateCertificateOperationVisible"
    @change="$emit('change')"
  />
  <lxc-update-firmware-modal
    :is-dialog-visible="updateFirmwareOperationDialogVisible"
    :object="device"
    :object-type="ObjectType.DEVICE"
    is-operation-update
    :operation="operation"
    @update:toggle-dialog="toggleUpdateFirmwareOperationVisible"
    @change="$emit('change')"
  />
</template>
