<script setup lang="ts">
import type { DeviceMetadataModule } from "./LxcDeviceMetadataModuleList.vue";
import type {
  DeviceI,
  DeviceMetadataComModuleI,
  DeviceMetadataIoModuleI,
} from "@lxc/app-device-types";

const props = defineProps<{
  device: DeviceI;
}>();

const { t } = useI18n();

const options = computed(() => {
  const options: string[] = [];

  if (props.device.metadata?.options?.software) {
    for (const option of props.device.metadata.options.software) {
      if (option.value) {
        options.push(
          t(`device.additionalMetadata.options.software.${option.key}`),
        );
      }
    }
  }

  if (props.device.metadata?.options?.numericSoftware) {
    for (const option of props.device.metadata.options.numericSoftware) {
      options.push(
        t(`device.additionalMetadata.options.numericSoftware.${option.key}`, {
          value: option.value,
        }),
      );
    }
  }

  return options;
});

const optionsLeft = computed(() =>
  options.value.slice(0, Math.ceil(options.value.length / 2)),
);
const optionsRight = computed(() =>
  options.value.slice(Math.ceil(options.value.length / 2)),
);

const optionsRowsCount = computed(() =>
  Math.max(optionsLeft.value.length, optionsRight.value.length),
);

const comModules: ComputedRef<DeviceMetadataModule[]> = computed(() => {
  if (props.device.metadata?.communicationModules) {
    return props.device.metadata.communicationModules.map(
      (comModule: DeviceMetadataComModuleI) => {
        return {
          location: comModule.location,
          module: comModule.type,
        };
      },
    );
  } else {
    return [];
  }
});

const ioModules: ComputedRef<DeviceMetadataModule[]> = computed(() => {
  if (props.device.metadata?.ioModules) {
    return props.device.metadata.ioModules.map(
      (ioModule: DeviceMetadataIoModuleI) => {
        return {
          location: ioModule.location,
          module: ioModule.type,
        };
      },
    );
  } else {
    return [];
  }
});
</script>

<template>
  <div class="flex flex-col gap-8">
    <h3>{{ t("device.additionalMetadata.title") }}</h3>

    <div v-if="optionsRowsCount">
      <h5 class="text-gray-900 text-base">
        {{ t("device.additionalMetadata.options.title") }}
      </h5>

      <table class="bg-white w-full mt-5">
        <tr
          v-for="i in optionsRowsCount"
          :key="i"
          :class="
            (i % 2 !== 0 ? 'bg-gray-50 ' : '') +
            'text-gray-900 border-b text-sm'
          "
        >
          <td class="w-1/2 text-left py-2 px-4">
            <span v-if="optionsLeft[i - 1]">
              {{ optionsLeft[i - 1] }}
            </span>
          </td>
          <td class="w-1/2 text-left py-2 px-4">
            <span v-if="optionsRight[i - 1]">
              {{ optionsRight[i - 1] }}
            </span>
          </td>
        </tr>
      </table>
    </div>

    <div>
      <h3>{{ t("device.additionalMetadata.communicationModules.title") }}</h3>
      <lxc-device-metadata-module-list :modules="comModules" class="mt-5" />
    </div>

    <div>
      <h3>{{ t("device.additionalMetadata.modulesIo.title") }}</h3>
      <lxc-device-metadata-module-list :modules="ioModules" class="mt-5" />
    </div>
  </div>
</template>
