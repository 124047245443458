<script setup lang="ts">
import { DEFAULT_CHART_COLORS_HTML } from "@lxc/app-device-common";
import Chart from "chart.js/auto";
import "chartjs-adapter-luxon";
import zoomPlugin from "chartjs-plugin-zoom";
import { v4 as uuidv4 } from "uuid";
import { onMounted, ref, watch } from "vue";
import { NO_CONTENT } from "~/components/dtwins/dtwinsForm/LxcDtwinsFormType";
import { DEFAULT_CHARTJS_ZOOM_SUB_CONFIG } from "~/components/shared/LxcChartLocal.model";
import type { Serie, Value } from "~/types/chart";
import { DatasetType, Granularity, MAX_SERIES_BY_CHART } from "~/types/chart";
import ILxChartDatasetBar from "~icons/lxc-business/chart-dataset-bar";
import ILxChartDatasetLine from "~icons/lxc-business/chart-dataset-line";
import ILxcEyeOff from "~icons/lxc/eye-off";
import ILxcSettings from "~icons/lxc/settings";
import ILxcTrash2 from "~icons/lxc/trash-2";

const props = defineProps<{
  series: Serie[];
  height?: number;
  width?: number;
  locale?: string;
  // X scale properties, cf. https://www.chartjs.org/docs/3.9.1/axes/styling.html
  xScaleProperties?: any;
  // Plugin properties, cf. https://www.chartjs.org/docs/3.9.1/developers/plugins.html
  pluginProperties?: any;
}>();

const emit = defineEmits(["update:series"]);

const { t } = useI18n();
Chart.register(zoomPlugin);

const chartId = `chart-${uuidv4()}`;
const chartRef = ref<HTMLCanvasElement>();
let chartInstance: Chart | undefined;
const formatValueByDatasetLabel: any = {};
const selectedSerieColor: Ref<Record<string, string>> = ref({});
const selectedType: Ref<Record<string, DatasetType>> = ref({});
const showSettingsRef = ref<any>({});
const shouldDisplayShowSettings = (serie: Serie) =>
  showSettingsRef.value[serie.name] ?? false;
const updateShowSettings = (serie: Serie, state: boolean) =>
  (showSettingsRef.value[serie.name] = state);
const onSerieToolbarLeave = (
  event: MouseEvent,
  serie: Serie,
  state: boolean,
) => {
  const elements = document.elementsFromPoint(event.clientX, event.clientY);
  const colorPickerClass = "lxc-color-palette-picker-opened";
  const colorPickerSelector = `#${colorPickerClass}`;
  if (
    elements.every((currentElement) => {
      return (
        !currentElement.classList.contains(colorPickerClass) &&
        currentElement.closest(colorPickerSelector) === null
      );
    })
  ) {
    updateShowSettings(serie, state);
  }
};
const getTabContentStyle = (serie: Serie) => {
  const elementClassName = ["flex-1", "select-none", "w-full"];
  serie.isVisible
    ? elementClassName.push("text-black")
    : elementClassName.push("text-gray-400");
  return elementClassName;
};

/**
 * Compare two granularities and return the lowest one
 * Return undefined if one of the granularity is undefined or null
 */
const getMinGranularity = (
  granularity1: Granularity | undefined,
  granularity2: Granularity | undefined,
) => {
  const granularityPriority = [
    Granularity.MILLISECOND,
    Granularity.SECOND,
    Granularity.MINUTE,
    Granularity.HOUR,
    Granularity.DAY,
    Granularity.WEEK,
    Granularity.MONTH,
    Granularity.QUARTER,
    Granularity.YEAR,
  ];

  if (!granularity1 || !granularity2) {
    return undefined;
  } else if (
    granularityPriority.indexOf(granularity1) <=
    granularityPriority.indexOf(granularity2)
  ) {
    return granularity1;
  } else {
    return granularity2;
  }
};

/**
 * Format a value with precision and unit symbol properties
 * @param value
 * @param precision
 * @param unitSymbol
 */
const formatValue = (value: number, precision = 2, unitSymbol?: string) => {
  const pow = Math.pow(10, precision);
  return `${Math.ceil(value * pow) / pow}${unitSymbol ?? ""}`;
};

/**
 * Return the format value function
 */
const getFormatValueFunction = (serie: Serie) =>
  serie.formatValue ??
  ((value: any) => formatValue(value, serie.precision, serie.unitSymbol));

const updateChartScales = () => {
  if (chartInstance) {
    const scales: any = {
      x: {
        ...{
          type: "time",
          grid: {
            display: false,
          },
          adapters: {
            date: {
              locale: props.locale,
            },
          },
          time: {
            unit: Granularity.YEAR,
            displayFormats: {
              millisecond: "F",
              second: "F",
              minute: "F",
              hour: "f",
              day: "D",
              week: "D (W)",
              month: "LLLL yyyy",
              quarter: "qq yyyy",
              year: "yyyy",
            },
          },
        },
        ...props.xScaleProperties,
      },
    };

    for (const serie of props.series) {
      const yAxisID = getSerieYAxisID(serie);

      // get the min & max values of the serie
      const serieMin = Math.min(...serie.values.map((value) => value.value));
      const serieMax = Math.max(...serie.values.map((value) => value.value));

      // calculate the min & max values of the series
      const seriesMin = scales[yAxisID]?.min
        ? Math.min(scales[yAxisID].min, serieMin)
        : serieMin;
      const seriesMax = scales[yAxisID]?.max
        ? Math.max(scales[yAxisID].max, serieMax)
        : serieMax;

      // add margin on min and max values depending on the delta - if the min and the max are the same, use 20% of the
      // max value as margin
      const delta = seriesMax - seriesMin;
      const margin = delta !== 0 ? delta / 50 : (seriesMax * 20) / 100;
      const seriesMinWithMargin = seriesMin - margin;
      const seriesMaxWithMargin = seriesMax + margin;

      // round min and max scales
      const minScale =
        delta < 1 ? seriesMinWithMargin : Math.floor(seriesMinWithMargin);
      const maxScale =
        delta < 1 ? seriesMaxWithMargin : Math.ceil(seriesMaxWithMargin);

      scales[yAxisID] = {
        ...{
          display: "auto",
          min: minScale,
          max: maxScale,
          position:
            scales[yAxisID]?.position ??
            (Object.keys(scales).length % 2 === 0 ? "right" : "left"),
          title: {
            display: !!serie.unit,
            text: serie.unit,
          },
          ticks: {
            callback: getFormatValueFunction(serie),
            stepSize: Math.round((maxScale - minScale) / 10), // divide the scale with 10 steps
          },
        },
        ...serie.scaleProperties,
      };

      scales.x.time.unit = getMinGranularity(
        scales.x.time.unit,
        serie.granularity,
      );
    }

    chartInstance.options.scales = scales;

    chartInstance.update("none");
  }
};

/**
 * Update the locale of the chart
 */
const updateChartLocale = () => {
  if (chartInstance) {
    chartInstance.options.locale = props.locale;

    updateChartScales();
  }
};

const hideSerieTitle = t("chart.serie.hide");
const removeSerieTitle = t("chart.serie.remove");
const displaySerieTitle = t("chart.serie.display");

function getUnusedColorFromSeries(series: Serie[]): string {
  let color;
  const usedColors = series
    .filter((serie) => serie.color !== undefined)
    .map((serie) => serie.color);
  const unusedColors = DEFAULT_CHART_COLORS_HTML.filter(
    (color) => !usedColors.includes(color),
  );
  if (unusedColors.length > 0) {
    color = unusedColors[Math.floor(Math.random() * unusedColors.length)];
  } else {
    color =
      DEFAULT_CHART_COLORS_HTML[
        Math.floor(Math.random() * DEFAULT_CHART_COLORS_HTML.length)
      ];
  }
  return color;
}

function updateSerieColor(serie: Serie, color: string) {
  serie.color = color;
  serie.datasetProperties = {
    ...serie.datasetProperties,
    ...{
      borderColor: color,
      backgroundColor: color,
      pointBackgroundColor: color,
    },
  };
}

function getSerieBorderColor(serie: Serie): string {
  if (serie.name in selectedSerieColor.value) {
    return `border-color: #d3d3d3 #d3d3d3 #d3d3d3 ${selectedSerieColor.value[serie.name]};`;
  } else {
    return "border-color: #d3d3d3";
  }
}

/**
 * Return true if the serie is considered as boolean, false otherwise
 */
function isSerieBoolean(serie: Serie): boolean {
  return (
    serie.scaleProperties &&
    serie.scaleProperties.min === 0 &&
    serie.scaleProperties.max === 1 &&
    serie.scaleProperties.ticks.stepSize === 1
  );
}

/**
 * Return the ID of the Y axis based on the serie unit, type and label
 * The purpose is to group series by unit or type
 */
function getSerieYAxisID(serie: Serie): string {
  return serie.unit || (isSerieBoolean(serie) ? "boolean" : serie.label);
}

/**
 * Return the full label of the serie, including the unit
 */
function getSerieFullLabel(serie: Serie): string {
  let label = serie.label || serie.name;

  if (serie.unit) {
    label += ` (${serie.unit})`;
  }

  return label;
}

/**
 * Update the chart datasets
 */
const updateChartDatasets = () => {
  const datasets = [];

  if (chartInstance) {
    for (const serie of props.series) {
      const label = getSerieFullLabel(serie);

      const color = getUnusedColorFromSeries(props.series);
      if (!serie.color) {
        updateSerieColor(serie, color);
      }

      selectedType.value[serie.name] =
        selectedType.value[serie.name] ?? DatasetType.LINE;

      datasets.push({
        // default properties
        ...{
          type: selectedType.value[serie.name],
          label,
          data: serie.isVisible ? serie.values : null,
          backgroundColor: `${serie.color}b3`, // opacity: 0.7
          borderColor: serie.color,
          borderWidth: 1,
          tension: 0,
          pointRadius: 2,
          yAxisID: getSerieYAxisID(serie),
        },
        //  override dataset properties
        ...serie.datasetProperties,
      });

      if (serie.color) {
        selectedSerieColor.value[serie.name] = serie.color;
      }

      const datasetLabel = datasets[datasets.length - 1].label;

      formatValueByDatasetLabel[datasetLabel] = getFormatValueFunction(serie);
    }

    chartInstance.data.datasets = datasets;

    updateChartScales();
  }
};

const updateChartPlugin = () => {
  if (chartInstance) {
    chartInstance.options.plugins = {
      ...{
        legend: {
          position: "bottom",
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              const datasetLabel = context.dataset.label ?? "";

              let label = datasetLabel ?? "";

              if (label) {
                label += ": ";
              }

              if (formatValueByDatasetLabel[datasetLabel]) {
                label += formatValueByDatasetLabel[datasetLabel](
                  context.parsed.y,
                );
              } else {
                label += context.formattedValue;
              }

              return label;
            },
          },
        },
        zoom: {
          zoom: DEFAULT_CHARTJS_ZOOM_SUB_CONFIG,
        },
      },
      ...props.pluginProperties,
    };

    chartInstance.update("none");
  }
};

/**
 * Update the color of series
 */
const updateChartSelectedColor = (serieColor: Record<string, string>) => {
  for (const serie of props.series) {
    if (serieColor[serie.name]) {
      updateSerieColor(serie, serieColor[serie.name]);
    }
  }

  updateChartDatasets();
};

/**
 * Build the chart
 */
const buildChart = () => {
  for (const serie of props.series) {
    showSettingsRef.value[serie.name] = false;
  }

  const chartCtx = chartRef.value?.getContext("2d");
  if (chartCtx) {
    chartInstance = new Chart(chartCtx, {
      data: {
        datasets: [],
      },
      options: {
        parsing: {
          xAxisKey: "timestamp",
          yAxisKey: "value",
        },
      },
    });

    updateChartLocale();
    updateChartDatasets();
    updateChartPlugin();
  }
};
/** End build the chart */

const removeSerie = (serie: Serie) => {
  const index = props.series.indexOf(serie);
  serie.isVisible = true;
  if (index !== -1) {
    const series = props.series;
    series.splice(index, 1);
    emit("update:series", series);
  }
};

function onHide(serie: Serie) {
  serie.isVisible = !serie.isVisible;
}

const valueForBooleanTrue = "1";
const valueForBooleanFalse = "0";
const defaultPrecisionForLabel = 0;

/**
 * Extract the last value of the series to be displayed in the tab label. If not defined, return undefined. This
 * function transforms the numbers using an input number precision (or the default one) and the boolean to its number
 * representation (0 or 1).
 * @param values
 * @param precision
 */
function getTabValue(
  values: Value[],
  precision?: number | undefined,
): string | undefined {
  let value;
  if (values.length > 0) {
    const rawValue = values.sort(
      (value1, value2) => value2.timestamp - value1.timestamp,
    )[0].value;
    if (typeof rawValue === "boolean") {
      value = rawValue ? valueForBooleanTrue : valueForBooleanFalse;
    } else if (typeof rawValue === "number") {
      value = rawValue.toFixed(precision ?? defaultPrecisionForLabel);
    }
  }
  return value;
}

watch(() => props.series, updateChartDatasets, { deep: true });
watch(() => selectedSerieColor.value, updateChartSelectedColor, { deep: true });
watch(() => selectedType.value, updateChartDatasets, { deep: true });
watch(
  () => props.height,
  () => chartInstance?.render(),
);
watch(
  () => props.width,
  () => chartInstance?.render(),
);
watch(() => props.locale, updateChartLocale, { deep: true });
watch(() => props.xScaleProperties, updateChartScales, { deep: true });
watch(() => props.pluginProperties, updateChartPlugin, { deep: true });

onMounted(buildChart);
</script>

<template>
  <lxc-card class="lxc-chart" header-class="!px-3 !py-3">
    <template #header>
      <div class="flex gap-2">
        <div
          v-for="(serie, i) in series"
          :key="i"
          :class="`rounded-md border-y border-l-8 border-r h-13 w-1/${MAX_SERIES_BY_CHART}`"
          :style="getSerieBorderColor(serie)"
          @mouseover="updateShowSettings(serie, true)"
          @mouseleave="($event) => onSerieToolbarLeave($event, serie, false)"
        >
          <div class="flex px-3 py-2">
            <button
              v-if="!serie.isVisible"
              html-type="button"
              :title="displaySerieTitle"
              @click="() => onHide(serie)"
            >
              <ILxcEyeOff class="ml-1 mr-2.5" />
            </button>
            <div class="flex flex-1 truncate w-full">
              <div :class="getTabContentStyle(serie)">
                <div class="truncate">
                  <span>
                    {{ serie.label }}
                  </span>
                </div>
                <div>
                  <span class="font-semibold">
                    <span>
                      {{
                        getTabValue(serie.values, serie.precision) ?? NO_CONTENT
                      }}
                    </span>
                    <span v-if="serie.unit" class="pl-1">
                      {{ serie.unit }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div v-if="shouldDisplayShowSettings(serie)" class="flex flex-none">
              <lxc-dropdown-panel
                v-if="serie.isVisible != false"
                :title="t('chart.serie.displaySettings')"
                button-id="myFilter"
                class="relative flex items-center"
                button-class="right-0 border-0 ml-2 !px-0 !py-0 !pl-2 !bg-white"
                exclude-autoclose=".lxc-color-palette-picker-opened"
              >
                <template #button-label>
                  <!-- See: https://lacroix-group.atlassian.net/browse/LXC-5868?focusedCommentId=87070 -->
                  <ILxcSettings class="text-black" />
                </template>
                <template #default>
                  <div class="grid grid-cols-2 gap-2">
                    <div class="flex items-center">
                      {{ t("chart.serie.color") }}
                    </div>
                    <div class="flex items-center">
                      <lxc-color-palette-picker
                        v-model="selectedSerieColor[serie.name]"
                        :color-palette="DEFAULT_CHART_COLORS_HTML"
                        :title="t('chart.serie.selectColor')"
                      />
                    </div>
                    <div class="flex items-center">
                      {{ t("chart.serie.type") }}
                    </div>
                    <div class="flex items-center">
                      <lxc-thumbnail-select
                        v-model="selectedType[serie.name]"
                        :chevron="false"
                        :default-option-width="14"
                        :gap="8"
                        :padding="8"
                        :width="56"
                      >
                        <lxc-thumbnail-option
                          :key="DatasetType.LINE"
                          :value="DatasetType.LINE"
                          :title="t('chart.serie.types.line')"
                        >
                          <ILxChartDatasetLine />
                        </lxc-thumbnail-option>
                        <lxc-thumbnail-option
                          :key="DatasetType.BAR"
                          :value="DatasetType.BAR"
                          :title="t('chart.serie.types.bar')"
                        >
                          <ILxChartDatasetBar />
                        </lxc-thumbnail-option>
                      </lxc-thumbnail-select>
                    </div>
                  </div>
                </template>
              </lxc-dropdown-panel>

              <button
                v-show="serie.isVisible"
                html-type="button"
                class="pl-2"
                :title="hideSerieTitle"
                @click="() => onHide(serie)"
              >
                <ILxcEyeOff />
              </button>
              <button
                :title="removeSerieTitle"
                html-type="button"
                class="pl-2"
                @click="() => removeSerie(serie)"
              >
                <ILxcTrash2 />
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>

    <canvas
      :id="chartId"
      ref="chartRef"
      :height="height ?? 100"
      :width="width ?? 400"
      aria-label="Chart"
    />
  </lxc-card>
</template>
