<script setup lang="ts">
import LxcForm from "@lxc/app-device-common/src/components/form/LxcForm.vue";
import {
  type FirmwareI,
  type OperationModelI,
  OperationModelType,
  type PaginatedListI,
} from "@lxc/app-device-types";
import LxcCampaignFunnelStep1SelectFirmware from "~/components/campaigns/campaignForm/campaignOperationMgrFunnel/campaignFunnelStep1/LxcCampaignFunnelStep1SelectFirmware.vue";
import operationManagerService from "~/services/operationManager.service";
import LxcError from "~/utils/LxcError";

export interface CampaignFunnelStep1 {
  name?: string;
  operationModelType?: OperationModelType;
  firmwareUuid?: string;
}

const emit = defineEmits([
  "allOperationModelsLoaded",
  "update:modelValue",
  "selectFirmware",
]);

const props = defineProps<{
  modelValue: CampaignFunnelStep1;
  canEdit: boolean;
  isEditing: boolean;
}>();

const { t, te } = useI18n();

const isLoadingAllOperationModels: Ref<boolean> = ref(false);
const errorAllOperationModels: Ref<LxcError | undefined> = ref(undefined);
const allOperationModels: Ref<OperationModelI[]> = ref([]);

const distinctOperationModelTypes: ComputedRef<OperationModelType[]> = computed(
  () => [
    ...new Set(
      allOperationModels.value.map((operationModel) => operationModel.type),
    ),
  ],
);

// set all the operation model list
async function setAllOperationModels() {
  errorAllOperationModels.value = undefined;
  isLoadingAllOperationModels.value = true;

  const allOperationModelsFetched = await fetchAllOperationModels();
  if (LxcError.check(allOperationModelsFetched)) {
    errorAllOperationModels.value = allOperationModelsFetched;
  } else {
    allOperationModels.value = allOperationModelsFetched;
    emit("allOperationModelsLoaded", allOperationModels.value);
  }

  isLoadingAllOperationModels.value = false;
}

// fetch all the operation models recursively
async function fetchAllOperationModels(
  fromPage: number = 1,
  // max pageSize of 100 is defined by BS dtwins
  pageSize: number = 100,
): Promise<OperationModelI[] | LxcError> {
  const paginatedOperationModels: PaginatedListI<OperationModelI> =
    await operationManagerService.getModels(fromPage, pageSize);

  if (LxcError.check(paginatedOperationModels)) {
    return paginatedOperationModels;
  } else if (
    paginatedOperationModels.pagination.count > 0 &&
    paginatedOperationModels.pagination.page <
      Math.ceil(
        paginatedOperationModels.pagination.totalCount /
          paginatedOperationModels.pagination.pageSize,
      )
  ) {
    const operationModelsNextPageFetched = await fetchAllOperationModels(
      fromPage + 1,
      pageSize,
    );
    if (LxcError.check(operationModelsNextPageFetched)) {
      return operationModelsNextPageFetched;
    } else {
      return paginatedOperationModels.results.concat(
        operationModelsNextPageFetched,
      );
    }
  } else {
    return paginatedOperationModels.results;
  }
}

function getOperationModelTypeLabel(operationModelType: OperationModelType) {
  const i18nKey = `campaign.funnel.campaignSettings.operationModelType.${operationModelType}`;
  return te(i18nKey) ? t(i18nKey) : operationModelType;
}

const isOperationModelTypeFirmUpdateSelected: ComputedRef<boolean> = computed(
  () => form.value.operationModelType === OperationModelType.FIRM_UPDATE,
);

function onSelectFirmware(firmware?: FirmwareI) {
  form.value.firmwareUuid = firmware?.uuid;
  emit("selectFirmware", firmware);
}

// Campaign settings rules
const rules = {
  name: [
    {
      required: true,
      message: t("campaign.funnel.campaignSettings.validation.name"),
      whitespace: true,
      trigger: "blur",
    },
    {
      max: 100,
      message: t("input.error.maxLength", { maxLength: 100 }),
      whitespace: true,
      trigger: "blur",
    },
  ],
  operationModelType: [
    {
      required: true,
      message: t("campaign.funnel.campaignSettings.validation.type"),
      trigger: "change",
    },
  ],
};

const formRef: Ref<typeof LxcForm | undefined> = ref();
const campaignFunnelStep1SelectFirmwareRef: Ref<
  typeof LxcCampaignFunnelStep1SelectFirmware | undefined
> = ref();

const form: ComputedRef<CampaignFunnelStep1> = computed({
  get() {
    return props.modelValue;
  },
  set(form: CampaignFunnelStep1) {
    emit("update:modelValue", form);
  },
});

function onClickOperationModelType(operationModelType: OperationModelType) {
  if (props.canEdit) {
    form.value.operationModelType =
      form.value.operationModelType != operationModelType
        ? (form.value.operationModelType = operationModelType)
        : undefined;
  }
}

// reset the selected firmware when changing operation model type
watch(
  () => form.value.operationModelType,
  (operationModelType) => {
    if (operationModelType !== OperationModelType.FIRM_UPDATE) {
      onSelectFirmware(undefined);
    }
  },
);

onMounted(setAllOperationModels);

defineExpose({
  validate: async () =>
    Promise.resolve(
      (await formRef.value?.validate().catch(() => false)) & // single "&" in order to execute all the validate functions
        (!isOperationModelTypeFirmUpdateSelected.value ||
          (await campaignFunnelStep1SelectFirmwareRef.value
            ?.validate()
            .catch(() => false))),
    ),
});
</script>

<template>
  <lxc-form ref="formRef" :model="form" :rules="rules" @submit.prevent>
    <h4 class="mt-0 mb-8 text-gray-900">
      {{ t("campaign.funnel.campaignSettings.title") }}
    </h4>

    <lxc-form-item
      :label="t('campaign.funnel.campaignSettings.name')"
      class="w-80 mb-4"
      prop="name"
    >
      <lxc-input
        v-model="form.name"
        type="text"
        :placeholder="t('campaign.funnel.campaignSettings.name')"
        :disabled="isEditing"
      />
    </lxc-form-item>

    <lxc-form-item
      :label="t('campaign.funnel.campaignSettings.typeOfCampaign.title')"
      display-label
      prop="operationModelType"
    >
      <lxc-container
        :is-loading="isLoadingAllOperationModels"
        :error="errorAllOperationModels"
        class="!p-0"
      >
        <div class="flex gap-6">
          <div
            v-for="operationModelType of distinctOperationModelTypes"
            :key="operationModelType"
            :class="`${canEdit ? 'cursor-pointer' : 'cursor-not-allowed'} flex items-center min-w-[12.688rem] py-[0.937rem] rounded-xl px-4 ${form.operationModelType === operationModelType ? 'bg-primary-50 border-2 border-primary-800' : 'bg-white border border-gray-300'}`"
            @click="onClickOperationModelType(operationModelType)"
          >
            <!-- build label specifically in order to manage the color and set the checkbox label to empty string in order to not display it -->
            <lxc-checkbox
              v-model="form.operationModelType"
              :value="operationModelType"
              label=""
              :disabled="!canEdit"
            />
            <label
              :class="`${canEdit ? 'cursor-pointer' : 'cursor-not-allowed'} ml-2 ${form.operationModelType === operationModelType ? 'text-primary-800' : ''}`"
            >
              {{ getOperationModelTypeLabel(operationModelType) }}
            </label>
          </div>
        </div>
      </lxc-container>
    </lxc-form-item>
  </lxc-form>

  <div v-if="isOperationModelTypeFirmUpdateSelected">
    <lxc-campaign-funnel-step1-select-firmware
      ref="campaignFunnelStep1SelectFirmwareRef"
      v-model="form.firmwareUuid"
      :can-edit="canEdit"
      :operation-models="allOperationModels"
      @select-firmware="onSelectFirmware"
    />
  </div>
</template>
