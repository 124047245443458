<script setup lang="ts">
import type { DtwinI } from "@lxc/app-device-types";
import { DtwinLifeCycleState } from "@lxc/app-device-types";
import type { FunctionalComponent, SVGAttributes } from "vue";
import { useAcl } from "vue-simple-acl";
import { DtwinsListDisplayContext } from "~/components/dtwins/dtwinsList/dtwinsList.type";
import dtwinsService from "~/services/dtwins.service";
import { ACL_ROLES } from "~/types";
import LxcError from "~/utils/LxcError";
import {
  NotificationKey,
  showNotificationSuccess,
} from "~/utils/notifications-tools";
import ILxcLightRotateCw from "~icons/lxc-light/rotate-cw";
import ILxcLightSlash from "~icons/lxc-light/slash";
import ILxcLightTrash2 from "~icons/lxc-light/trash-2";

const props = defineProps<{
  context?: DtwinsListDisplayContext;
  isRemovingFromFleet?: boolean;
  isAllowedToManageFleet?: boolean;
  dtwin: DtwinI;
}>();

const emits = defineEmits(["removeFromFleet", "lifeCycleStateUpdated"]);

const { t } = useI18n();
const { can } = useAcl();
const canUpdateLifeCycleState = computed(() => can(ACL_ROLES.UPDATE_DEVICES));

const showConfirm = ref(false);
const isUpdatingLifeCycleState = ref(false);

interface ActionConfiguration {
  icon: FunctionalComponent<SVGAttributes>;
  title: string;
  content: string;
}

const actionConfiguration: ComputedRef<ActionConfiguration> = computed(() => {
  if (props.dtwin.lifeCycleState === DtwinLifeCycleState.DISABLED) {
    return {
      icon: ILxcLightRotateCw,
      title: t("dtwins.action.enable"),
      content: t("dtwins.action.button.enable"),
    };
  } else {
    return {
      icon: ILxcLightSlash,
      title: t("dtwins.action.disable"),
      content: t("dtwins.action.button.disable"),
    };
  }
});

const onTriggerAction = () => {
  if (
    props.dtwin.lifeCycleState === DtwinLifeCycleState.DISABLED &&
    props.dtwin.previousLifeCycleState
  ) {
    updateDtwinLifeCycleState(props.dtwin.previousLifeCycleState);
  } else if (
    props.dtwin.lifeCycleState === DtwinLifeCycleState.PREPROVISIONED ||
    props.dtwin.lifeCycleState === DtwinLifeCycleState.REGISTERED
  ) {
    showConfirm.value = true;
  }
};

async function updateDtwinLifeCycleState(
  newLifeCycleState: DtwinLifeCycleState,
) {
  isUpdatingLifeCycleState.value = true;
  showConfirm.value = false;

  const response = await dtwinsService.updateDeviceLifeCycleState(
    props.dtwin.uid,
    newLifeCycleState,
  );

  if (LxcError.check(response)) {
    response.notify(NotificationKey.error);
  } else {
    showNotificationSuccess(t(NotificationKey.success));
    emits("lifeCycleStateUpdated");
  }
  isUpdatingLifeCycleState.value = false;
}
</script>
<template>
  <lxc-button
    v-if="context === DtwinsListDisplayContext.FLEET"
    html-type="button"
    type="borderless"
    :disabled="isRemovingFromFleet || !isAllowedToManageFleet"
    :icon="!isRemovingFromFleet ? ILxcLightTrash2 : undefined"
    :title="
      isAllowedToManageFleet
        ? t('dtwins.fleet.removeFromFleet')
        : t('fleet.actionForbidden')
    "
    class="invisible"
    @click.stop.prevent="$emit('removeFromFleet')"
  >
    <lxc-loader v-if="isRemovingFromFleet" />
  </lxc-button>
  <lxc-button
    v-else-if="
      context === DtwinsListDisplayContext.DTWINS && canUpdateLifeCycleState
    "
    html-type="button"
    type="borderless"
    class="invisible"
    :title="actionConfiguration.title"
    :disabled="isUpdatingLifeCycleState"
    :icon="!isUpdatingLifeCycleState ? actionConfiguration.icon : undefined"
    @click.stop.prevent="onTriggerAction"
  >
    <lxc-loader v-if="isUpdatingLifeCycleState" />
  </lxc-button>
  <lxc-button
    v-else-if="
      context === DtwinsListDisplayContext.DTWIN_DETAIL &&
      canUpdateLifeCycleState
    "
    :title="actionConfiguration.title"
    type="tertiary"
    :icon="actionConfiguration.icon"
    :disabled="isUpdatingLifeCycleState"
    :is-loading="isUpdatingLifeCycleState"
    @click="onTriggerAction"
  >
    {{ actionConfiguration.content }}
  </lxc-button>
  <lxc-confirm-modal
    :is-dialog-visible="showConfirm"
    :title="t('dtwins.action.confirm.title')"
    :description="t('dtwins.action.confirm.description')"
    :cancel-label="t('button.cancel')"
    :ok-label="t('dtwins.action.confirm.disable')"
    :icon="ILxcLightTrash2"
    icon-color-theme="error"
    color-theme="danger"
    @confirm="updateDtwinLifeCycleState(DtwinLifeCycleState.DISABLED)"
    @cancel="showConfirm = false"
  />
</template>
<style lang="scss" scoped>
button {
  :deep(svg) {
    height: 20px;
    width: 20px;
  }
}
</style>
