<script setup lang="ts">
import type { FleetI } from "@lxc/app-device-types";
import { DisplayColumns } from "~/components/dtwins/dtwinsList/dtwinsList.type";
import { SearchMode } from "~/composables/useSearch";
import ILxcInfo from "~icons/lxc/info";

const props = defineProps<{
  show: boolean;
  fleet?: FleetI;
  compatibilityCriteria?: string;
}>();

const { t } = useI18n();

const emit = defineEmits(["update:show"]);

const showSidePanel: WritableComputedRef<boolean> = computed({
  get: () => {
    return props.show;
  },
  set: (show: boolean) => {
    emit("update:show", show);
  },
});

const onClose = () => {
  showSidePanel.value = false;
};

const incompatibleDevicesCount = computed(() => {
  if (
    props.fleet?.deviceTwinCount !== undefined &&
    props.fleet?.compatibleDeviceTwinCount !== undefined
  ) {
    return props.fleet.deviceTwinCount - props.fleet.compatibleDeviceTwinCount;
  } else {
    return 0;
  }
});

const dtwinsListSearchBarClass = computed(() => {
  if (incompatibleDevicesCount.value > 0) {
    return "!mb-[1.125rem] !mt-6";
  } else {
    return "!mb-[1.125rem]";
  }
});
</script>

<template>
  <lxc-side-canvas
    v-model:show="showSidePanel"
    :header="
      t('campaign.funnel.fleetSelection.fleetInformation.title', {
        fleetName: fleet?.friendlyName,
      })
    "
    size="2/3"
    @hidden="onClose"
  >
    <lxc-information
      v-if="incompatibleDevicesCount > 0"
      class="!my-0 !px-0 !py-0"
    >
      <template #component>
        <lxc-info-sign>
          <ILxcInfo
            width="1.25rem"
            height="1.25rem"
            view-box="0 0 1.25rem 1.25rem"
          />
        </lxc-info-sign>
      </template>
      <template #body>
        <div v-if="fleet?.compatibleDeviceTwinCount === 0">
          <p class="text-gray-900">
            {{ t("fleet.table.column.information.noDeviceCompatible") }}
          </p>
        </div>
        <div v-else>
          <p class="text-gray-900">
            {{
              t(
                "campaign.funnel.fleetSelection.fleetInformation.message.beginning",
              )
            }}
            <span class="font-semibold">
              {{
                t(
                  "campaign.funnel.fleetSelection.fleetInformation.message.middle",
                  { count: incompatibleDevicesCount },
                )
              }}
            </span>
            {{
              t("campaign.funnel.fleetSelection.fleetInformation.message.end", {
                count: incompatibleDevicesCount,
              })
            }}
          </p>
        </div>
      </template>
    </lxc-information>
    <lxc-dtwins-list
      :columns="[
        DisplayColumns.FRIENDLY_NAME,
        DisplayColumns.TYPE,
        DisplayColumns.SERIAL_NUMBER,
        DisplayColumns.SECTOR,
      ]"
      no-quick-filters
      display-device-compatibility-quick-filter
      :search-mode="SearchMode.FILTER_SEARCH"
      :fleet-uid="fleet?.uid"
      :search-bar-class="dtwinsListSearchBarClass"
      :compatibility-criteria="compatibilityCriteria"
      no-redirection
    />
  </lxc-side-canvas>
</template>
