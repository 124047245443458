<script setup lang="ts">
import { FILTER_SEARCH_TXT_MIN_LENGTH } from "~/constants/constants";
import ILxCross from "~icons/lxc-business/cross";

const props = defineProps<{
  class?: string | string[];
  prefixClass?: string | string[];
  disabled?: boolean;
  modelValue: string;
  placeholder: string;
  filterSearchTxtMinLength?: number;
}>();
const emit = defineEmits(["update:modelValue", "clear"]);

const inputClass = computed(() => {
  let vClass = ["!pl-10 pr-8"];

  const propClass: string[] | undefined = Array.isArray(props.class)
    ? props.class
    : props.class?.split(" ");
  if (propClass) {
    vClass = vClass.concat(propClass);
  }

  return vClass;
});

const inputPrefixClass = computed(() => {
  let vClass = ["!px-[0.625rem]"];

  const classes = Array.isArray(props.prefixClass)
    ? props.prefixClass
    : props.prefixClass?.split(" ");

  if (classes) {
    vClass = vClass.concat(classes);
  }

  return vClass;
});

const searchString = computed<string>({
  get(): string {
    return props.modelValue || "";
  },
  set(input?: string) {
    if (
      (input?.length ?? 0) >=
      (props.filterSearchTxtMinLength ?? FILTER_SEARCH_TXT_MIN_LENGTH)
    ) {
      emit("update:modelValue", input);
    } else {
      emit("update:modelValue", "");
    }
  },
});

function onClearPressed() {
  emit("update:modelValue", "");
  emit("clear");
}
</script>
<template>
  <div class="relative mt-2 rounded-md shadow-sm">
    <!-- label is set to "" here to prevent lxc-form-item to use a default label for this input and display something
    we do not want to see -->
    <lxc-input
      v-model="searchString"
      label=""
      type="search"
      prefix-read-only
      :prefix-class="inputPrefixClass"
      :class="inputClass"
      :placeholder="placeholder"
      :disabled="disabled ?? false"
    >
      <template #prefix>
        <span class="text-gray-500">
          <ILxcLightSearch class="w-5 h-5" />
        </span>
      </template>
    </lxc-input>
    <div v-show="searchString !== ''" class="absolute inset-y-0 right-4 flex">
      <button
        type="button"
        class="box-content rounded-none border-none opacity-50 hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
        @click="onClearPressed"
      >
        <span
          class="w-[1em] focus:opacity-100 disabled:pointer-events-none disabled:select-none disabled:opacity-25 [&.disabled]:pointer-events-none [&.disabled]:select-none [&.disabled]:opacity-25 text-primary-900 dark:text-primary-100"
        >
          <ILxCross />
        </span>
      </button>
    </div>
  </div>
</template>
<style lang="scss">
/* Removes the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* Removes the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
</style>
