<script setup lang="ts">
import { onMounted, onUnmounted } from "vue";
import type { TabNavigation } from "~/components/shared/tabs/LxcTabsWithNavigation.vue";

interface Props {
  tabNavigation: TabNavigation;
}
const { tabNavigation } = defineProps<Props>();

const route = useRoute();

const key = ref(computeKey());
const displayable = ref(shouldBeDisplayed(route.path));

/**
 * Use the `path` parameter to determine if the component should be displayed.
 * If the path does not match the tab configuration, it means the user is not on
 * the right tab and its content should not be loaded and displayed.
 * @param path Path used to determine if the component should be displayed.
 */
function shouldBeDisplayed(path: string): boolean {
  return path.startsWith(tabNavigation.redirectPath);
}

/**
 * Function used to update the internal component state. The function checks if
 * the component should be displayed or not, and update the key if needed to
 * force the component to be displayed and refreshed if needed.
 * @param path Path used to determine the  component state.
 */
function handleInternalTabState(path: string): void {
  const shouldBeDisplayedWithPath = shouldBeDisplayed(path);
  if (shouldBeDisplayedWithPath) {
    key.value = computeKey();
  }
  displayable.value = shouldBeDisplayedWithPath;
}

/**
 * Util function used to create a new unique key to trigger component refresh.
 */
function computeKey() {
  return `${tabNavigation.index}_${Date.now()}`;
}

/**
 * Handle the component state refresh when the browser back and forward buttons
 * are triggered.
 */
const handlePopState = () => handleInternalTabState(route.path);

watch(
  () => route.path,
  (path) => handleInternalTabState(path),
);

onMounted(() => {
  window.addEventListener("popstate", handlePopState);
});
onUnmounted(() => {
  window.removeEventListener("popstate", handlePopState);
});
</script>

<template>
  <slot v-if="displayable" :key="key" :name="tabNavigation.index" />
</template>
