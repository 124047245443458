import RestService from "./rest.service";
import type {
  ApiListResult,
  AsyncLogI,
  TranslatedLogAttributeI,
} from "@lxc/app-device-types";
import { DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE } from "~/constants/constants";
import type { FilterSelectionValue, FiltersSelection } from "~/types";

export class LogsService extends RestService {
  protected BASE_URL = "/logs";

  getLogs(
    page?: number,
    pageSize?: number,
    searchParams?: string | FiltersSelection,
    sort?: string | null,
  ): Promise<ApiListResult<AsyncLogI>> {
    const iterator = (searchParams as FiltersSelection).entries();
    const searchObject: Record<string, FilterSelectionValue> = {};

    for (const entry of iterator) {
      const filterDefinition = entry[1];
      searchObject[filterDefinition.key] = filterDefinition.value;
    }

    const params = { page, pageSize, ...searchObject, sort };

    return this.$api.get(`${this.BASE_URL}`, {
      headers: { Accept: "application/json" },
      params,
      timeout: Infinity,
    });
  }

  getActionTypes(
    page?: number,
    pageSize?: number,
    search?: string | FiltersSelection,
  ): Promise<ApiListResult<TranslatedLogAttributeI>> {
    const params = {
      page: page ?? DEFAULT_FIRST_PAGE,
      pageSize: pageSize ?? DEFAULT_PAGE_SIZE,
      search,
    };

    return this.$api.get(`${this.BASE_URL}/actionTypes`, { params });
  }
}

export default new LogsService();
