/**
 * Export a certificate
 * Note: the import of "useExportAsString" involves a Vitest failure,
 * this is the reason why this feature is isolated in a dedicated composable.
 */
import { useExportAsString } from "@lxc/app-device-common";
import { CACertificateItemType } from "@lxc/app-device-types";

const { exportString } = useExportAsString();

export function useExportCertificateAsString() {
  /**
   * build and propose the download of a certificate file from a string
   * @param base64value
   * @param filename
   * @param type
   */
  function exportCertificateAsString(
    base64value: string,
    filename: string,
    type: CACertificateItemType,
  ) {
    let extension: string, mime: string;

    if (type === CACertificateItemType.CSR) {
      extension = "csr";
      mime = "application/pkcs10; charset=utf-8";
    } else {
      extension = "pem";
      mime = "application/x-pem-file; charset=utf-8";
    }

    exportString(base64value, filename, extension, mime);
  }

  return {
    exportCertificateAsString,
  };
}
