<script setup lang="ts">
import type { DtwinI, SectorI } from "@lxc/app-device-types";
import type { Ref } from "vue";
import { onMounted } from "vue";
import { useDtwinModels } from "~/composables/useDtwinModels";
import { useDtwin, useDtwins } from "~/composables/useDtwins";
import { DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE } from "~/constants/constants";
import { PATHS } from "~/constants/paths";
import { router } from "~/plugins/router";
import { Filters, SectorResource } from "~/types";

const props = defineProps<{
  sector: SectorI;
}>();

const { t } = useI18n();
const {
  isLoading: isLoadingDtwins,
  results: dtwins,
  error: errorDtwins,
  fetchData,
  setFilter,
  canManageItems,
  getDescription,
} = useDtwins();
const { getDtwinTypeLabel } = useDtwin();

const {
  isLoading: isLoadingModels,
  error: errorModels,
  results: models,
  fetchAllModels,
} = useDtwinModels();

const selectedItems: Ref<any[]> = ref([]);
const displayActions = computed(() => canManageItems(props.sector));

onMounted(async () => {
  await fetchAllModels();
  setFilter(Filters.SECTORS, [props.sector.code]);
  await fetchData();
});

async function refresh() {
  await fetchData();
  selectedItems.value = [];
}

function onRowClick(dtwin: DtwinI) {
  router.push(`${PATHS.DTWINS}/${dtwin.uid}`);
}

const isLoading = computed(
  () => isLoadingModels.value || isLoadingDtwins.value,
);

async function getNextByPageAndSize(
  page: number = DEFAULT_FIRST_PAGE,
  pageSize: number = DEFAULT_PAGE_SIZE,
) {
  await fetchData(page, pageSize);
}
</script>

<template>
  <lxc-sector-toolbar
    ref="sectorToolbar"
    primary-key="uid"
    :selected-items="selectedItems"
    :sector="sector"
    :resource="SectorResource.DTWINS"
    @change="refresh"
  >
    <lxc-table-column
      prop="attributes.friendlyName"
      :label="t('dtwins.list.attributes.friendlyName')"
    />
    <lxc-table-column
      prop="attributes.type"
      :label="t('dtwins.list.attributes.type')"
      width="12rem"
    >
      <template v-if="models" #default="scope">
        {{ getDtwinTypeLabel(models.results, scope.row) }}
      </template>
    </lxc-table-column>
    <lxc-table-column :label="t('dtwins.list.attributes.state')" width="12rem">
      <template #default="scope">
        {{ t(`dtwins.lifeCycleState.${scope.row.lifeCycleState}`) }}
      </template>
    </lxc-table-column>
  </lxc-sector-toolbar>

  <lxc-container
    :px="0"
    :py="0"
    class="mt-4"
    :is-loading="isLoading && !dtwins"
    :error="errorModels || errorDtwins"
  >
    <lxc-sectors-details-table
      v-model="selectedItems"
      primary-key="uid"
      :is-loading="isLoading"
      :selection="displayActions"
      :items="dtwins"
      :display-actions="displayActions"
      :resource="SectorResource.DTWINS"
      :get-description="getDescription"
      @pagination:get-next-page="getNextByPageAndSize"
      @actions:detach="(item) => $refs.sectorToolbar.openDetachModal(item)"
      @row-click="onRowClick"
    >
      <lxc-table-column
        prop="attributes.friendlyName"
        :label="t('dtwins.list.attributes.friendlyName')"
        min-width="20rem"
      />
      <lxc-table-column
        prop="attributes.type"
        :label="t('dtwins.list.attributes.type')"
        width="12rem"
      >
        <template v-if="models" #default="scope">
          {{ getDtwinTypeLabel(models.results, scope.row) }}
        </template>
      </lxc-table-column>
      <lxc-table-column
        prop="attributes.serialNumber"
        :label="t('dtwins.list.attributes.serialNumber')"
        width="12rem"
      />
      <lxc-table-column
        :label="t('dtwins.list.attributes.state')"
        width="12rem"
      >
        <template #default="scope">
          {{ t(`dtwins.lifeCycleState.${scope.row.lifeCycleState}`) }}
        </template>
      </lxc-table-column>
    </lxc-sectors-details-table>
  </lxc-container>
</template>
