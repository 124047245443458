<script setup lang="ts">
import type { ApplicationI, OperationI } from "@lxc/app-device-types";
import { AppDeviceState, OperationState } from "@lxc/app-device-types";
import type { Ref } from "vue";
import deviceService from "~/services/device.service";
import { ObjectType } from "~/types";
import LxcError from "~/utils/LxcError";
import {
  NotificationKey,
  showNotificationError,
  showNotificationSuccess,
} from "~/utils/notifications-tools";

const props = defineProps<{
  application: ApplicationI;
  operation: OperationI;
}>();
const emit = defineEmits(["change"]);

const { t } = useI18n();

const isCancellable = ref(false);
const isEditable = ref(false);

function checkOperationStatus() {
  switch (props.operation.definition.state) {
    case OperationState.PENDING:
    case OperationState.RUNNING:
    case OperationState.RETRY:
      isCancellable.value = true;
      break;
  }
  if (props.operation.definition.state === OperationState.PENDING) {
    isEditable.value = true;
  }
}

const [confirmCancelOperationDialogVisible, toggleConfirmCancelVisible] =
  useToggle();
const [
  updateCertificateOperationDialogVisible,
  toggleUpdateCertificateOperationVisible,
] = useToggle();

const operationIdArray: Ref<string[]> = ref([]);
async function cancelOperation(deviceId: string, operationId: string) {
  operationIdArray.value.push(operationId);
  const cancelOperationsResponse = await deviceService.cancelOperations(
    deviceId,
    operationIdArray.value,
  );

  if (LxcError.check(cancelOperationsResponse)) {
    showNotificationError(t(NotificationKey.error));
  } else {
    showNotificationSuccess(t(NotificationKey.success));
    toggleConfirmCancelVisible();
    emit("change");
  }
}

enum Action {
  CANCEL = "CANCEL",
  UPDATE = "UPDATE",
}

function onSelectAction(action: Action) {
  switch (action) {
    case Action.CANCEL:
      toggleConfirmCancelVisible();
      break;
    case Action.UPDATE:
      toggleUpdateCertificateOperationVisible();
      break;
    default:
      break;
  }
}

onMounted(checkOperationStatus);
</script>
<template>
  <lxc-dropdown @command="onSelectAction">
    <el-dropdown-item
      :command="Action.CANCEL"
      :disabled="
        !isCancellable || application.state !== AppDeviceState.ACTIVATED
      "
    >
      {{ t("application.operation.cancelAction.title") }}
    </el-dropdown-item>
    <el-dropdown-item
      :command="Action.UPDATE"
      :disabled="!isEditable || application.state !== AppDeviceState.ACTIVATED"
    >
      {{ t("application.operation.updateAction.title") }}
    </el-dropdown-item>
  </lxc-dropdown>

  <lxc-confirm-modal
    :is-dialog-visible="confirmCancelOperationDialogVisible"
    :title="t('application.operation.cancelAction.confirm.title')"
    :description="t('application.operation.cancelAction.confirm.description')"
    @cancel="toggleConfirmCancelVisible"
    @confirm="cancelOperation(application.id!, operation.definition.id)"
  />
  <lxc-update-certificate-modal
    :is-dialog-visible="updateCertificateOperationDialogVisible"
    :object="application"
    :object-type="ObjectType.DEVICE"
    is-operation-update
    :operation="operation"
    @update:toggle-dialog="toggleUpdateCertificateOperationVisible"
    @change="$emit('change')"
  />
</template>
