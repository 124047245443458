<script setup lang="ts">
import type { DtwinI } from "@lxc/app-device-types";
import { DtwinsListDisplayContext } from "~/components/dtwins/dtwinsList/dtwinsList.type";
import type LxcContainer from "~/components/shared/LxcContainer.vue";
import type { TabNavigation } from "~/components/shared/tabs/LxcTabsWithNavigation.vue";
import LxcTabsWithNavigation from "~/components/shared/tabs/LxcTabsWithNavigation.vue";
import { useDtwinModels } from "~/composables/useDtwinModels";
import { PATHS } from "~/constants/paths";
import DtwinsService from "~/services/dtwins.service";
import LxcError from "~/utils/LxcError";

const route = useRoute();
const router = useRouter();
const {
  isLoading: isLoadingModels,
  error: errorModels,
  results: models,
  fetchAllModels,
} = useDtwinModels();

const dtwin: Ref<DtwinI | undefined | null> = ref();
const dtwinUid: Ref<string> = ref(route.params.id.toString());
const errorDtwin: Ref<LxcError | null> = ref(null);
const isLoadingDtwin = ref(true);
const breadcrumbContainerRef: Ref<HTMLElement | undefined> = ref();
const containerRef: Ref<typeof LxcContainer | undefined> = ref();
const mainParentElement: ComputedRef<HTMLElement | undefined | null> = computed(
  () => breadcrumbContainerRef.value?.parentElement,
);
const containerPaddingTop: ComputedRef<number> = computed(
  () => containerRef.value?.paddingTop ?? 0,
);
const containerPaddingBottom: ComputedRef<number> = computed(
  () => containerRef.value?.paddingBottom ?? 0,
);
const tabsContentHeight: Ref<number> = ref(0);
const tabsContentTop: Ref<number> = ref(0);
const parentScrollTop: Ref<number> = ref(0);

async function fetchDevice() {
  const response = await DtwinsService.getDtwin(dtwinUid.value, true, true);

  if (LxcError.check(response)) {
    dtwin.value = null;
    errorDtwin.value = response;
  } else {
    dtwin.value = response;
  }

  isLoadingDtwin.value = false;
}

const fetchData = async () => {
  await Promise.all([fetchAllModels(), fetchDevice()]);
};

function updateTabContentHeight() {
  let height = 0;
  const tabsHeight = tabsWithNavigationRef?.value?.tabsRef?.tabsHeight;

  if (breadcrumbContainerRef.value?.parentElement) {
    const containerHeight =
      breadcrumbContainerRef.value.parentElement.getBoundingClientRect()
        .height - breadcrumbContainerRef.value.getBoundingClientRect().height;

    if (containerRef.value && tabsHeight !== undefined) {
      height =
        containerHeight -
        containerPaddingTop.value -
        containerPaddingBottom.value -
        tabsHeight;
    }
  }

  tabsContentHeight.value = height;
}

function initTabContentTopPosition() {
  const breadcrumbHeight =
    breadcrumbContainerRef.value?.getBoundingClientRect().height ?? 0;
  const tabsHeight = tabsWithNavigationRef?.value?.tabsRef?.tabsHeight ?? 0;
  tabsContentTop.value =
    breadcrumbHeight + tabsHeight + containerPaddingTop.value;
}

const onParentScroll = (evt: Event) => {
  const parentElm = evt.target as HTMLElement | undefined;

  if (parentElm && parentElm === mainParentElement.value) {
    parentScrollTop.value = parentElm.scrollTop;
  }
};

const onComponentUpdated = () => {
  updateTabContentHeight();
  initTabContentTopPosition();
};

//
// Navigation section. Logic / configuration responsible for the navigation.
//
const tabsWithNavigationRef: Ref<typeof LxcTabsWithNavigation | undefined> =
  ref();
const descriptionTabIndex = 0;
const firmwareTabIndex = 1;
const configurationTabIndex = 2;
const dataVisualisationTabIndex = 3;

const tabNavigations: TabNavigation[] = [
  {
    index: descriptionTabIndex,
    redirectPath: `${PATHS.DTWINS}/${dtwinUid.value}/${PATHS.DTWINS_DESCRIPTION_SUBPATH}`,
    labelTranslationKey: "dtwins.form.description.title",
  },
  {
    index: firmwareTabIndex,
    redirectPath: `${PATHS.DTWINS}/${dtwinUid.value}/${PATHS.DTWINS_FIRMWARE_SUBPATH}`,
    labelTranslationKey: "dtwins.form.firmware.title",
  },
  {
    index: configurationTabIndex,
    redirectPath: `${PATHS.DTWINS}/${dtwinUid.value}/${PATHS.DTWINS_CONFIGURATION_SUBPATH}`,
    labelTranslationKey: "dtwins.form.configuration.title",
  },
  {
    index: dataVisualisationTabIndex,
    redirectPath: `${PATHS.DTWINS}/${dtwinUid.value}/${PATHS.DTWINS_DATA_VISUALISATION_SUBPATH}`,
    labelTranslationKey: "dtwins.form.dataviz.title",
  },
];

//
// Lifecycle Hooks. See: https://vuejs.org/api/composition-api-lifecycle
//
onUpdated(onComponentUpdated);
onMounted(async () => {
  await fetchData();
  const parentElm = mainParentElement.value;
  if (parentElm) {
    parentElm.addEventListener("scroll", onParentScroll);
  }
  window.addEventListener("resize", updateTabContentHeight);
});
onBeforeUnmount(() => {
  const parentElm = mainParentElement.value;

  if (parentElm) {
    parent.removeEventListener("scroll", onParentScroll);
  }
  window.removeEventListener("resize", updateTabContentHeight);
});
</script>

<template>
  <div ref="breadcrumbContainerRef" class="flex justify-between">
    <div class="flex px-8 pt-8">
      <lxc-breadcrumb
        :name="dtwin?.attributes.friendlyName ?? ''"
        should-not-translate
        back
      />
    </div>
    <div class="px-8 self-end">
      <lxc-dtwins-actions
        v-if="dtwin"
        :dtwin="dtwin"
        :context="DtwinsListDisplayContext.DTWIN_DETAIL"
        @life-cycle-state-updated="fetchData"
      />
    </div>
  </div>

  <lxc-container
    ref="containerRef"
    :error="errorModels || errorDtwin"
    :is-loading="isLoadingModels || isLoadingDtwin"
  >
    <LxcTabsWithNavigation
      ref="tabsWithNavigationRef"
      :tab-navigations="tabNavigations"
      @update:selected-tab-index="onComponentUpdated"
    >
      <template #[descriptionTabIndex]>
        <lxc-dtwins-description
          v-if="models?.results"
          v-model:dtwin="dtwin"
          :models="models?.results"
        />
      </template>
      <template #[firmwareTabIndex]>
        <lxc-dtwins-firmware :dtwin="dtwin" />
      </template>
      <template #[configurationTabIndex]>
        <lxc-dtwins-configuration :dtwin-uid="dtwin?.uid" />
      </template>
      <template #[dataVisualisationTabIndex]>
        <lxc-dtwins-dataviz
          :dtwin="dtwin"
          :offset-top="tabsContentTop"
          :scroll-top="parentScrollTop"
          :tabs-content-height="tabsContentHeight"
        />
      </template>
    </LxcTabsWithNavigation>
  </lxc-container>
</template>
