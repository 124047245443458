import type { UniqueLabel } from "@lxc/app-device-common";

export function getUniqueLabelList(
  values?: string[],
  disabled = false,
  deletable = true,
): UniqueLabel[] | undefined {
  return values?.sort().map((value: string): UniqueLabel => {
    return {
      label: value,
      uid: value,
      deletable,
      disabled,
    };
  });
}

export function getValuesFromUniqueLabels(
  uniqueLabels?: UniqueLabel[],
): string[] | undefined {
  return uniqueLabels?.map((uniqueLabel: UniqueLabel) => uniqueLabel.uid);
}
