<script setup lang="ts">
import type { UserDataI } from "@lxc/app-device-types";
import { storeToRefs } from "pinia";
import type { Ref } from "vue";
import { ref } from "vue";
import { useAcl } from "vue-simple-acl";
import type { TabNavigation } from "~/components/shared/tabs/LxcTabsWithNavigation.vue";
import { PATHS } from "~/constants/paths";
import usersService from "~/services/users.service";
import { useConfigStore } from "~/stores/useConfigStore";
import { useUserSession } from "~/stores/useUserSession";
import type { UserLanguageI } from "~/types";
import { ACL_ROLES } from "~/types";
import LxcError from "~/utils/LxcError";
import { NotificationKey } from "~/utils/notifications-tools";

const acl = useAcl();
const { featureToggle } = useConfigStore();
const { userSession } = useUserSession();
const { isModeAD } = storeToRefs(useConfigStore());

const error: Ref<LxcError | null> = ref(null);
const user: Ref<UserDataI | null> = ref(null);
const canViewCertificate = computed(
  () =>
    featureToggle.isVisible("user-certificate") &&
    acl.can(ACL_ROLES.USR_CERTIFICATES),
);

const userAccountProfile = ref();

function saveLanguage(language: UserLanguageI) {
  userAccountProfile.value.saveLanguage(language);
}

const isLoading = ref(false);

//
// Navigation section. Logic / configuration responsible for the navigation.
//
const profileTabIndex = 0;
const languageTabIndex = 1;
const certificateTabIndex = 2;

const tabNavigations = computed(() => {
  const tabNavigations: TabNavigation[] = [
    {
      index: profileTabIndex,
      redirectPath: `${PATHS.USER_ACCOUNT_PROFILE}`,
      labelTranslationKey: "user.userAccount.userInformations.title",
    },
    {
      index: languageTabIndex,
      redirectPath: `${PATHS.USER_ACCOUNT_LANGUAGE}`,
      labelTranslationKey: "user.userAccount.language.title",
    },
  ];
  if (canViewCertificate.value) {
    tabNavigations.push({
      index: certificateTabIndex,
      redirectPath: `${PATHS.USER_ACCOUNT_CERTIFICATE}`,
      labelTranslationKey: "user.userAccount.certificate.title",
    });
  }
  return tabNavigations;
});

//
// Lifecycle Hooks. See: https://vuejs.org/api/composition-api-lifecycle
//
onMounted(async () => {
  isLoading.value = true;
  error.value = null;

  const userId = userSession?.userId;
  if (userId) {
    const response = await usersService.getUserById(userId);
    if (LxcError.check(response)) {
      response.notify(NotificationKey.error);
      error.value = response;
    } else {
      user.value = response;
    }
  }

  isLoading.value = false;
});
</script>

<template>
  <lxc-container>
    <lxc-breadcrumb />

    <lxc-container :is-loading="isLoading" :error="error" :px="0" :py="0">
      <LxcTabsWithNavigation :tab-navigations="tabNavigations">
        <template #[profileTabIndex]>
          <lxc-user-account-profile
            ref="userAccountProfile"
            v-model:user="user"
            :is-mode-a-d="isModeAD"
          />
        </template>
        <template #[languageTabIndex]>
          <lxc-user-account-language
            v-model:user="user"
            :is-mode-a-d="isModeAD"
            @save-language="saveLanguage"
          />
        </template>
        <template #[certificateTabIndex]>
          <lxc-user-account-certificates v-model:user="user" />
        </template>
      </LxcTabsWithNavigation>
    </lxc-container>
  </lxc-container>
</template>
