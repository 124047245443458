<script setup lang="ts">
import type { CampaignI } from "@lxc/app-device-types";
import { CampaignState, LicenseStatus } from "@lxc/app-device-types";
import type { Ref } from "vue";
import CampaignService from "~/services/campaign.service";
import { useLicenseStatusStore } from "~/stores/useLicenseStatusStore";
import { FeaturesName } from "~/types/deviceFeatures";
import LxcError from "~/utils/LxcError";
import {
  NotificationKey,
  showNotificationSuccess,
} from "~/utils/notifications-tools";
import ILxcPlus from "~icons/lxc/plus";
import ILxcTrash2 from "~icons/lxc/trash-2";
import ILxcClose from "~icons/lxc/x";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const licenseStatusStore = useLicenseStatusStore();

const id = route.params.id.toString();

const campaign: Ref<CampaignI | null> = ref(null);
const error: Ref<LxcError | null> = ref(null);
const isLoading = ref(true);
const showConfirm: Ref<boolean> = ref(false);
const showCampaignFunnel: Ref<boolean> = ref(false);

const onCancelCampaign = async () => {
  if (campaign.value?.id) {
    const campaignId = campaign.value.id;
    isLoading.value = true;
    showConfirm.value = false;

    const response = await CampaignService.cancelCampaign(
      campaignId.toString(),
    );

    if (LxcError.check(response)) {
      response.notify(NotificationKey.saveError);
    } else {
      showNotificationSuccess(t(NotificationKey.saveSuccess));
      await fetchCampaign();
    }

    isLoading.value = false;
  }
};

async function fetchCampaign() {
  const response = await CampaignService.getCampaign(id);

  if (LxcError.check(response)) {
    campaign.value = null;
    error.value = response;
  } else {
    campaign.value = response;
  }

  isLoading.value = false;
}

// License Check
const isBeyondLicenseDateValidity: Ref<boolean | undefined> = ref(true);
const isBeyondLicenseLimitOfAllDeviceTypes: Ref<boolean | undefined> =
  ref(true);
const checkLicenseValidityDate = (arg: boolean | undefined) =>
  (isBeyondLicenseDateValidity.value = arg);
const checkLimitOfAllDeviceTypes = (arg: boolean | undefined) =>
  (isBeyondLicenseLimitOfAllDeviceTypes.value = arg);
const disableUpdateOrCancelCampaign = computed(() => {
  const { isLicenseFeatureAdvancedFleetManagementActive } =
    useLicenseStatusStore();
  return (
    !isLicenseFeatureAdvancedFleetManagementActive ||
    isBeyondLicenseDateValidity.value ||
    isBeyondLicenseLimitOfAllDeviceTypes.value
  );
});

const canUpdateOrCancelCampaign = computed(
  () =>
    campaign.value?.state === CampaignState.SCHEDULED ||
    campaign.value?.state === CampaignState.RUNNING ||
    campaign.value?.state === CampaignState.PAUSED,
);

onMounted(fetchCampaign);

watch(
  () => licenseStatusStore.licenseStatus,
  async () => {
    if (
      licenseStatusStore.licenseStatus?.statusOfLicense == undefined ||
      licenseStatusStore.licenseStatus?.statusOfLicense ==
        LicenseStatus.NOT_ACTIVATED
    ) {
      await router.replace("/");
    }
  },
);
watch(
  () => licenseStatusStore.isLicenseFeatureAdvancedFleetManagementPresent,
  async () => {
    if (
      licenseStatusStore.isLicenseFeatureAdvancedFleetManagementPresent !== true
    ) {
      await router.replace("/");
    }
  },
);
</script>

<template>
  <lxc-container class="mb-4">
    <lxc-license-warning-messages
      :context="FeaturesName.ADVANCED_FLEET_MGT"
      @is-beyond-expiration-date="checkLicenseValidityDate($event)"
      @is-beyond-limit-of-all-device-type="checkLimitOfAllDeviceTypes($event)"
    />

    <div class="flex justify-between mr-14">
      <lxc-breadcrumb
        :name="campaign?.config?.name"
        back
        should-not-translate
      />
      <div class="flex gap-4 self-center">
        <lxc-button
          v-if="canUpdateOrCancelCampaign"
          :icon="ILxcPlus"
          size="large"
          type="primary"
          :title="t('campaign.details.buttons.edit.label')"
          :disabled="disableUpdateOrCancelCampaign || isLoading"
          @click="showCampaignFunnel = true"
        >
          {{ t("campaign.details.buttons.edit.label") }}
        </lxc-button>
        <lxc-button
          v-if="canUpdateOrCancelCampaign"
          type="secondary"
          :title="t('campaign.details.buttons.cancel.label')"
          :disabled="disableUpdateOrCancelCampaign || isLoading"
          :icon="ILxcClose"
          @click="showConfirm = true"
        >
          {{ t("campaign.details.buttons.cancel.label") }}
        </lxc-button>
      </div>
    </div>

    <lxc-container :is-loading="isLoading" :error="error" :px="0">
      <lxc-campaign-overview-dashboard :campaign="campaign" />

      <h2 class="section-title pt-6 pl-6">
        {{ t("campaign.details.equipments.title") }}
      </h2>

      <lxc-campaign-operation-list
        :campaign-planned-start-at="campaign?.config?.plannedStartAt"
        :campaign-id="campaign?.id"
        :current-campaign-type="campaign?.type"
      />
    </lxc-container>
  </lxc-container>

  <lxc-campaign-funnel v-model:show="showCampaignFunnel" :campaign-id="id" />

  <lxc-confirm-modal
    :is-dialog-visible="showConfirm"
    :title="t('campaign.details.buttons.cancel.confirm.title')"
    :description="t('campaign.details.buttons.cancel.confirm.message')"
    :ok-label="t('campaign.details.buttons.cancel.confirm.yes')"
    :cancel-label="t('campaign.details.buttons.cancel.confirm.no')"
    :icon="ILxcTrash2"
    icon-color-theme="error"
    color-theme="danger"
    @confirm="onCancelCampaign"
    @cancel="showConfirm = false"
  />
</template>
