<script setup lang="ts">
import type { DtwinI } from "@lxc/app-device-types";
import ILxcClose from "~icons/lxc/x";

const props = defineProps<{
  dtwin: DtwinI;
}>();

defineEmits(["close"]);

const { t } = useI18n();

const title = computed(() => {
  return props.dtwin?.attributes.friendlyName !== undefined &&
    props.dtwin?.attributes.friendlyName !== ""
    ? props.dtwin?.attributes.friendlyName
    : props.dtwin.uid;
});

const titleColor = "#002f5d";
</script>

<template>
  <button
    class="rounded-lg p-2 border border-gray-300"
    :title="t('button.close')"
    @click="$emit('close')"
  >
    <ILxcClose width="1.25rem" height="1.25rem" />
  </button>
  <div class="mt-2 px-3 truncate">
    <div class="truncate">
      <span
        class="items-center uppercase text-lg font-semibold"
        :style="{ color: titleColor }"
        :title="title"
      >
        {{ title }}
      </span>
    </div>
  </div>
  <div
    class="rounded-lg p-2 border border-gray-300 flex justify-center items-center"
  >
    <lxc-dtwins-list-metadata-status
      :operation-statuses="dtwin.operationStatuses"
      :show-simplified-pictogram="true"
    />
  </div>
</template>
