import type { TreeViewDataObject } from "@lxc/app-device-common";
import type {
  CertificateChainLinkI,
  SubjectAlternativeNameI,
} from "@lxc/app-device-types";
import { i18n } from "~/plugins/i18n";
import { formatIsoDate } from "~/utils/date-tools";

export function useParseCertificateFromString() {
  const dateFormat = i18n.global.t("dateFormat.datetime");
  const validFromIndex = 0;
  const validToIndex = 1;
  const additionalLabelStartingIndex = 2;

  /**
   * Utility function to gather the SANs with the same ID into the same tree section.
   * Key: SAN ID - Value: [SAN value, SAN value] -> Ex: { DNS, [signed-certificate-dns-1, signed-certificate-dns-2] }
   * @param leafSubjectAlternativeNames The list of the leaf certificate SAN.
   * @returns The SANs representation as a Map.
   */
  function subjectAlternativeNameAsMap(
    leafSubjectAlternativeNames: SubjectAlternativeNameI[],
  ): Map<string, string[]> {
    const subjectAlternativeNamesAsMap = new Map<string, string[]>();
    for (const leafSubjectAlternativeName of leafSubjectAlternativeNames) {
      if (
        subjectAlternativeNamesAsMap.get(
          leafSubjectAlternativeName.id.toString(),
        ) === undefined
      ) {
        subjectAlternativeNamesAsMap.set(
          leafSubjectAlternativeName.id.toString(),
          [leafSubjectAlternativeName.value],
        );
      } else {
        const previousValue = subjectAlternativeNamesAsMap.get(
          leafSubjectAlternativeName.id.toString(),
        );
        if (previousValue !== undefined) {
          subjectAlternativeNamesAsMap.set(
            leafSubjectAlternativeName.id.toString(),
            [...previousValue, leafSubjectAlternativeName.value],
          );
        }
      }
    }
    return subjectAlternativeNamesAsMap;
  }

  /**
   * Format the leaf certificate SAN to be displayed in the leaf tree level.
   * @param level Leaf tree level used as index for the DOM.
   * @param leafSubjectAlternativeNames The list of the leaf certificate SAN.
   * @returns The formatted SANs to be displayed in the tree view. If the translation in not found, display the name with a (?).
   */
  function formatLeafSubjectAlternativeNames(
    level: number,
    leafSubjectAlternativeNames: SubjectAlternativeNameI[],
  ) {
    let childrenLevelIndex = additionalLabelStartingIndex;
    const formattedSubjectAlternativeNames: TreeViewDataObject[] = [];
    const subjectAlternativeNamesAsMap = subjectAlternativeNameAsMap(
      leafSubjectAlternativeNames,
    );
    for (const subjectAlternativeName of subjectAlternativeNamesAsMap) {
      const id = subjectAlternativeName[0];
      const valueJoined = subjectAlternativeName[1].join(", ");
      const translationKey = `certificates.subjectAlternativeName.${id}.labelColon`;
      const labelPrefix = `${i18n.global.t(translationKey)}`;
      const isTranslated = translationKey !== labelPrefix;
      const translatedLabelPrefix = isTranslated ? labelPrefix : `${id} (?)`;
      formattedSubjectAlternativeNames.push({
        id: `${level}_${childrenLevelIndex}`,
        label: `${translatedLabelPrefix} ${valueJoined}`,
        children: [],
      });
      childrenLevelIndex++;
    }

    return formattedSubjectAlternativeNames;
  }

  /**
   * return a treeview data from a chain links
   * @param chainLinks
   * @param leafSubjectAlternativeNames
   */
  function getTreeviewDataFromChainLinks(
    chainLinks: CertificateChainLinkI[],
    leafSubjectAlternativeNames: SubjectAlternativeNameI[],
  ): TreeViewDataObject[] {
    const treeViewData: TreeViewDataObject[] = [];
    let parentTreeViewData: TreeViewDataObject | undefined;
    let level = 0;

    for (const currentChainLink of chainLinks) {
      const levelLabel = i18n.global.t("certificates.chain.level", { level });
      const vCurrentTreeViewData: TreeViewDataObject = {
        id: level,
        label:
          level === 0
            ? currentChainLink.cn
            : `${currentChainLink.cn} (${levelLabel})`,
        children: [
          {
            id: `${level}_${validFromIndex}`,
            label: `${i18n.global.t("certificates.start.timestamp.labelColon")} ${formatIsoDate(currentChainLink.validFrom, dateFormat)}`,
            children: [],
          },
          {
            id: `${level}_${validToIndex}`,
            label: `${i18n.global.t("certificates.expiration.timestamp.labelColon")} ${formatIsoDate(currentChainLink.validTo, dateFormat)}`,
            children: [],
          },
        ],
      };

      // If there is only one element in the chain or if it is the end of the chain (leaf), extract and display the SAN if defined.
      if (
        (level === chainLinks.length && level === 0) ||
        level === chainLinks.length - 1
      ) {
        vCurrentTreeViewData.children = [
          ...vCurrentTreeViewData.children,
          ...formatLeafSubjectAlternativeNames(
            level,
            leafSubjectAlternativeNames,
          ),
        ];
      }

      if (level === 0) {
        treeViewData.push(vCurrentTreeViewData);
      } else if (parentTreeViewData) {
        parentTreeViewData.children.push(vCurrentTreeViewData);
      }

      parentTreeViewData = vCurrentTreeViewData;
      level++;
    }

    return treeViewData;
  }

  return {
    getTreeviewDataFromChainLinks,
  };
}
