<script setup lang="ts">
import LxcForm from "@lxc/app-device-common/src/components/form/LxcForm.vue";
import type { SubjectAlternativeNameI } from "@lxc/app-device-types";
import { SubjectAlternativeNameId } from "@lxc/app-device-types";
import type {
  InternalRuleItem,
  RuleItem,
  Value,
} from "async-validator/dist-types/interface";
import { isIpAddressValid, isUrlValid } from "~/utils/certificate.utils";
import ILxcTrash2 from "~icons/lxc/trash-2";

interface SubjectAlternativeNameForm {
  subjectAlternativeNames: SubjectAlternativeNameI[];
}

interface Props {
  modelValue: SubjectAlternativeNameForm;
  isEditable: boolean;
}
const { modelValue } = defineProps<Props>();

const { t } = useI18n();

// For the moment, we only support DNS and IP address in the UI.
// Check the RFC 5280 for more details about the available IDs (https://datatracker.ietf.org/doc/html/rfc5280#section-4.2.1.6)
const supportedSubjectAlternativeNameIds = [
  SubjectAlternativeNameId.DNS,
  SubjectAlternativeNameId.IP_ADDRESS,
];

const form = computed(() => modelValue);
const formRef: Ref<typeof LxcForm | undefined> = ref();

const inputLabelClasses = ["pb-2", "font-medium", "text-sm"];

const addEmptySubjectAlternativeName = () => {
  form.value.subjectAlternativeNames.push({
    id: SubjectAlternativeNameId.DNS,
    value: "",
  });
};

const deleteSubjectAlternativeName = (index: number) => {
  if (form.value.subjectAlternativeNames.length > index) {
    form.value.subjectAlternativeNames.splice(index, 1);
  }
};

const isSubjectAlternativeNameValid = (
  value: string,
  subjectAlternativeNameId: SubjectAlternativeNameId,
): Error | undefined => {
  let error: Error | undefined;
  let isValueValid = true;
  switch (subjectAlternativeNameId) {
    case SubjectAlternativeNameId.DNS:
      if (!isUrlValid(value)) {
        isValueValid = false;
      }
      break;
    case SubjectAlternativeNameId.IP_ADDRESS:
      if (!isIpAddressValid(value)) {
        isValueValid = false;
      }
      break;
    default:
      error = new Error(t("certificates.subjectAlternativeName.unknownError"));
  }
  if (!isValueValid) {
    error = new Error(
      t(
        `certificates.subjectAlternativeName.${subjectAlternativeNameId}.invalidFormat`,
      ),
    );
  }
  return error;
};

const isValueValid = (
  rule: InternalRuleItem,
  value: Value,
  callback: (error?: string | Error) => void,
) => {
  let error: Error | undefined;
  if (value !== "") {
    try {
      const index = rule.field?.split("[")[1].split("]")[0];
      const subjectAlternativeNameId =
        form.value.subjectAlternativeNames[parseInt(index!)].id;
      error = isSubjectAlternativeNameValid(value, subjectAlternativeNameId);
    } catch (e) {
      console.error(
        `An unsupported error occurred during the SAN parsing. Error: ${e}`,
      );
      error = new Error(t("certificates.subjectAlternativeName.unknownError"));
    }
  } else {
    error = new Error(t("certificates.subjectAlternativeName.noValue"));
  }
  return error ? callback(error) : callback();
};

const rules = computed(() => {
  const formRules: Record<string, RuleItem[]> = {};
  for (const index in form.value.subjectAlternativeNames) {
    formRules[`subjectAlternativeNames[${index}].value`] = [
      {
        type: "string",
        validator: isValueValid,
      },
    ];
  }
  return formRules;
});

const validate = async () => {
  return formRef.value?.validate();
};

// Trigger the validation when the component is displayed to indicate the user
// that an input in the form is invalid. If the validation is not triggered when
// the component is mounted, hiding the section will clear the validation
// errors.
onMounted(async () => validate);

// Used by the root form to trigger the validation on a submit.
defineExpose({
  validate,
});
</script>

<template>
  <div
    v-if="isEditable"
    class="border rounded-lg border-gray-400 p-4 mb-1 hover:cursor-pointer hover:bg-gray-200"
    :title="t('certificates.subjectAlternativeName.add')"
    @click="addEmptySubjectAlternativeName"
  >
    <p class="text-gray-400 flex gap-4 my-0">
      <ILxcPlusCircle />
      <span class="font-semibold self-center select-none">
        {{ t("certificates.subjectAlternativeName.add") }}
      </span>
    </p>
  </div>
  <div
    v-else-if="modelValue?.subjectAlternativeNames.length === 0"
    class="pb-1"
  >
    <span>{{ t("certificates.subjectAlternativeName.empty") }}</span>
  </div>
  <lxc-form ref="formRef" :model="form" :rules="rules">
    <div class="mb-4">
      <div
        v-for="(subjectAlternativeName, index) in form.subjectAlternativeNames"
        :key="index"
        class="flex w-full py-1.5"
      >
        <div class="flex-none">
          <!-- `lxc-select` component only allow string or string[] (seen with @tho - need an update of the `lxc-select` and the `lxc-option`) -->
          <div class="flex flex-col">
            <span :class="inputLabelClasses">
              {{ t("certificates.subjectAlternativeName.type") }}
            </span>
            <lxc-select
              :key="form.subjectAlternativeNames[index].id"
              v-model.number="form.subjectAlternativeNames[index].id"
              class="w-24"
              :placeholder="
                t(
                  `certificates.subjectAlternativeName.${subjectAlternativeName.id}.label`,
                )
              "
              :disabled="!isEditable"
            >
              <lxc-option
                v-for="item in supportedSubjectAlternativeNameIds"
                :key="item"
                :label="t(`certificates.subjectAlternativeName.${item}.label`)"
                :value="item"
              />
            </lxc-select>
          </div>
        </div>
        <div class="flex-1 ml-3">
          <div class="flex flex-col">
            <span :class="inputLabelClasses">
              {{ t("certificates.subjectAlternativeName.value") }}
            </span>
            <lxc-form-item
              :prop="`subjectAlternativeNames[${index}].value`"
              class="!pb-0"
            >
              <div class="flex">
                <div class="flex-1">
                  <lxc-input
                    v-model="subjectAlternativeName.value"
                    type="text"
                    class="w-full"
                    :disabled="!isEditable"
                  />
                </div>
                <div
                  v-if="isEditable"
                  class="flex flex-col justify-center flex-none"
                >
                  <lxc-button
                    html-type="button"
                    type="borderless"
                    :icon="ILxcTrash2"
                    class="!py-0 !pl-2 !pr-0"
                    :title="t('certificates.subjectAlternativeName.delete')"
                    @click="deleteSubjectAlternativeName(index)"
                  />
                </div>
              </div>
            </lxc-form-item>
          </div>
        </div>
      </div>
    </div>
  </lxc-form>
</template>
