import RestService from "./rest.service";
import type {
  ApiListResult,
  CampaignI,
  CampaignOperationState,
  CampaignType,
  ObjectCampaignI,
  OperationI,
  ReportingConfigurationI,
  ReportingRecipientI,
} from "@lxc/app-device-types";
import type { FiltersSelection } from "~/types";

export class CampaignService extends RestService {
  protected CAMPAIGN_BASE_URL = "/campaigns";
  private CAMPAIGN_REPORTING_BASE_URL = "/campaigns/reporting";
  private PROXY_URL = "/proxy?serviceId=CAMPAIGN";

  /**
   * Retrieve campaigns
   * @param page
   * @param pageSize
   * @param search
   * @param sort
   */
  getCampaigns(
    page: number,
    pageSize: number,
    search?: string | FiltersSelection,
    sort?: string | null,
  ): Promise<ApiListResult<CampaignI>> {
    const params = { page, pageSize, sort, search };

    return this.$api.get(this.CAMPAIGN_BASE_URL, { params });
  }

  /**
   * Retrieve a campaign by its ID
   * @param id
   */
  getCampaign(id: string): Promise<CampaignI> {
    return this.$api.get(`${this.CAMPAIGN_BASE_URL}/${id}`);
  }

  /**
   * Cancel a campaign by its ID
   * @param id
   */
  cancelCampaign(id: string) {
    return this.$api.patch(`${this.CAMPAIGN_BASE_URL}/${id}/cancel`);
  }

  /**
   * Create a campaign
   */
  createCampaign(campaign: CampaignI): Promise<CampaignI> {
    return this.$api.post(this.CAMPAIGN_BASE_URL, { campaign });
  }

  /**
   * Update a campaign
   */
  updateCampaign(campaign: CampaignI): Promise<CampaignI> {
    return this.$api.patch(`${this.CAMPAIGN_BASE_URL}/${campaign.id}`, {
      campaign,
    });
  }

  /**
   * Retrieve reporting configuration
   */
  getReportingConfiguration(): Promise<ReportingConfigurationI> {
    return this.$api.get(this.CAMPAIGN_REPORTING_BASE_URL);
  }

  /**
   * Post reporting configuration
   * @param configuration
   */
  postReportingConfiguration(
    configuration: ReportingConfigurationI,
  ): Promise<ReportingConfigurationI> {
    return this.$api.post(this.CAMPAIGN_REPORTING_BASE_URL, { configuration });
  }

  /**
   * Add reporting recipients
   * @param configurationId
   * @param recipients
   * @returns
   */
  addReportingRecipients(
    configurationId: string,
    recipients: ReportingRecipientI[],
  ): Promise<ReportingRecipientI[]> {
    return this.$api.post(
      `${this.CAMPAIGN_REPORTING_BASE_URL}/${configurationId}/addRecipients`,
      { recipients },
    );
  }

  /**
   * Remove reporting recipients
   * @param configurationId
   * @param recipients
   * @returns
   */
  removeReportingRecipients(
    configurationId: string,
    recipients: ReportingRecipientI[],
  ): Promise<ReportingRecipientI[]> {
    return this.$api.post(
      `${this.CAMPAIGN_REPORTING_BASE_URL}/${configurationId}/removeRecipients`,
      { recipients },
    );
  }

  /**
   * Get a campaign object operations
   * @param objectId
   * @param page
   * @param pageSize
   * @param sort
   * @param campaignType
   * @returns
   */
  getCampaignObjectOperations(
    objectId: string,
    page?: number,
    pageSize?: number,
    sort?: string,
    campaignType?: CampaignType,
  ): Promise<ApiListResult<OperationI>> {
    let params = {};

    if (page) {
      params = { ...params, page };
    }

    if (pageSize) {
      params = { ...params, pageSize };
    }

    if (sort) {
      params = { ...params, sort };
    }

    if (campaignType) {
      params = { ...params, campaignType };
    }

    return this.$api.get(
      `${this.CAMPAIGN_BASE_URL}/objects/${objectId}/operations`,
      { params },
    );
  }

  /**
   * Get a campaign report by id
   * @param id
   * @returns
   */
  getCampaignReport(id: string): Promise<any> {
    return this.$api.get(`${this.PROXY_URL}&target=reports/${id}`);
  }

  /**
   * Get processed objects for a campaign
   * @param campaignId
   * @param page
   * @param pageSize
   * @param sort
   * @param label
   * @param objectTypes
   * @param states
   * @returns
   */
  getCampaignProcessedObjects(
    campaignId: string,
    page?: number,
    pageSize?: number,
    sort?: string,
    label?: string,
    objectTypes?: string[],
    states?: CampaignOperationState[],
  ): Promise<ApiListResult<ObjectCampaignI>> {
    let params = {};

    if (page) {
      params = { ...params, page };
    }

    if (pageSize) {
      params = { ...params, pageSize };
    }

    if (sort) {
      params = { ...params, sort };
    }

    if (label) {
      params = { ...params, label };
    }

    if (objectTypes) {
      params = { ...params, objectTypes };
    }

    if (states) {
      params = { ...params, states };
    }

    return this.$api.get(`${this.CAMPAIGN_BASE_URL}/${campaignId}/operations`, {
      params,
    });
  }
}

export default new CampaignService();
