<script setup lang="ts">
import { ElTreeSelect } from "element-plus";
import type { LxcTreeNodes } from "~/components/shared/lxcTreeSelect/LxcTreeNodes.model.ts";

const { t } = useI18n();

const elTreeNodeClass = "el-tree-node";
const treeContentClass = "tree-content";
const elTreeNodeChildrenClass = "el-tree-node__children";
const elIconClass = "el-icon";
const isLeafClass = "is-leaf";
const itemMarginByLevel = 22;
const itemMargin12 = 12;
const itemMargin12px = `${itemMargin12}px`;
const itemMargin6px = "6px";
const itemMargin3px = "3px";

const treeSelectElement: Ref<HTMLElement | null> = ref(null);
const selectedTreeValues = ref<string[]>();

const treeRef = ref<ElTreeSelect>();

const isSelectionValidated = ref(false);
const searchInputValue = ref<string>("");
const checkedTreeNodeValues = ref<string[]>([]);
const indeterminateNodesValue = ref<string[]>([]);
const previousCheckedNodes = ref<string[]>([]);
const dropDownUpdated = ref(false);
const isDropDownVisible = ref(false);

const props = withDefaults(
  defineProps<{
    /**
     * Data containing all nodes to render.
     */
    tree: LxcTreeNodes[];
    /**
     * Default checked nodes (list of value).
     * Default: empty list.
     */
    defaultCheckedValues?: string[];
    /**
     * Placement of the dropdown. Possible values: 'top' | 'top-start' | 'top-end' | 'bottom' | 'bottom-start' |
     *'bottom-end' | 'left' | 'left-start' | 'left-end' | 'right' | 'right-start' | 'right-end'.
     * Default: top-start
     */
    placement?: string;
    /**
     * Whether LxcTreeSelect is disabled.
     * Default: false.
     */
    disabled?: boolean;
    /**
     * Placeholder display when not nodes selected.
     * Default: null.
     */
    placeholder?: string;
    /**
     * Whether LxcTreeSelect can have no node selected.
     * Default: false.
     */
    allowEmptySelection?: boolean;
  }>(),
  {
    defaultCheckedValues: new Array<string>(),
    disabled: false,
    placement: "top-start",
    placeholder: null,
    allowEmptySelection: false,
  },
);

const emit = defineEmits(["onCheckedNodeValueChanged"]);

/**
 * Update global list of checkedSectorCodes with all up-to-date nodes value.
 */
const updateCheckedNodes: () => void = () => {
  if (props.tree) {
    let treeAsList = Array<LxcTreeNodes>();

    for (const rootNode of props.tree) {
      let flattenRootNode = flattenTree(rootNode);
      for (const node of flattenRootNode) {
        treeAsList.push(node);
      }
    }

    selectedTreeValues.value = treeAsList
      .filter((treeNode) =>
        checkedTreeNodeValues.value.includes(treeNode.value),
      )
      .map((treeNode) => treeNode.value);

    checkedTreeNodeValues.value = selectedTreeValues.value;
  }
};

function flattenTree(
  node: LxcTreeNodes,
  result: LxcTreeNodes[] = [],
): LxcTreeNodes[] {
  result.push(node);
  if ((node.children?.length ?? 0) > 0) {
    for (const child of node.children) {
      flattenTree(child, result);
    }
  }
  return result;
}

/**
 * Recursively find all selectable nodes for the given parent node
 * @param node parent node
 * @param result list all children (direct or indirect) of this node.
 */
function findAllSelectableTreeNodes(
  node: LxcTreeNodes,
  result: LxcTreeNodes[] = [],
): LxcTreeNodes[] {
  if (!node.disabled) {
    result.push(node);
  }
  if ((node.children?.length ?? 0) > 0) {
    for (const child of node.children) {
      if (!child.disabled) {
        findAllSelectableTreeNodes(child, result);
      }
    }
  }
  return result;
}

/**
 * Recursively compute all indeterminate nodes
 * @param node parent node to check
 * @param result result list containing all indeterminate nodes
 * @param children direct list of child for parent node. If defined, the node must add itself to declare itself has
 * a child of the parent node.
 */
function findAllIndeterminateNodes(
  node: LxcTreeNodes,
  result: string[] = [],
  children: LxcTreeNodes[] = null,
): LxcTreeNodes[] {
  if (children) {
    children.push(node);
  }

  // Find all direct child of current node
  let childrenTreeNodes = new Array<LxcTreeNodes>();
  if ((node.children?.length ?? 0) > 0) {
    for (const child of node.children) {
      findAllIndeterminateNodes(child, result, childrenTreeNodes);
    }
  }

  // If current node not checked, verify if it is indeterminate
  if (!checkedTreeNodeValues.value.includes(node.value)) {
    let hasAtLeastOneChildChecked = false;
    if (childrenTreeNodes.length > 0) {
      for (const child of childrenTreeNodes) {
        hasAtLeastOneChildChecked =
          checkedTreeNodeValues.value.includes(child.value) ||
          result.includes(child.value);
        if (hasAtLeastOneChildChecked) {
          break;
        }
      }
      if (hasAtLeastOneChildChecked) {
        result.push(node.value);
      }
    }
  }
  return result;
}

function onRemoveTag(tagValue: string) {
  // Prevent to remove the last tag since we always want to select at least one sector
  if (
    !props.allowEmptySelection &&
    !isDropDownVisible.value &&
    checkedTreeNodeValues.value.length === 0
  ) {
    checkedTreeNodeValues.value.push(tagValue);
    computeIndeterminateNodes();
  } else {
    updateCheckedNodes();
    computeIndeterminateNodes();
    // Only notify when checked node changes when dropdown is not visible to prevent conflict in node selection.
    if (!isDropDownVisible.value) {
      emit("onCheckedNodeValueChanged", checkedTreeNodeValues.value);
    }
  }
}

function onCheck(node: LxcTreeNodes) {
  // Click on an indeterminate node
  if (indeterminateNodesValue.value.includes(node.value)) {
    // Get all children of nodes
    let nodeValueChildren = flattenTree(node).map((treeNode) => treeNode.value);
    // Remove all indeterminate children
    indeterminateNodesValue.value = indeterminateNodesValue.value.filter(
      (value) => !nodeValueChildren.includes(value),
    );
    // Remove all checked children
    checkedTreeNodeValues.value = checkedTreeNodeValues.value.filter(
      (value) => !nodeValueChildren.includes(value),
    );
  } // Click on a not checked node
  else if (checkedTreeNodeValues.value.includes(node.value)) {
    let allNodes = findAllSelectableTreeNodes(node);
    for (const selectableNode of allNodes) {
      if (!checkedTreeNodeValues.value.includes(selectableNode.value)) {
        checkedTreeNodeValues.value.push(selectableNode.value);
      }
    }
  }

  computeIndeterminateNodes();
}

function computeIndeterminateNodes() {
  // Reset indeterminate state to false for all nodes in order to trigger view re-rendering
  updateIndeterminateState(false);

  // Compute indeterminate state for all nodes
  let nodes = Array<LxcTreeNodes>();
  if (props.tree) {
    for (const rootNode of props.tree) {
      let indeterminateNodes = findAllIndeterminateNodes(rootNode);
      for (const node of indeterminateNodes) {
        nodes.push(node);
      }
    }
  }

  indeterminateNodesValue.value = nodes;

  // Set indeterminate state to true for all computed indeterminate nodes in order to trigger view re-rendering
  updateIndeterminateState(true);
  // Hack to force refresh state of node. In some cases, the previous call does not work, and we need a second call with
  // a small delay to for re-rendering. This will have as a consequence to display a 'blink' on some nodes.
  setTimeout(() => updateIndeterminateState(true), 0);
}

function updateIndeterminateState(state: boolean) {
  for (const indeterminateNodeValue of indeterminateNodesValue.value) {
    let node = treeRef.value.getNode(indeterminateNodeValue);
    node.indeterminate = state;
  }
}

function onDropDownVisibilityChanged(isVisible: boolean) {
  isDropDownVisible.value = isVisible;
  if (!isVisible) {
    if (isSelectionValidated.value !== true) {
      resetSelection();
    }
  } else {
    previousCheckedNodes.value = checkedTreeNodeValues.value;
    isSelectionValidated.value = false;
  }

  // Set indeterminate state to true for all computed indeterminate nodes in order to trigger view re-rendering
  updateIndeterminateState(true);

  transformDom();
}

function resetSelection() {
  treeRef.value.setCheckedKeys(previousCheckedNodes.value, false);
  checkedTreeNodeValues.value = previousCheckedNodes.value;
  computeIndeterminateNodes();
}

function onSubmit() {
  updateCheckedNodes();
  isSelectionValidated.value = true;
  closeDropDown();

  emit("onCheckedNodeValueChanged", checkedTreeNodeValues.value);
}

function onCancel() {
  isSelectionValidated.value = false;
  closeDropDown();
}

function closeDropDown() {
  treeRef.value.focus();
  treeRef.value.blur();
  searchInputValue.value = "";
}

/**
 * Adapt el-select-tree component from Element plus to match LX CONNECT UI.
 * Invert arrow and checkbox, and change margins.
 */
function transformDom() {
  // Prevent to rework the DOM every time
  if (dropDownUpdated.value === true) {
    return;
  }
  dropDownUpdated.value = true;

  if (treeSelectElement.value) {
    const treeNodeContent = document.querySelectorAll(".el-tree-node__content");

    for (const elTreeElement of treeNodeContent.values()) {
      let nodeElement = elTreeElement as HTMLElement;

      // Compute level
      let level = 0;
      let parent = nodeElement.parentElement;
      while (
        parent.classList.contains(elTreeNodeClass) ||
        parent.classList.contains(elTreeNodeChildrenClass)
      ) {
        if (parent.classList.contains(elTreeNodeChildrenClass)) {
          level++;
        }
        parent = parent.parentElement;
      }

      let arrowNode = elTreeElement.childNodes.item(0);
      let checkbox;
      let checkboxHTMLElement;
      if (arrowNode.classList.contains(elIconClass)) {
        checkbox = elTreeElement.childNodes.item(1);
        checkboxHTMLElement = checkbox as HTMLElement;
        let arrowNodeHTMLElement = arrowNode as HTMLElement;

        elTreeElement.removeChild(checkbox);
        elTreeElement.insertBefore(checkbox, arrowNode);

        if (arrowNodeHTMLElement.classList.contains(isLeafClass)) {
          elTreeElement.removeChild(arrowNode);
          checkboxHTMLElement.style.marginRight = itemMargin12px;
        } else {
          arrowNodeHTMLElement.style.marginRight = itemMargin6px;
          arrowNodeHTMLElement.style.marginLeft = itemMargin3px;

          // By default, only expand first node
          if (level == 0) {
            arrowNodeHTMLElement.click();
          }
        }
      } else {
        checkbox = elTreeElement.childNodes.item(0);
        checkboxHTMLElement = checkbox as HTMLElement;
      }

      if (level == 0) {
        nodeElement.style.marginLeft = itemMargin12px;
      } else {
        nodeElement.style.marginLeft = `${itemMargin12 + level * itemMarginByLevel}px`;
      }

      nodeElement.style.marginRight = itemMargin12px;
      nodeElement.classList.add(treeContentClass);

      checkboxHTMLElement.style.marginLeft = itemMargin6px;
    }
  }
}

/**
 * Method to render the content (label here). The class will change function of it is selected or not
 */
const renderContent = (
  h,
  {
    node,
  }: {
    node: Node;
    data: Tree;
    store: Node["store"];
  },
) => {
  let displayClass = "custom-tree-node";
  if (node["disabled"] == true) {
    displayClass = "custom-tree-node-disabled";
  } else if (node["checked"] == true) {
    displayClass = "custom-tree-node-checked";
  }

  return h(
    "span",
    {
      id: node.value,
      title: node.label,
      class: displayClass,
    },
    h("span", null, node.label),
  );
};

watch(searchInputValue, (val) => {
  treeRef.value.filter(val);
});

onMounted(async () => {
  checkedTreeNodeValues.value = props.defaultCheckedValues ?? [];
  updateCheckedNodes();
  computeIndeterminateNodes();
});
</script>

<template>
  <div ref="treeSelectElement">
    <el-tree-select
      ref="treeRef"
      v-model="checkedTreeNodeValues"
      node-key="value"
      :placement="placement"
      small="small"
      :disabled="disabled"
      :data="tree"
      :placeholder="placeholder"
      :indent="0"
      :expand-on-click-node="false"
      :teleported="true"
      :render-after-expand="false"
      :default-expand-all="false"
      :render-content="renderContent"
      :max-collapse-tags="2"
      multiple
      collapse-tags
      collapse-tags-tooltip
      check-on-click-node
      check-strictly
      show-checkbox
      @visible-change="onDropDownVisibilityChanged"
      @check="onCheck"
      @remove-tag="onRemoveTag"
    >
      <template #header>
        <lxc-search-input
          v-model="searchInputValue"
          class="w-full display-block"
          :placeholder="t('sectors.search')"
          :filter-search-txt-min-length="1"
        />
      </template>

      <template #footer>
        <div class="grid grid-cols-[max-content_auto] gap-4">
          <lxc-button
            html-type="button"
            type="secondary"
            :title="t('button.cancel')"
            @click.prevent="onCancel"
          >
            {{ t("button.cancel") }}
          </lxc-button>
          <lxc-button
            html-type="button"
            :disabled="
              !allowEmptySelection && checkedTreeNodeValues.length === 0
            "
            :title="t('button.validate')"
            @click.prevent="onSubmit"
          >
            {{ t("button.validate") }}
          </lxc-button>
        </div>
      </template>
    </el-tree-select>
  </div>
</template>
<style lang="scss">
.tree-content {
  border-radius: 6px;
  margin-bottom: 3px;
  padding: 16px;
  margin-top: 4px;
  border: 1px solid $color-gray-200;
  pointer-events: auto;
}

.el-tree-node {
  pointer-events: none;
}

.el-tree-node__children {
  overflow: clip !important;
}

.el-tag {
  background-color: #e6ebf1 !important;
  border-radius: 16px !important;
  color: $color-primary-700 !important;
}

.custom-tree-node {
  font-size: 16px;
  text-align: center;
  text-overflow: ellipsis;
  line-height: 22px;
  font-weight: 500;
  color: $color-gray-700;
  user-select: none;
}

.custom-tree-node-checked {
  @extend .custom-tree-node;
  font-weight: 600;
  color: $color-primary-700;
}

.custom-tree-node-disabled {
  @extend .custom-tree-node;
  font-weight: 400;
  color: $color-gray-300;
}

// Warning: if we use $color-XXX variables with this component, there are not applied and the checkbox does not
// behave correctly (the half status is not displayed).
.el-checkbox {
  --el-checkbox-border-radius: 4px !important;
  --el-checkbox-border-color: #d0d5dd !important;
  --el-checkbox-checked-bg-color: #e6ebf1 !important;
  --el-checkbox-checked-border-color: #1b538d !important;
  --el-checkbox-checked-icon-color: #1b538d !important;
  --el-checkbox-input-height: 16px !important;
  --el-checkbox-input-width: 16px !important;
}

.el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
  top: 6px !important;
}

.el-checkbox__inner::after {
  height: 8px !important;
  left: 5px !important;
  top: 2px !important;
}

.el-tree-node__expand-icon:before {
  content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round"><polyline points="9 18 15 12 9 6"></polyline></svg>') !important;
}

.el-select__icon:before {
  content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"></polyline></svg>') !important;
}

.el-select {
  width: 100% !important;
  height: 2.5rem !important;
  border-radius: 4px !important;
  border-color: $color-gray-300 !important;
}

.el-select__wrapper {
  width: 100% !important;
  min-height: 40px !important;
  font-size: 16px !important;
  color: $color-gray-500 !important;
  --el-text-color-placeholder: $color-gray-500 !important;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.is-selected::after {
  display: none !important;
}

.el-select-dropdown {
  max-width: 36rem !important;
}

.el-select-dropdown__header {
  padding: 4px 10px !important;
  border-bottom: 0 !important;
}

.el-select-dropdown__footer {
  padding: 8px 10px !important;
  border-top: 0 !important;
}

.el-popper {
  max-width: 80rem !important;
}

.el-select__tags-text {
  max-width: 76rem !important;
}
</style>
