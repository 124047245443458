<script setup lang="ts">
import type { DtwinI } from "@lxc/app-device-types";
import { NO_CONTENT } from "~/components/dtwins/dtwinsForm/LxcDtwinsFormType";
import { PATHS } from "~/constants/paths";
import { formatIsoDate } from "~/utils/date-tools";
import ILxcArrowRightCircle from "~icons/lxc/arrow-right-circle";

const { t } = useI18n();
const dateFormat: string = t("dateFormat.datetime");

const props = defineProps<{
  dtwin: DtwinI;
  modelLabel: string;
}>();

const latLongRepresentation = computed(() => {
  let value = NO_CONTENT;
  if (
    props.dtwin.features?.location?.reported?.latitude !== undefined &&
    props.dtwin.features?.location?.reported?.longitude !== undefined
  ) {
    value = t("dtwins.form.description.geolocation.attributes.latlong", {
      latitude: props.dtwin.features?.location?.reported?.latitude,
      longitude: props.dtwin.features?.location?.reported?.longitude,
    });
  }
  return value;
});
</script>

<template>
  <div class="p-4">
    <lxc-form-item :label="t('dtwins.form.description.idCard.attributes.type')">
      <lxc-input :value="modelLabel" type="text" read-only />
    </lxc-form-item>
    <lxc-form-item
      :label="t('dtwins.form.description.idCard.attributes.serialNumber')"
    >
      <lxc-input :value="dtwin.attributes.serialNumber" type="text" read-only />
    </lxc-form-item>
    <lxc-form-item :label="t('dtwins.form.description.state.attributes.state')">
      <lxc-input
        :value="
          dtwin.lifeCycleState
            ? t(`dtwins.lifeCycleState.${props.dtwin?.lifeCycleState}`)
            : NO_CONTENT
        "
        type="text"
        read-only
      />
    </lxc-form-item>
    <lxc-form-item
      :label="t('dtwins.form.description.state.attributes.lastConnection')"
    >
      <lxc-input
        :value="
          dtwin.lastConnectionDate
            ? formatIsoDate(props.dtwin.lastConnectionDate, dateFormat)
            : NO_CONTENT
        "
        type="text"
        read-only
      />
    </lxc-form-item>
    <lxc-form-item
      :label="t('dtwins.form.description.geolocation.attributes.address')"
    >
      <lxc-input
        :value="dtwin?.attributes?.address ?? NO_CONTENT"
        type="text"
        read-only
      />
    </lxc-form-item>
    <lxc-form-item
      :label="t('dtwins.form.description.geolocation.attributes.status')"
    >
      <lxc-input v-model="latLongRepresentation" type="text" read-only />
    </lxc-form-item>
    <lxc-button
      :title="t('button.validate')"
      @click="$router.push(`${PATHS.DTWINS}/${dtwin.uid}`)"
    >
      <div class="flex items-center">
        <span>{{
          t("dtwins.cartography.deviceMapTile.button.seeDevice")
        }}</span>
        <ILxcArrowRightCircle class="ml-2" width="1.25rem" height="1.25rem" />
      </div>
    </lxc-button>
  </div>
</template>
