<script setup lang="ts">
import { useSerialize } from "@lxc/app-device-common";
import type { FirmwareI } from "@lxc/app-device-types";
import { FirmwareStatus } from "@lxc/app-device-types";
import {
  useActivateFirmware,
  useDeactivateFirmware,
  useDeleteFirmware,
} from "~/composables/useFirmware";
import firmwareService from "~/services/firmware.service";
import LxcError from "~/utils/LxcError";
import {
  NotificationKey,
  showNotificationSuccess,
} from "~/utils/notifications-tools";
import ILxcLightRotateCw from "~icons/lxc-light/rotate-cw";
import ILxcLightSlash from "~icons/lxc-light/slash";
import ILxcLightTrash2 from "~icons/lxc-light/trash-2";

const props = defineProps<{
  sideCanvasShown: boolean;
  uuid?: string | null;
}>();
const emit = defineEmits([
  "close",
  "delete",
  "change",
  "update:sideCanvasShown",
]);

const { t } = useI18n();
const serialize = useSerialize();
const {
  canDeleteFirmware,
  checkFirmwareStateBeforeDeletion,
  deleteError,
  deleteFirmware,
  deleteConfirmDialogVisible,
  isDeleteLoading,
  isEmbedded,
  toggleDeleteConfirmVisible,
} = useDeleteFirmware();

const {
  activateConfirmDialogVisible,
  activateFirmware,
  activateError,
  canActivateFirmware,
  isActivateLoading,
  toggleActivateConfirmVisible,
} = useActivateFirmware();

const {
  deactivateConfirmDialogVisible,
  deactivateFirmware,
  deactivateError,
  canDeactivateFirmware,
  isDeactivateLoading,
  toggleDeactivateConfirmVisible,
} = useDeactivateFirmware();

const formSideCanvasShown = computed({
  get: () => {
    return props.sideCanvasShown;
  },
  set: (shown: boolean) => {
    emit("update:sideCanvasShown", shown);
  },
});

const isLoading = ref<boolean>(true);
const error = ref<LxcError | null | undefined>();
const edited: Ref<boolean> = ref(false);
const defaultFirmwareData: FirmwareI = {
  uuid: "",
  name: "",
  description: "",
  version: "",
  range: "",
  declination: "",
  hardwareVersions: [],
  firmwareVersions: [],
  descriptor: "",
};
const firmwareData = reactive<FirmwareI>(
  Object.assign({}, defaultFirmwareData),
);
let initialFirmwareData: string = serialize(defaultFirmwareData);

const dispatchClose = (evt: Event) => {
  emit("close", {
    edited: edited.value,
  });
};

const activateFirmwareVisible = computed(
  () =>
    firmwareData?.status === FirmwareStatus.DEACTIVATED &&
    canActivateFirmware.value,
);
const deactivateFirmwareVisible = computed(
  () =>
    firmwareData?.status === FirmwareStatus.ACTIVATED &&
    canDeactivateFirmware.value,
);

const sectionClass = "border-solid border-b border-gray-300 pb-6 mt-6";
const labelClass = "text-gray-500 mb-3 mt-4";
const valueClass = "text-base font-medium text-gray-700";

function setFirmwareForm(paramFirmwareData?: FirmwareI) {
  Object.assign(firmwareData, paramFirmwareData || defaultFirmwareData);
  initialFirmwareData = serialize(firmwareData);
}

const getFirmwareByUuid = async () => {
  error.value = null;

  if (props.uuid) {
    isLoading.value = true;
    const response = await firmwareService.getFirmwareByUuid(props.uuid);
    if (LxcError.check(response)) {
      error.value = response;
    } else {
      setFirmwareForm(response);
    }
    isLoading.value = false;
  }
};

const updateEdited = (firmware?: FirmwareI) => {
  edited.value = serialize(firmware ?? {}) !== initialFirmwareData;
};

const onDeleteFirmwareAndPropagateChange = async () => {
  await deleteFirmware(firmwareData);

  if (LxcError.check(deleteError.value)) {
    (deleteError.value as LxcError).notify(NotificationKey.removeError);
  } else {
    showNotificationSuccess(t(NotificationKey.removeSuccess));
    emit("delete");
  }
};

const onCheckFirmwareStateBeforeDeletion = async (event: Event) => {
  if (firmwareData.uuid) {
    await checkFirmwareStateBeforeDeletion(event, firmwareData.uuid);

    if (LxcError.check(deleteError.value)) {
      (deleteError.value as LxcError).notify(NotificationKey.error);
    }
  }
};

const confirmActivateFirmware = async () => {
  if (firmwareData.uuid) {
    toggleActivateConfirmVisible();
  }
};

const confirmDeactivateFirmware = async () => {
  if (firmwareData.uuid) {
    toggleDeactivateConfirmVisible();
  }
};

const onActivateFirmware = async () => {
  await activateFirmware(firmwareData);

  if (LxcError.check(activateError.value)) {
    (activateError.value as LxcError).notify(NotificationKey.saveError);
  } else {
    showNotificationSuccess(t(NotificationKey.saveSuccess));
    firmwareData.status = FirmwareStatus.ACTIVATED;
  }
};

const onDeactivateFirmware = async () => {
  await deactivateFirmware(firmwareData);

  if (LxcError.check(deactivateError.value)) {
    (deactivateError.value as LxcError).notify(NotificationKey.saveError);
  } else {
    showNotificationSuccess(t(NotificationKey.saveSuccess));
    firmwareData.status = FirmwareStatus.DEACTIVATED;
  }
};

watch(() => props.uuid, getFirmwareByUuid);
watch(() => firmwareData, updateEdited, { deep: true });

onMounted(getFirmwareByUuid);
</script>

<template>
  <lxc-side-canvas
    v-model:show="formSideCanvasShown"
    size="1/3"
    :header="firmwareData.name"
    :close-tooltip="t('button.close')"
    body-class="!pt-2 !pb-4 bg-white"
    @discard="dispatchClose"
  >
    <lxc-container
      :is-loading="isLoading"
      :error="error"
      :px="0"
      :py="0"
      class="bg-white"
    >
      <section :class="sectionClass">
        <lxc-firmware-side-section-header>
          {{ t("firmware.section.informations.title") }}
        </lxc-firmware-side-section-header>
        <div :class="labelClass">
          {{ t("firmware.form.name") }}
        </div>
        <div :class="valueClass">
          {{ firmwareData.name }}
        </div>
        <div :class="labelClass">
          {{ t("firmware.form.version") }}
        </div>
        <div :class="valueClass">
          {{ firmwareData.version }}
        </div>
      </section>

      <section :class="sectionClass">
        <lxc-firmware-side-section-header>
          {{ t("firmware.section.compatibility.title") }}
        </lxc-firmware-side-section-header>
        <div :class="labelClass">
          {{ t("firmware.form.range") }}
        </div>
        <div :class="valueClass">
          {{ firmwareData.range }}
        </div>
        <div v-if="firmwareData.declination" :class="labelClass">
          {{ t("firmware.form.declination") }}
        </div>
        <div v-if="firmwareData.declination" :class="valueClass">
          {{ firmwareData.declination }}
        </div>
        <div :class="labelClass">
          {{ t("firmware.form.hardwareVersions") }}
        </div>
        <lxc-firmware-property-list
          :items="firmwareData.hardwareVersions"
          type="primary"
        />
        <div :class="labelClass">
          {{ t("firmware.form.firmwareVersions") }}
        </div>
        <lxc-firmware-property-list
          :items="firmwareData.firmwareVersions"
          type="primary"
        />
      </section>

      <section :class="sectionClass">
        <lxc-firmware-side-section-header>
          {{ t("firmware.section.complements.title") }}
        </lxc-firmware-side-section-header>
        <div :class="labelClass">
          {{ t("firmware.form.status") }}
        </div>
        <div :class="valueClass">
          <lxc-status-badge :status="firmwareData.status" />
        </div>
        <div :class="labelClass">
          {{ t("firmware.form.description") }}
        </div>
        <div :class="valueClass">
          {{ firmwareData.description }}
        </div>
      </section>
      <div class="mt-8">
        <lxc-button
          html-type="button"
          type="danger"
          :disabled="
            isDeleteLoading ||
            isActivateLoading ||
            isDeactivateLoading ||
            !canDeleteFirmware
          "
          :icon="!isDeleteLoading ? ILxcLightTrash2 : undefined"
          :title="t('firmware.actions.delete.label')"
          class="whitespace-nowrap"
          @click="onCheckFirmwareStateBeforeDeletion"
        >
          <div
            v-if="isDeleteLoading"
            class="inline-block align-middle relative"
          >
            <lxc-loader />
          </div>
          {{ t("firmware.actions.delete.label") }}
        </lxc-button>
      </div>
    </lxc-container>
    <template #footer>
      <div class="grid grid-cols-[max-content_auto] gap-4">
        <lxc-button
          html-type="button"
          type="secondary"
          :title="t('button.close')"
          @click="dispatchClose"
        >
          {{ t("button.close") }}
        </lxc-button>
        <lxc-button
          v-if="activateFirmwareVisible"
          html-type="button"
          type="primary"
          :disabled="
            isActivateLoading ||
            isDeactivateLoading ||
            isDeleteLoading ||
            !canActivateFirmware
          "
          :icon="!isActivateLoading ? ILxcLightRotateCw : undefined"
          :title="t('firmware.actions.activate.label')"
          class="whitespace-nowrap"
          @click="confirmActivateFirmware"
        >
          <div
            v-if="isActivateLoading"
            class="inline-block align-middle relative"
          >
            <lxc-loader />
          </div>
          {{ t("firmware.actions.activate.label") }}
        </lxc-button>
        <lxc-button
          v-if="deactivateFirmwareVisible"
          html-type="button"
          type="secondary"
          :disabled="
            isDeactivateLoading ||
            isActivateLoading ||
            isDeleteLoading ||
            !canDeactivateFirmware
          "
          :icon="!isDeactivateLoading ? ILxcLightSlash : undefined"
          :title="t('firmware.actions.deactivate.label')"
          class="whitespace-nowrap"
          @click="confirmDeactivateFirmware"
        >
          <div
            v-if="isDeactivateLoading"
            class="inline-block align-middle relative"
          >
            <lxc-loader />
          </div>
          {{ t("firmware.actions.deactivate.label") }}
        </lxc-button>
      </div>
    </template>
  </lxc-side-canvas>
  <lxc-confirm-modal
    :is-dialog-visible="deleteConfirmDialogVisible"
    :title="t('firmware.actions.delete.label')"
    :description="
      isEmbedded
        ? t('firmware.actions.delete.descriptionEmbedded', {
            name: firmwareData.name,
          })
        : t('firmware.actions.delete.description', { name: firmwareData.name })
    "
    @cancel="toggleDeleteConfirmVisible"
    @confirm="onDeleteFirmwareAndPropagateChange"
  />
  <lxc-confirm-modal
    :is-dialog-visible="activateConfirmDialogVisible"
    :title="t('firmware.actions.activate.label')"
    :description="
      t('firmware.actions.activate.description', { name: firmwareData.name })
    "
    @cancel="toggleActivateConfirmVisible"
    @confirm="onActivateFirmware"
  />
  <lxc-confirm-modal
    :is-dialog-visible="deactivateConfirmDialogVisible"
    :title="t('firmware.actions.deactivate.label')"
    :description="
      t('firmware.actions.deactivate.description', { name: firmwareData.name })
    "
    @cancel="toggleDeactivateConfirmVisible"
    @confirm="onDeactivateFirmware"
  />
</template>
