import RestService from "./rest.service";
import type {
  ApiListResult,
  DeviceModelConfigI,
  DeviceModelUiConfigI,
  DtwinI,
  DtwinLifeCycleState,
  DtwinModelI,
  ImportMode,
  ImportResultsI,
  PaginatedListI,
} from "@lxc/app-device-types";
import type { DeviceTypeI } from "@lxc/app-device-types/dist/interfaces/mobile.interface";
import type { AxiosProgressEvent, AxiosRequestConfig } from "axios";
import type { FiltersSelection } from "~/types";

export class DtwinsService extends RestService {
  protected BASE_URL = "/dtwins";
  protected PROXY_URL_DTWINS = "/proxy?serviceId=DTWINS";
  protected PROXY_URL_DATAM = "/proxy?serviceId=DATAM";

  /**
   * Retrieve the device models
   * @param page
   * @param pageSize
   * @param filters
   * @returns
   */
  getModels(
    page: number,
    pageSize: number,
    filters?: string | FiltersSelection,
  ): Promise<PaginatedListI<DtwinModelI>> {
    const params: Record<string, string> = {
      page: page.toString(),
      pageSize: pageSize.toString(),
      filters: filters as string,
    };

    const target = `deviceModels?${new URLSearchParams(params).toString()}`;

    return this.$api.get(this.PROXY_URL_DTWINS, { params: { target } });
  }

  /**
   * Fetch LPP4/LogUp devices.
   * @param page
   * @param pageSize
   * @param filter
   * @param sort
   * @param additionalParams
   */
  async getDtwins(
    page: number,
    pageSize: number,
    filter?: string | FiltersSelection,
    sort?: string | null,
    additionalParams?: Map<string, unknown>,
  ): Promise<ApiListResult<DtwinI>> {
    let filterWithCompatibilityCriteria = filter as string;
    const compatibilityCriteria = additionalParams?.get(
      "compatibilityCriteria",
    );
    if (compatibilityCriteria) {
      filterWithCompatibilityCriteria += `${filterWithCompatibilityCriteria ? ";" : ""}${compatibilityCriteria}`;
    }
    const params: Record<string, string> = {
      page: page.toString(),
      pageSize: pageSize.toString(),
      filter: filterWithCompatibilityCriteria,
    };

    if (sort) {
      params["sort"] = sort;
    }

    const config: AxiosRequestConfig = {
      params,
    };

    return await this.$api
      .get(`${this.BASE_URL}`, config)
      .then((response) => {
        if (
          !(response as unknown as PaginatedListI<DtwinI>).pagination &&
          !(response as unknown as PaginatedListI<DtwinI>).results
        ) {
          return response;
        }
        return {
          context: (response as unknown as PaginatedListI<DtwinI>).pagination,
          data: (response as unknown as PaginatedListI<DtwinI>).results,
        };
      })
      .catch((error) => error);
  }

  /**
   * Fetch device types
   * @returns
   */
  async getDtwinTypes(): Promise<DeviceTypeI[]> {
    return this.$api.get(`${this.BASE_URL}/types`);
  }

  /**
   * Retrieve device
   * @param id
   * @param showMetadata If set to true, ask the dtwin to add the device metadata. Default: false.
   * @param addOperationStatuses If set true, AS will add last operations statuses to the Dtwin object.
   */
  getDtwin(
    id: string,
    showMetadata?: boolean,
    addOperationStatuses?: boolean,
  ): Promise<DtwinI> {
    return this.$api.get(`${this.BASE_URL}/${id}`, {
      params: {
        showMetadata,
        addOperationStatuses,
      },
    });
  }

  /**
   * Update device properties
   * @param uid
   * @param data
   */
  patchDtwin(uid: string, data: Record<string, any>): Promise<void> {
    return this.$api.patch(`${this.PROXY_URL_DTWINS}`, data, {
      params: {
        target: `deviceTwins/${uid}/attributes`,
      },
    });
  }

  /**
   * Import devices
   */
  import(
    file: File,
    progress: Ref<number | undefined | null>,
    analyze: ImportMode,
  ): Promise<ImportResultsI> {
    const formData = new FormData();
    formData.append("file", file);
    return this.$api.post(`${this.BASE_URL}/import?mode=${analyze}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        progress.value = progressEvent.total
          ? (progressEvent.loaded / progressEvent.total) * 100
          : 0;
      },
      timeout: Infinity,
    });
  }

  /**
   * Retrieve device configuration
   * @param id
   * @returns
   */
  getDtwinTelemetries(id: string): Promise<any> {
    return this.$api.get(`${this.PROXY_URL_DTWINS}`, {
      params: { target: `deviceTwins/${id}/dataviz/configuration` },
    });
  }

  getDtwinTelemetryValues(
    id: string,
    name: string,
    startTimestampNs: number,
    endTimestampNs: number,
    calculatedTelemetry: boolean,
  ): Promise<any> {
    return this.$api.get(this.PROXY_URL_DATAM, {
      params: {
        target: `devices/${id}/telemetry/${name}?startDateTime=${startTimestampNs}&endDateTime=${endTimestampNs}&calculatedTelemetry=${calculatedTelemetry}`,
      },
    });
  }

  getDeviceModelConfig(uid: string): Promise<DeviceModelConfigI> {
    return this.$api.get(`${this.BASE_URL}/deviceModels/${uid}/config`);
  }

  async updateDeviceLifeCycleState(
    uid: string,
    lifeCycleState: DtwinLifeCycleState,
  ): Promise<void> {
    return this.$api.put(
      `${this.BASE_URL}/${uid}/lifeCycleState/${lifeCycleState}`,
    );
  }

  async getDeviceModelUiConfigByDeviceModelUid(
    deviceModelUid: string,
  ): Promise<DeviceModelUiConfigI> {
    return this.$api.get(
      `${this.BASE_URL}/deviceModels/${deviceModelUid}/uiConfig`,
    );
  }
}

export default new DtwinsService();
