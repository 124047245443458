import type { DtwinModelI } from "@lxc/app-device-types";
import { useFetchPage } from "~/composables/useFetchPage";
import type { SearchMode } from "~/composables/useSearch";
import { useSearch } from "~/composables/useSearch";
import { useSort } from "~/composables/useSort";
import DtwinsService from "~/services/dtwins.service";
import type { FiltersSelection } from "~/types";
import { Filters, FiltersType, OPERATOR_RSQL_EQUAL } from "~/types";
import type LxcError from "~/utils/LxcError";
import { fetchAllPaginatedList } from "~/utils/fetch.utils";

export function useDtwinModels(
  searchMode?: SearchMode,
  useQueryParametersForPagination?: boolean,
) {
  const service = DtwinsService.getModels.bind(DtwinsService);

  const appliedFilters: FiltersSelection = new Map([
    [Filters.NAME, { key: "name", operator: OPERATOR_RSQL_EQUAL, value: "" }],
  ]);

  const { searchParams, filters, setFilter, search, onSearch } = useSearch(
    FiltersType.RSQL,
    appliedFilters,
    searchMode,
  );
  const { sort, onSortChange } = useSort();
  const { isLoading, results, error, fetchData } = useFetchPage({
    service,
    searchParams,
    sort,
    useQueryParametersForPagination,
  });

  // TODO: Refactor needed to avoid using 999999999 for pageSize
  async function fetchAllModels() {
    await fetchData(1, 999999999);
  }

  async function fetchAllModelsNoPagination(): Promise<
    DtwinModelI[] | LxcError
  > {
    return await fetchAllPaginatedList((page: number, pageSize: number) =>
      DtwinsService.getModels(page, pageSize),
    );
  }

  return {
    filters,
    setFilter,
    search,
    onSearch,
    results,
    isLoading,
    error,
    fetchAllModels,
    fetchAllModelsNoPagination,
    onSortChange,
  };
}
