<script lang="ts" setup>
import type { SectorI } from "@lxc/app-device-types";
import type { Ref } from "vue";
import { onMounted, ref } from "vue";
import { useAcl } from "vue-simple-acl";
import type { TabNavigation } from "~/components/shared/tabs/LxcTabsWithNavigation.vue";
import { isFeatureEnabled } from "~/composables/useFeature";
import { PATHS } from "~/constants/paths";
import SectorsService from "~/services/sectors.service";
import { ACL_ROLES } from "~/types";
import { APP_FEATURES } from "~/types/appFeatures";
import LxcError from "~/utils/LxcError";

const acl = useAcl();
const route = useRoute();
const router = useRouter();
const sectorCode: Ref<string> = ref(route.params.code.toString());

const isLoading = ref(false);
const sector: Ref<SectorI | null> = ref(null);
const error: Ref<LxcError | null> = ref(null);

async function fetchSector() {
  isLoading.value = true;
  const response = await SectorsService.getSectorByCode(sectorCode.value);

  if (LxcError.check(response)) {
    error.value = response;
  } else {
    sector.value = response;
    error.value = null;
  }

  isLoading.value = false;
}

async function navigateToSectorList() {
  await router.push(PATHS.SECTORS);
}

//
// Navigation section. Logic / configuration responsible for the navigation.
//
const devicesTabIndex = 0;
const dtwinsTabIndex = 1;
const applicationsTabIndex = 2;
const usersTabIndex = 3;
const groupsTabIndex = 4;

const tabNavigations = computed(() => {
  const tabNavigations: TabNavigation[] = [];
  if (acl.can(ACL_ROLES.DISPLAY_DEVICES)) {
    tabNavigations.push({
      index: devicesTabIndex,
      redirectPath: `${PATHS.SECTORS}/${sectorCode.value}/${PATHS.DEVICES_DVTM_ESOFT_SUBPATH}`,
      labelTranslationKey: "sectors.details.tab.device.label",
      forceRefresh: true,
    });
  }
  if (isFeatureEnabled(APP_FEATURES.DTWINS)) {
    tabNavigations.push({
      index: dtwinsTabIndex,
      redirectPath: `${PATHS.SECTORS}/${sectorCode.value}/${PATHS.DTWINS_SUBPATH}`,
      labelTranslationKey: "sectors.details.tab.dtwins.label",
      forceRefresh: true,
    });
  }
  if (acl.can(ACL_ROLES.DISPLAY_APPLICATIONS)) {
    tabNavigations.push({
      index: applicationsTabIndex,
      redirectPath: `${PATHS.SECTORS}/${sectorCode.value}/${PATHS.APPLICATIONS_SUBPATH}`,
      labelTranslationKey: "sectors.details.tab.application.label",
      forceRefresh: true,
    });
  }
  if (acl.can(ACL_ROLES.DISPLAY_USERS)) {
    tabNavigations.push(
      {
        index: usersTabIndex,
        redirectPath: `${PATHS.SECTORS}/${sectorCode.value}/${PATHS.USERS_SUBPATH}`,
        labelTranslationKey: "sectors.details.tab.user.label",
        forceRefresh: true,
      },
      {
        index: groupsTabIndex,
        redirectPath: `${PATHS.SECTORS}/${sectorCode.value}/${PATHS.GROUPS_SUBPATH}`,
        labelTranslationKey: "sectors.details.tab.userGroup.label",
        forceRefresh: true,
      },
    );
  }
  return tabNavigations;
});

//
// Lifecycle Hooks. See: https://vuejs.org/api/composition-api-lifecycle
//
onMounted(fetchSector);
</script>

<template>
  <lxc-container v-if="sector" :px="8">
    <lxc-breadcrumb
      :name="sector.label"
      back
      :back-callback="navigateToSectorList"
      should-not-translate
    />
    <div v-if="sector?.description" class="mt-8 sector-description-container">
      <p>{{ sector?.description }}</p>
    </div>
    <LxcTabsWithNavigation :tab-navigations="tabNavigations">
      <template #[devicesTabIndex]>
        <LxcSectorDetailDevices :sector="sector" />
      </template>
      <template #[dtwinsTabIndex]>
        <LxcSectorDetailDtwins :sector="sector" />
      </template>
      <template #[applicationsTabIndex]>
        <LxcSectorDetailApplications :sector="sector" />
      </template>
      <template #[usersTabIndex]>
        <LxcSectorDetailUsers :sector="sector" />
      </template>
      <template #[groupsTabIndex]>
        <LxcSectorDetailUserGroups :sector="sector" />
      </template>
    </LxcTabsWithNavigation>
  </lxc-container>
</template>

<style lang="scss" scoped>
.sector-description-container {
  p {
    font-size: 14px;
    margin: 0;
  }
}
</style>
