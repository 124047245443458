<script setup lang="ts">
import { CACertificateItemType } from "@lxc/app-device-types";
import { useExportCertificateAsString } from "~/composables/useCertificateExport";
import keystoreService from "~/services/keystore.service";
import {
  NotificationKey,
  showNotificationError,
} from "~/utils/notifications-tools";
import uploadUtils from "~/utils/upload.utils";
import ILxcDownload from "~icons/lxc/download";
import ILxcInfo from "~icons/lxc/info";

interface Props {
  /** Files used in the input component. */
  files?: FileList | null;
  /** If set to true, only display the CSR download button. Else, display the CSR and certificate download buttons. */
  isCsr: boolean;
  /** Alias of the certificate or CSR. Used to download the targeted certificate or CSR. */
  alias?: string | null | undefined;
  /** If set to true, disable the input field. Default: false. */
  isInputDisabled?: boolean;
  /** If set, display the error message in the file input component. */
  uploadError?: Error | null;
  /** If set, display the file upload progress in the input component. */
  uploadProgress?: number | null;
}
const props = withDefaults(defineProps<Props>(), {
  files: undefined,
  alias: undefined,
  isInputDisabled: false,
  uploadError: undefined,
  uploadProgress: undefined,
});
const emit = defineEmits(["update:files", "blur", "input"]);

const { locale, t } = useI18n();
const { exportCertificateAsString } = useExportCertificateAsString();

const files = ref(props.files);

const onUpload = () => {
  emit("update:files", files.value);
};

/**
 * Send the file to the user or display an error notification if fetch failed.
 */
const onCsrDownload = async () => {
  if (props.alias !== undefined && props.alias !== null) {
    const response = await keystoreService.getCsr(props.alias);
    if (response.csr !== undefined) {
      exportCertificateAsString(
        response.csr,
        props.alias,
        CACertificateItemType.CSR,
      );
    } else {
      showNotificationError(t(NotificationKey.error));
    }
  }
};

/**
 * Send the file to the user or display an error notification if fetch failed.
 */
const onCertificateDownload = async () => {
  if (props.alias !== undefined && props.alias !== null) {
    const response = await keystoreService.getCertificate(props.alias);
    if (response.certificate !== undefined) {
      exportCertificateAsString(
        response.certificate,
        props.alias,
        CACertificateItemType.CERTIFICATE,
      );
    } else {
      showNotificationError(t(NotificationKey.error));
    }
  }
};
</script>

<template>
  <lxc-information v-show="isCsr" class="mb-4">
    <template #component>
      <lxc-info-sign>
        <ILxcInfo
          width="1.25rem"
          height="1.25rem"
          view-box="0 0 1.25rem 1.25rem"
        />
      </lxc-info-sign>
    </template>
    <template #body>
      {{ t("certificates.keystore.csrRequiresUploadCertificate") }}
    </template>
  </lxc-information>

  <div class="2xl:flex h-full">
    <div class="2xl:w-3/5 2xl:flex-grow-0 2xl:flex-shrink-0">
      <lxc-input
        v-model="files"
        type="file"
        accept=".pem,.crt,.cer"
        :disabled="isInputDisabled"
        :delete-tooltip="t('input.deleteFile')"
        :error="uploadError"
        :locale="locale"
        :multiple="false"
        :percentage="uploadProgress"
        :size-unit="uploadUtils.getUnitTranslations()"
        :container-classes="['h-24']"
        @update:model-value="onUpload"
        @blur="$emit('blur', $event)"
        @input="$emit('input', $event)"
      >
        <template #placeholder>
          <div class="px-2 2xl:flex 2xl:flex-col">
            <span class="text-primary-700 font-bold text-xs">{{
              t("input.clickToUpload")
            }}</span>
            <span class="pl-1 text-xs">{{
              t("input.orDragAndDropWithoutExtension")
            }}</span>
          </div>
        </template>
      </lxc-input>
    </div>
    <div class="2xl:w-2/5">
      <div class="flex flex-col h-full w-full justify-between 2xl:pl-4">
        <div class="max-2xl:py-2">
          <lxc-button
            type="secondary"
            html-type="button"
            :title="t('certificates.keystore.csr')"
            :icon="ILxcDownload"
            class="w-full h-11 text-xs"
            @click="onCsrDownload"
          >
            {{ t("certificates.keystore.csr") }}
          </lxc-button>
        </div>
        <div v-show="!isCsr">
          <lxc-button
            type="secondary"
            html-type="button"
            :title="t('certificates.keystore.certificate')"
            :icon="ILxcDownload"
            class="w-full h-11 text-xs"
            @click="onCertificateDownload"
          >
            {{ t("certificates.keystore.certificate") }}
          </lxc-button>
        </div>
      </div>
    </div>
  </div>
</template>
