import type { DtwinI, DtwinModelI, SectorI } from "@lxc/app-device-types";
import { useFetchPage } from "~/composables/useFetchPage";
import type { SearchMode } from "~/composables/useSearch";
import { useSearch } from "~/composables/useSearch";
import { useSort } from "~/composables/useSort";
import DtwinsService from "~/services/dtwins.service";
import { useConfigStore } from "~/stores/useConfigStore";
import { useUserSession } from "~/stores/useUserSession";
import type { FiltersSelection } from "~/types";
import {
  CUSTOM_IN_FLEET_RSQL_OPERATOR,
  CUSTOM_OUT_FLEET_RSQL_OPERATOR,
  Filters,
  FiltersType,
  OPERATOR_RSQL_ILIKE,
  OPERATOR_RSQL_IN,
  OPERATOR_RSQL_OUT,
} from "~/types";
import type LxcError from "~/utils/LxcError";
import { fetchAllApiListResult } from "~/utils/fetch.utils";
import filtersUtils from "~/utils/filters.utils";

export function useDtwins(
  searchMode?: SearchMode,
  useQueryParametersForPagination?: boolean,
) {
  const service = DtwinsService.getDtwins.bind(DtwinsService);
  const itemType = "dtwins";

  const { userSession } = useUserSession();
  const { defaultSectorCode } = useConfigStore();

  const appliedFilters: FiltersSelection = new Map([
    [
      Filters.NAME,
      {
        key: ["attributes.friendlyName"],
        operator: OPERATOR_RSQL_ILIKE,
        value: "",
      },
    ],
    [
      Filters.DTWIN_NAME_OR_SERIAL_NUMBER,
      {
        key: ["attributes.friendlyName", "attributes.serialNumber"],
        operator: OPERATOR_RSQL_ILIKE,
        value: "",
        valueFormatter: filtersUtils.formatRSQLValue,
      },
    ],
    [
      Filters.DTWINS_IN_LIFE_CYCLE_STATE,
      { key: "lifeCycleState", operator: OPERATOR_RSQL_IN, value: [] },
    ],
    [
      Filters.SECTORS,
      { key: "attributes.sectorId", operator: OPERATOR_RSQL_IN, value: [] },
    ],
    [
      Filters.SECTORS_EXCLUDED,
      { key: "attributes.sectorId", operator: OPERATOR_RSQL_OUT, value: [] },
    ],
    [
      Filters.DTWIN_MODEL_TYPE,
      { key: "deviceModelUid", operator: OPERATOR_RSQL_IN, value: [] },
    ],
    [
      Filters.DTWINS_IN_FLEET,
      { key: "", operator: CUSTOM_IN_FLEET_RSQL_OPERATOR, value: "" },
    ],
    [
      Filters.DTWINS_NOT_IN_FLEET,
      { key: "", operator: CUSTOM_OUT_FLEET_RSQL_OPERATOR, value: "" },
    ],
    [Filters.DTWIN_UID, { key: "uid", operator: OPERATOR_RSQL_IN, value: [] }],
    [
      Filters.DTWIN_IN_SOFTWARE_VERSION,
      {
        key: "features.firmware.reported.firmwareVersion",
        operator: OPERATOR_RSQL_IN,
        value: [],
      },
    ],
    [
      Filters.DTWIN_IN_HARDWARE_VERSION,
      {
        key: "attributes.hardwareVersion",
        operator: OPERATOR_RSQL_IN,
        value: [],
      },
    ],
  ]);

  const {
    searchParams,
    filters,
    setFilter,
    search,
    onSearch,
    searchOnSectors,
  } = useSearch(FiltersType.RSQL, appliedFilters, searchMode);
  const { sort, onSortChange } = useSort();
  const { isLoading, results, error, fetchData } = useFetchPage({
    service,
    searchParams,
    sort,
    useQueryParametersForPagination,
  });

  /**
   * Return the description of a dtwin
   * @param dtwin
   * @returns
   */
  function getDescription(dtwin?: DtwinI) {
    return dtwin?.attributes.friendlyName ?? "";
  }

  function canManageItems(sector: SectorI) {
    return (
      !sector.isRootSector &&
      userSession?.sectors
        .map((sector) => sector.code)
        .includes(`${defaultSectorCode}`)
    );
  }

  function getItemIds(items: Ref<any[]>): string[] {
    return items.value.map((item) => item.uid);
  }

  async function fetchAllDtwins(): Promise<DtwinI[] | LxcError> {
    return await fetchAllApiListResult((page: number, pageSize: number) =>
      DtwinsService.getDtwins(page, pageSize),
    );
  }

  return {
    itemType,
    isLoading,
    results,
    error,
    filters,
    fetchData,
    appliedFilters,
    setFilter,
    onSortChange,
    onSearch,
    search,
    searchParams,
    getDescription,
    canManageItems,
    getItemIds,
    fetchAllDtwins,
    searchOnSectors,
  };
}

export function useDtwin() {
  /**
   * Return the label of the model for a device twin
   * @param dtwin
   * @param models
   * @returns
   */
  const getDtwinTypeLabel = function (
    models: DtwinModelI[],
    dtwin?: DtwinI | null,
  ): string {
    const model = models.find(
      (model: DtwinModelI) => model.uid === dtwin?.deviceModelUid,
    );
    return model?.friendlyName || model?.name || "";
  };

  return {
    getDtwinTypeLabel,
  };
}
