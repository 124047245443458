import LxcApplicationDetail from "../components/applications/applicationDetail/LxcApplicationDetail.vue";
import LxcApplications from "../components/applications/applicationList/LxcApplications.vue";
import LxcCampaignDetail from "../components/campaigns/campaignDetail/LxcCampaignDetail.vue";
import LxcCampaigns from "../components/campaigns/campaignList/LxcCampaigns.vue";
import LxcCampaignReport from "../components/campaigns/campaignReport/LxcCampaignReport.vue";
import LxcDashboard from "../components/dashboard/LxcDashboard.vue";
import LxcDeviceDetail from "../components/devices/deviceDetail/LxcDeviceDetail.vue";
import LxcDevices from "../components/devices/deviceList/LxcDevices.vue";
import LxcDtwinsForm from "../components/dtwins/dtwinsForm/LxcDtwinsForm.vue";
import LxcDtwins from "../components/dtwins/dtwinsList/LxcDtwins.vue";
import LxcUnexpectedError from "../components/errors/LxcUnexpectedError.vue";
import LxcLogs from "../components/logs/logList/LxcLogs.vue";
import LxcApplicationMgt from "../components/parameters/applicationMgt/LxcApplicationMgt.vue";
import LxcCertificateAlertReporting from "../components/parameters/certificate-alert-reporting/LxcCertificateAlertReporting.vue";
import LxcCertificateManagement from "../components/parameters/certificate-management/LxcCertificateManagement.vue";
import LxcCertificates from "../components/parameters/certificates/LxcCertificates.vue";
import LxcFirmwares from "../components/parameters/firmwares/LxcFirmwares.vue";
import LxcSectorDetail from "../components/sectors/sectorDetail/LxcSectorDetail.vue";
import LxcSectors from "../components/sectors/sectorList/LxcSectors.vue";
import LxcUserForm from "../components/userManagement/user/userForm/LxcUserForm.vue";
import LxcUserGroupDetail from "../components/userManagement/userGroup/userGroupDetail/LxcUserGroupDetail.vue";
import { setupLayouts } from "virtual:generated-layouts";
import type {
  RouteLocation,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import { createRouter, createWebHistory } from "vue-router";
import type { AbilitiesEvaluation } from "vue-simple-acl/types/acl";
import LxcAbout from "~/components/about/LxcAbout.vue";
import LxcCampaignOperationMgrDetail from "~/components/campaigns/campaignDetail/campaignOperationManagerDetail/LxcCampaignOperationMgrDetail.vue";
import LxcDevicesMap from "~/components/cartography/devicesMap/LxcDevicesMap.vue";
import LxcConnectMobileApp from "~/components/connectMobileApp/LxcConnectMobileApp.vue";
import LxcNotFoundVue from "~/components/errors/LxcNotFound.vue";
import LxcUnauthorized from "~/components/errors/LxcUnauthorized.vue";
import LxcFleetDetails from "~/components/fleets/fleetDetails/LxcFleetDetails.vue";
import LxcFleets from "~/components/fleets/fleetsList/LxcFleets.vue";
import LxcLicense from "~/components/parameters/license/LxcLicense.vue";
import LxcPkiConnector from "~/components/parameters/pki-connector/LxcPkiConnector.vue";
import { BreadcrumbConfig } from "~/components/shared/breadcrumb/breadcrumb.config";
import LxcUserAccount from "~/components/userAccount/LxcUserAccount.vue";
import LxcUserManagement from "~/components/userManagement/LxcUserManagement.vue";
import { useAppInterface } from "~/composables/useAppInterface";
import { isFeatureEnabled } from "~/composables/useFeature";
import { PATHS } from "~/constants/paths";
import { useConfirmLeavePageStore } from "~/stores/useConfirmLeavePageStore";
import { useSectorStore } from "~/stores/useSectorStore";
import { useUserSession } from "~/stores/useUserSession";
import { ACL_ROLES } from "~/types";
import { APP_FEATURES } from "~/types/appFeatures";

const { canViewThirdPartyApps } = useAppInterface();

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    redirect: () => {
      return PATHS.DASHBOARD;
    },
  },
  {
    path: PATHS.DASHBOARD,
    component: LxcDashboard,
    meta: {
      auth: true,
      breadcrumb: BreadcrumbConfig.DASHBOARD,
    },
  },
  {
    path: PATHS.ERROR,
    component: LxcUnexpectedError,
    meta: {
      layout: "preLogin",
    },
  },
  {
    path: PATHS.APPLICATIONS,
    component: LxcApplications,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.DISPLAY_APPLICATIONS),
      auth: true,
      breadcrumb: BreadcrumbConfig.APPLICATION,
    },
  },
  {
    path: `${PATHS.APPLICATIONS}/:appId`,
    component: LxcApplicationDetail,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.DISPLAY_APPLICATIONS),
      auth: true,
      breadcrumb: BreadcrumbConfig.APPLICATION,
    },
  },
  {
    path: PATHS.DEVICES_DVTM_ESOFT,
    component: LxcDevices,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.DISPLAY_DEVICES),
      auth: true,
      breadcrumb: BreadcrumbConfig.DEVICE,
    },
  },
  {
    path: `${PATHS.DEVICES_DVTM_ESOFT}/:id`,
    component: LxcDeviceDetail,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.DISPLAY_DEVICES),
      auth: true,
      breadcrumb: BreadcrumbConfig.DEVICE,
    },
  },
  {
    path: PATHS.DTWINS,
    component: LxcDtwins,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(APP_FEATURES.DTWINS) &&
        canWithRedirection(can, ACL_ROLES.DISPLAY_DEVICES),
      auth: true,
      breadcrumb: BreadcrumbConfig.DTWIN,
    },
  },
  {
    path: `${PATHS.DTWINS}/:id`,
    component: LxcDtwinsForm,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(APP_FEATURES.DTWINS) &&
        canWithRedirection(can, ACL_ROLES.DISPLAY_DEVICES),
      auth: true,
      breadcrumb: BreadcrumbConfig.DTWIN,
    },
    // The dtwin page does not exist by itself, it is a wrapper for the dtwin tabs. The navigation to this page (via the
    // left panel or the URL) must redirect to the description tab.
    redirect: (to) => {
      return `${PATHS.DTWINS}/${to.params.id}/${PATHS.DTWINS_DESCRIPTION_SUBPATH}`;
    },
    children: [],
  },
  {
    path: `${PATHS.DTWINS_DESCRIPTION}`,
    component: LxcDtwinsForm,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(APP_FEATURES.DTWINS) &&
        canWithRedirection(can, ACL_ROLES.DISPLAY_DEVICES),
      auth: true,
      breadcrumb: BreadcrumbConfig.DTWIN,
    },
  },
  {
    path: `${PATHS.DTWINS_FIRMWARE}`,
    component: LxcDtwinsForm,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(APP_FEATURES.DTWINS) &&
        canWithRedirection(can, ACL_ROLES.DISPLAY_DEVICES),
      auth: true,
      breadcrumb: BreadcrumbConfig.DTWIN,
    },
  },
  {
    path: `${PATHS.DTWINS_CONFIGURATION}`,
    component: LxcDtwinsForm,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(APP_FEATURES.DTWINS) &&
        canWithRedirection(can, ACL_ROLES.DISPLAY_DEVICES),
      auth: true,
      breadcrumb: BreadcrumbConfig.DTWIN,
    },
  },
  {
    path: `${PATHS.DTWINS_DATA_VISUALISATION}`,
    component: LxcDtwinsForm,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(APP_FEATURES.DTWINS) &&
        canWithRedirection(can, ACL_ROLES.DISPLAY_DEVICES),
      auth: true,
      breadcrumb: BreadcrumbConfig.DTWIN,
    },
  },
  {
    path: PATHS.CAMPAIGNS,
    component: LxcCampaigns,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.DISPLAY_CAMPAIGNS),
      auth: true,
      breadcrumb: BreadcrumbConfig.CAMPAIGN,
    },
    redirect: () => {
      if (isFeatureEnabled(APP_FEATURES.CAMPAIGN_DTWINS)) {
        return PATHS.CAMPAIGNS_DEVICES;
      } else {
        return PATHS.CAMPAIGNS_S4_LOCAL_STATION;
      }
    },
    children: [],
  },
  {
    path: PATHS.CAMPAIGNS_DEVICES,
    component: LxcCampaigns,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.DISPLAY_CAMPAIGNS),
      auth: true,
      breadcrumb: BreadcrumbConfig.CAMPAIGN,
    },
  },
  {
    path: PATHS.CAMPAIGNS_S4_LOCAL_STATION,
    component: LxcCampaigns,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(APP_FEATURES.CAMPAIGN_DTWINS) &&
        canWithRedirection(can, ACL_ROLES.DISPLAY_CAMPAIGNS),
      auth: true,
      breadcrumb: BreadcrumbConfig.CAMPAIGN,
    },
  },
  {
    path: `${PATHS.CAMPAIGNS}/:id/${PATHS.DETAILS_SUBPATH}`,
    component: LxcCampaignDetail,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.DISPLAY_CAMPAIGNS),
      auth: true,
      breadcrumb: BreadcrumbConfig.CAMPAIGN,
    },
  },
  {
    path: `${PATHS.CAMPAIGNS_REPORTS}/:id`,
    component: LxcCampaignReport,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.NO_CONTROL),
      auth: true,
      breadcrumb: BreadcrumbConfig.CAMPAIGN,
    },
  },
  {
    path: `${PATHS.CAMPAIGNS}/:uid`,
    component: LxcCampaignOperationMgrDetail,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.DISPLAY_CAMPAIGNS),
      auth: true,
      breadcrumb: BreadcrumbConfig.CAMPAIGN,
    },
  },
  {
    path: PATHS.LOGS,
    component: LxcLogs,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(APP_FEATURES.LOGS) &&
        canWithRedirection(can, [
          ACL_ROLES.CYBER_LOGS_VIEW,
          ACL_ROLES.DEVICE_LOGS_VIEW,
          ACL_ROLES.DEVICE_FLEET_LOGS_VIEW,
          ACL_ROLES.SYSTEM_LOGS_VIEW,
        ]),
      auth: true,
      breadcrumb: BreadcrumbConfig.LOGS,
    },
  },
  {
    path: PATHS.USER_MANAGEMENT_GROUPS,
    component: LxcUserManagement,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.DISPLAY_USERS),
      auth: true,
      breadcrumb: BreadcrumbConfig.GROUP,
    },
  },
  {
    path: `${PATHS.USER_MANAGEMENT_GROUPS}/:code`,
    component: LxcUserGroupDetail,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.DISPLAY_USERS),
      auth: true,
      breadcrumb: BreadcrumbConfig.GROUP,
    },
  },
  {
    path: PATHS.PARAMETERS_CERTIFICATE_ALERT_REPORTING,
    component: LxcCertificateAlertReporting,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.CONFIGURE_NOTIFICATION),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: PATHS.PARAMETERS_DEVICE_CONFIGURATION,
    component: LxcCertificateManagement,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.MANAGE_CERTIFICATES),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: PATHS.PARAMETERS_PKI_CONNECTOR,
    component: LxcPkiConnector,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.CONFIGURE_PKI),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: PATHS.PARAMETERS_CERTIFICATES,
    component: LxcCertificates,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.DVT_CACRT_VIEW),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
    // The certificate page does not exist by itself, it is a wrapper for the keystore and the truststore pages
    // accessible via the usage of tabs. The navigation to this page (via the left panel or the URL) must redirect
    // to the keystore tab.
    redirect: PATHS.PARAMETERS_CERTIFICATES_KEYSTORE,
    children: [],
  },
  {
    path: PATHS.PARAMETERS_CERTIFICATES_KEYSTORE,
    component: LxcCertificates,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.DVT_CACRT_VIEW),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: `${PATHS.PARAMETERS_CERTIFICATES_KEYSTORE}/:uuid`,
    component: LxcCertificates,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.DVT_CACRT_VIEW),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: `${PATHS.PARAMETERS_CERTIFICATES_TRUSTSTORE}`,
    component: LxcCertificates,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.DVT_CACRT_VIEW),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: `${PATHS.PARAMETERS_CERTIFICATES_TRUSTSTORE}/:uuid`,
    component: LxcCertificates,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.DVT_CACRT_VIEW),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: PATHS.PARAMETERS_FIRMWARES,
    component: LxcFirmwares,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(APP_FEATURES.FOTA) &&
        canWithRedirection(can, ACL_ROLES.DISPLAY_FIRMWARES),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: `${PATHS.PARAMETERS_FIRMWARES}/:uuid`,
    component: LxcFirmwares,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(APP_FEATURES.FOTA) &&
        canWithRedirection(can, ACL_ROLES.MANAGE_FIRMWARES),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: PATHS.USER_MANAGEMENT_PROFILES,
    component: LxcUserManagement,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.DISPLAY_USERS),
      auth: true,
      breadcrumb: BreadcrumbConfig.PROFILE,
    },
  },
  {
    path: `${PATHS.USER_MANAGEMENT_PROFILES}/:profileCode`,
    component: LxcUserManagement,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.DISPLAY_USERS),
      auth: true,
      breadcrumb: BreadcrumbConfig.PROFILE,
    },
  },
  {
    path: PATHS.USER_MANAGEMENT,
    component: LxcUserManagement,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.DISPLAY_USERS),
      auth: true,
      breadcrumb: BreadcrumbConfig.USER_MANAGEMENT,
    },
    // The user management page does not exist by itself, it is a wrapper for the user, group and profile pages
    // accessible via the usage of tabs. The navigation to this page (via the left panel or the URL) must redirect
    // to the user tab.
    redirect: PATHS.USER_MANAGEMENT_USERS,
    children: [],
  },
  {
    path: PATHS.USER_MANAGEMENT_USERS,
    component: LxcUserManagement,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.DISPLAY_USERS),
      auth: true,
      breadcrumb: BreadcrumbConfig.USER_MANAGEMENT,
    },
  },
  {
    path: `${PATHS.USER_MANAGEMENT_USERS}/:id`,
    component: LxcUserForm,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.DISPLAY_USERS),
      auth: true,
      breadcrumb: BreadcrumbConfig.USER_MANAGEMENT,
    },
  },
  {
    path: PATHS.SECTORS,
    component: LxcSectors,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.MANAGE_SECTORS),
      auth: true,
      breadcrumb: BreadcrumbConfig.SECTOR,
    },
  },
  {
    path: `${PATHS.SECTORS}/:code`,
    component: LxcSectorDetail,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.MANAGE_SECTORS),
      auth: true,
      breadcrumb: BreadcrumbConfig.SECTOR,
    },
    redirect: (to: RouteLocation) => {
      return `${PATHS.SECTORS}/${to.params.code}/${PATHS.DEVICES_DVTM_ESOFT_SUBPATH}`;
    },
    children: [],
  },
  {
    path: PATHS.SECTORS_DEVICES,
    component: LxcSectorDetail,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.DISPLAY_DEVICES),
      auth: true,
      breadcrumb: BreadcrumbConfig.SECTOR,
    },
  },
  {
    path: PATHS.SECTORS_DTWINS,
    component: LxcSectorDetail,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(APP_FEATURES.DTWINS) &&
        canWithRedirection(can, ACL_ROLES.DISPLAY_DEVICES),
      auth: true,
      breadcrumb: BreadcrumbConfig.SECTOR,
    },
  },
  {
    path: PATHS.SECTORS_APPLICATIONS,
    component: LxcSectorDetail,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.DISPLAY_APPLICATIONS),
      auth: true,
      breadcrumb: BreadcrumbConfig.SECTOR,
    },
  },
  {
    path: PATHS.SECTORS_USERS,
    component: LxcSectorDetail,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.DISPLAY_USERS),
      auth: true,
      breadcrumb: BreadcrumbConfig.SECTOR,
    },
  },
  {
    path: PATHS.SECTORS_GROUPS,
    component: LxcSectorDetail,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.DISPLAY_USERS),
      auth: true,
      breadcrumb: BreadcrumbConfig.SECTOR,
    },
  },
  {
    path: PATHS.PARAMETERS_APPLICATIONS,
    component: LxcApplicationMgt,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(APP_FEATURES.APPLICATION_MANAGEMENT) &&
        canWithRedirection(can, [
          ACL_ROLES.DVTM_APPM_MOBILEAPP_VIEW,
          ACL_ROLES.DVTM_APPM_THIRDPARTY_VIEW,
        ]),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
    // The application page does not exist by itself, it is a wrapper for the mobile application and the third-party
    // application pages accessible via the usage of tabs. The navigation to this page (via the left panel or the URL)
    // must redirect to the keystore tab. On this page, one tab can be disabled. If the two are disabled, the router
    // will not allow the redirection to the page and the following code will not be executed.
    redirect: canViewThirdPartyApps()
      ? PATHS.PARAMETERS_APPLICATIONS_THIRD_PART_APP
      : PATHS.PARAMETERS_APPLICATIONS_MOBILE_APP,
    children: [],
  },
  {
    path: PATHS.PARAMETERS_APPLICATIONS_CREATE,
    component: LxcApplicationMgt,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(APP_FEATURES.APPLICATION_MANAGEMENT) &&
        canWithRedirection(can, ACL_ROLES.DVTM_APPM_MOBILEAPP_ADM),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: PATHS.PARAMETERS_APPLICATIONS_THIRD_PART_APP,
    component: LxcApplicationMgt,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(APP_FEATURES.APPLICATION_MANAGEMENT) &&
        canWithRedirection(can, ACL_ROLES.DVTM_APPM_THIRDPARTY_VIEW),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: `${PATHS.PARAMETERS_APPLICATIONS_THIRD_PART_APP}/:clientId`,
    component: LxcApplicationMgt,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(APP_FEATURES.APPLICATION_MANAGEMENT) &&
        canWithRedirection(can, ACL_ROLES.DVTM_APPM_THIRDPARTY_VIEW),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: PATHS.PARAMETERS_APPLICATIONS_MOBILE_APP,
    component: LxcApplicationMgt,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(APP_FEATURES.APPLICATION_MANAGEMENT) &&
        canWithRedirection(can, ACL_ROLES.DVTM_APPM_MOBILEAPP_VIEW),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: `${PATHS.PARAMETERS_APPLICATIONS_MOBILE_APP}/:clientId`,
    component: LxcApplicationMgt,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(APP_FEATURES.APPLICATION_MANAGEMENT) &&
        canWithRedirection(can, ACL_ROLES.DVTM_APPM_MOBILEAPP_VIEW),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: PATHS.ABOUT,
    component: LxcAbout,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.NO_CONTROL),
      auth: true,
      breadcrumb: BreadcrumbConfig.ABOUT,
    },
  },
  {
    path: PATHS.PARAMETERS_LICENSE,
    component: LxcLicense,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.NO_CONTROL),
      auth: true,
      breadcrumb: BreadcrumbConfig.PARAMETERS,
    },
  },
  {
    path: PATHS.USER_ACCOUNT,
    component: LxcUserAccount,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.NO_CONTROL),
      auth: true,
      breadcrumb: BreadcrumbConfig.USER_ACCOUNT,
    },
    // The user management page does not exist by itself, it is a wrapper for the profile, language and certificate pages
    // accessible via the usage of tabs. The navigation to this page (via the left panel or the URL) must redirect
    // to the user tab.
    redirect: PATHS.USER_ACCOUNT_PROFILE,
    children: [],
  },
  {
    path: PATHS.USER_ACCOUNT_PROFILE,
    component: LxcUserAccount,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.NO_CONTROL),
      auth: true,
      breadcrumb: BreadcrumbConfig.USER_ACCOUNT,
    },
  },
  {
    path: PATHS.USER_ACCOUNT_LANGUAGE,
    component: LxcUserAccount,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.NO_CONTROL),
      auth: true,
      breadcrumb: BreadcrumbConfig.USER_ACCOUNT,
    },
  },
  {
    path: PATHS.USER_ACCOUNT_CERTIFICATE,
    component: LxcUserAccount,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.USR_CERTIFICATES),
      auth: true,
      breadcrumb: BreadcrumbConfig.USER_ACCOUNT,
    },
  },
  {
    path: PATHS.NOT_FOUND,
    component: LxcNotFoundVue,
    meta: {
      auth: true,
    },
  },
  {
    path: PATHS.UNAUTHORIZED,
    component: LxcUnauthorized,
    meta: {
      auth: true,
    },
  },
  {
    path: PATHS.CONNECT_MY_MOBILE_APP,
    component: LxcConnectMobileApp,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACL_ROLES.DVTM_APPM_MOBILEAPP_VIEW),
      auth: true,
    },
  },
  {
    path: PATHS.FLEETS,
    component: LxcFleets,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(APP_FEATURES.DTWINS) &&
        canWithRedirection(can, ACL_ROLES.DISPLAY_DEVICES),
      auth: true,
      breadcrumb: BreadcrumbConfig.FLEETS,
    },
  },
  {
    path: `${PATHS.FLEETS}/:uid`,
    component: LxcFleetDetails,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(APP_FEATURES.DTWINS) &&
        canWithRedirection(can, ACL_ROLES.DISPLAY_DEVICES),
      auth: true,
      breadcrumb: BreadcrumbConfig.FLEETS,
    },
  },
  {
    path: PATHS.MAP,
    component: LxcDevicesMap,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(APP_FEATURES.DTWINS) &&
        isFeatureEnabled(APP_FEATURES.CARTOGRAPHY) &&
        canWithRedirection(can, ACL_ROLES.DISPLAY_DEVICES),
      auth: true,
    },
  },
];

export const router = createRouter({
  history: createWebHistory(import.meta.env.LXC_FI_DEVICE_BASENAME),
  routes: setupLayouts(routes),
});

async function canWithRedirection(
  can: AbilitiesEvaluation,
  permission: ACL_ROLES | ACL_ROLES[],
) {
  const perm = can(permission);
  if (!perm) {
    await router.push({ path: PATHS.UNAUTHORIZED });
  }
  return perm;
}

/**
 * Manage route checks
 * Set current user if needed and clear code/session_state query params
 * Return false if not auth after current user should have been set to avoid showing part of application before auto redirecting to keycloak login
 */
router.beforeEach(
  async (
    to: RouteLocationNormalized,
  ): Promise<RouteLocationNormalized | boolean | void> => {
    const { setCurrentUser, isAuthenticated } = useUserSession();

    if (to.meta.auth && !isAuthenticated) {
      await setCurrentUser(to);

      delete to.query.code;
      delete to.query.session_state;
      // set isAuthenticated again to fix infinite loading time
      const { isAuthenticated } = useUserSession();

      if (isAuthenticated) {
        const sectorsStore = useSectorStore();
        await sectorsStore.init(to.query);

        return to;
      } else {
        return false;
      }
    }

    // case checking display confirm
    const confirmLeavePageStore = useConfirmLeavePageStore();

    // need confirm before leaving the page
    if (confirmLeavePageStore.isActivated()) {
      // let redirection pass if it is done after confirm
      if (confirmLeavePageStore.confirmed()) {
        confirmLeavePageStore.reset();
      }
      // otherwise display the leaving page confirm message
      else {
        // provide the destination route in order to redirect after confirm
        confirmLeavePageStore.show(to.fullPath);
        return false; // stop redirection for confirm
      }
    }
  },
);
