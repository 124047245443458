<script setup lang="ts">
import { SortMode } from "@lxc/app-device-common";
import type LxcTable from "@lxc/app-device-common/src/components/LxcTable.vue";
import type { TruststoreCertificateI } from "@lxc/app-device-types";
import { onMounted, watch } from "vue";
import type { LocationQuery } from "vue-router";
import LxcTagForList from "~/components/shared/LxcTagForList.vue";
import { useCertificatesACLRoles } from "~/composables/useCertificates";
import { SearchMode } from "~/composables/useSearch";
import { useTabsSideCanvasNavigation } from "~/composables/useTabsSideCanvasNavigation";
import { useTruststore } from "~/composables/useTruststore";
import { DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE } from "~/constants/constants";
import { PATHS } from "~/constants/paths";
import { Filters } from "~/types";
import type LxcError from "~/utils/LxcError";
import { formatIsoDate } from "~/utils/date-tools";

const props = defineProps<{
  triggerSearch?: boolean;
  isTagsLoading: boolean;
  selectedCertificate?: TruststoreCertificateI | null;
  tags?: string[] | null;
  tagsError?: LxcError | null;
  interfaceFormShown: boolean;
}>();
const emit = defineEmits([
  "update:selectedCertificate",
  "openCertificateDetail",
  "update:interfaceFormShown",
]);

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const {
  isLoading,
  results,
  filters,
  error,
  fetchData,
  setFilter,
  onSortChange,
  onSearch,
  search,
} = useTruststore(SearchMode.URL_SEARCH);

const { canManageCertificates, canViewCertificates } =
  useCertificatesACLRoles();

const formSideCanvasShown = computed({
  get: () => {
    return props.interfaceFormShown;
  },
  set: (shown: boolean) => {
    emit("update:interfaceFormShown", shown);
  },
});

const searchQuery = computed({
  get() {
    return (filters.get(Filters.TRUSTSTORE_ALIAS) ?? "") as string;
  },
  set(searchQuery) {
    setFilter(Filters.TRUSTSTORE_ALIAS, searchQuery);
  },
});

const alias = ref<string | undefined | null>();
const tableRef = ref<InstanceType<typeof LxcTable>>();
const currentSelectedCertificate: Ref<
  TruststoreCertificateI | undefined | null
> = ref();
const canEditTruststoreCertificate: ComputedRef<boolean> = computed(
  (): boolean => canManageCertificates() && !props.isTagsLoading,
);

/**
 * Retrieve selected certificates
 */

const findCertificate = (
  list: TruststoreCertificateI[],
  paramCertificate?: TruststoreCertificateI | null,
) => {
  return list.find(
    (currentCertificate: TruststoreCertificateI) =>
      currentCertificate.alias === paramCertificate?.alias,
  );
};

const setSelection = () => {
  if (tableRef.value?.data) {
    tableRef.value.setCurrentRow(
      findCertificate(tableRef.value.data, props.selectedCertificate),
    );
  }
};

watch(() => props.selectedCertificate, setSelection);

/**
 * Update the selected certificate or redirect to certificate details page
 */
async function onSelectCertificate(certificate: TruststoreCertificateI) {
  alias.value = certificate.alias;

  if (certificate?.alias && canViewCertificates()) {
    const targetUrl: Record<string, string | string[] | LocationQuery> = {
      path: `${PATHS.PARAMETERS_CERTIFICATES_TRUSTSTORE}/${certificate?.alias}`,
      query: route.query,
    };
    formSideCanvasShown.value = true;
    await router.push(targetUrl);
  }
}

const onCloseCertificateDetail = () => {
  alias.value = null;
  formSideCanvasShown.value = false;
  router.push({
    path: PATHS.PARAMETERS_CERTIFICATES_TRUSTSTORE,
    query: route.query,
  });
};

const initDisplayFromRoute = () => {
  if (route.path.startsWith(PATHS.PARAMETERS_CERTIFICATES_TRUSTSTORE)) {
    alias.value = route?.params?.uuid as string | undefined;

    if (alias.value) {
      formSideCanvasShown.value = true;
    }
  }
};

/**
 * Retrieve certificates and set selected certificate if needed
 * @param page
 * @param pageSize
 */
async function loadData(
  page: number = DEFAULT_FIRST_PAGE,
  pageSize: number = DEFAULT_PAGE_SIZE,
) {
  await fetchData(page, pageSize);
  setTimeout(setSelection, 0);
}

function reloadWithContext() {
  loadData(results.value?.context?.page, results.value?.context?.pageSize);
}

watch(() => props.triggerSearch, search);
watch(
  () => currentSelectedCertificate.value,
  () => {
    emit("update:selectedCertificate", currentSelectedCertificate.value);
  },
);
watch(
  () => props.selectedCertificate,
  () => {
    currentSelectedCertificate.value = props.selectedCertificate;
  },
);

onSearch(async () => {
  await loadData();
  initDisplayFromRoute();
});
</script>

<template>
  <lxc-search-bar
    v-model:search-query="searchQuery"
    :search-placeholder="t('certificates.keystore.filters.searchByAlias')"
    class="grow shrink basis-0"
    @clear="search"
    @search="search"
  />
  <lxc-certificates-filters
    :filters="filters"
    :tags="tags"
    :is-tags-loading="isTagsLoading"
    :tag-filter-name="Filters.TRUSTSTORE_TAGS"
    :tags-error="tagsError"
    @change="setFilter"
    @enter="search"
  />
  <lxc-table
    ref="tableRef"
    min-width="63rem"
    :data="results?.data"
    :context="results?.context"
    :is-loading="isLoading"
    :error="error?.toError()"
    clickable
    :empty-text="t('certificates.noCertificate')"
    @change-page-and-page-size="loadData"
    @row-click="onSelectCertificate"
    @sort-change="onSortChange"
  >
    <lxc-table-column
      prop="alias"
      :label="t('certificates.alias')"
      :sort-mode="SortMode.CUSTOM"
      min-width="15rem"
    />
    <lxc-table-column
      prop="tags"
      :label="t('certificates.tag')"
      class="!py-0"
      width="20rem"
    >
      <template #default="scope">
        <LxcTagForList :tags="scope.row.tags" :max="3" />
      </template>
    </lxc-table-column>
    <lxc-table-column
      prop="expirationDate"
      :label="t('certificates.expiration.timestamp.label')"
      width="16rem"
    >
      <template #default="scope">
        {{ formatIsoDate(scope.row.expirationDate, t("dateFormat.datetime")) }}
      </template>
    </lxc-table-column>
    <lxc-table-column class="!py-0" width="12rem">
      <template #default="scope">
        <div class="flex gap-2">
          <lxc-truststore-list-download-action :certificate="scope.row" />
          <lxc-truststore-list-delete-action
            :certificate="scope.row"
            @change="reloadWithContext"
          />
        </div>
      </template>
    </lxc-table-column>
  </lxc-table>

  <lxc-truststore-certificate-detail
    v-model:side-canvas-shown="formSideCanvasShown"
    :alias="alias"
    :disabled="!canEditTruststoreCertificate"
    :is-tags-loading="isTagsLoading"
    :tags-error="tagsError"
    :tags="tags"
    @close="onCloseCertificateDetail"
    @save="loadData"
  />
</template>

<style lang="scss" scoped>
:deep(table) {
  tbody {
    tr {
      &:hover {
        button {
          visibility: visible;
        }
      }
    }
  }
}
</style>
