import type { CartographySettingsI } from "@lxc/app-device-types/dist/interfaces/cartography.interface";
import cartographyService from "~/services/cartography.service";

export function useCartography() {
  const cartographySettings: Ref<CartographySettingsI | undefined> = ref();

  async function getCartographySettings() {
    cartographySettings.value =
      await cartographyService.getCartographySettings();
  }

  return {
    cartographySettings,
    getCartographySettings,
  };
}
