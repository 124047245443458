<script lang="ts" setup>
import type { FirmwareI } from "@lxc/app-device-types";
import { useDeleteFirmware } from "~/composables/useFirmware";
import LxcError from "~/utils/LxcError";
import {
  NotificationKey,
  showNotificationSuccess,
} from "~/utils/notifications-tools";
import ILxcLightTrash2 from "~icons/lxc-light/trash-2";

const props = defineProps<{
  firmware: FirmwareI;
}>();
const emit = defineEmits(["change"]);

const { t } = useI18n();
const {
  canDeleteFirmware,
  checkFirmwareStateBeforeDeletion,
  deleteError,
  deleteFirmware,
  deleteConfirmDialogVisible,
  isDeleteLoading,
  isEmbedded,
  toggleDeleteConfirmVisible,
} = useDeleteFirmware();

const onDeleteFirmwareAndPropagateChange = async () => {
  await deleteFirmware(props.firmware);

  if (!LxcError.check(deleteError.value)) {
    showNotificationSuccess(t(NotificationKey.removeSuccess));
    emit("change");
  } else {
    (deleteError.value as LxcError).notify(NotificationKey.saveError);
  }
};

const onCheckFirmwareStateBeforeDeletion = async (event: Event) => {
  await checkFirmwareStateBeforeDeletion(event, props.firmware.uuid);

  if (LxcError.check(deleteError.value)) {
    (deleteError.value as LxcError).notify(NotificationKey.error);
  }
};
</script>

<template>
  <lxc-button
    v-if="canDeleteFirmware"
    html-type="button"
    type="borderless"
    :disabled="isDeleteLoading"
    :icon="!isDeleteLoading ? ILxcLightTrash2 : undefined"
    :title="t('firmware.actions.delete.label')"
    class="invisible"
    @click.prevent="onCheckFirmwareStateBeforeDeletion"
  >
    <lxc-loader v-if="isDeleteLoading" />
  </lxc-button>

  <lxc-confirm-modal
    :is-dialog-visible="deleteConfirmDialogVisible"
    :title="t('firmware.actions.delete.label')"
    :description="
      isEmbedded
        ? t('firmware.actions.delete.descriptionEmbedded', {
            name: firmware.name,
          })
        : t('firmware.actions.delete.description', { name: firmware.name })
    "
    :icon="ILxcLightTrash2"
    icon-color-theme="error"
    color-theme="danger"
    @cancel="toggleDeleteConfirmVisible"
    @confirm="onDeleteFirmwareAndPropagateChange"
  />
</template>
<style lang="scss" scoped>
button {
  :deep(svg) {
    height: 20px;
    width: 20px;
  }
}
</style>
