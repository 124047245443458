import { AppDeviceState } from "@lxc/app-device-types";
import type { FilterOptions } from "~/types";

export interface ApplicationTypeDeclinations {
  applicationDeclinations: string[];
}
export type ApplicationTypeDeclinationMap = Record<
  string,
  ApplicationTypeDeclinations
>;

export const appModelTypeOptions: FilterOptions = {
  label: "filters.type",
  options: [
    {
      value: "S4-View",
      label: "S4-View",
    },
    {
      value: "S4G-Tools",
      label: "S4-Tools",
    },
    {
      value: "S4-Manager",
      label: "S4-Manager",
    },
    {
      value: "SG4000",
      label: "SG4000",
    },
  ],
};

export const appModelDeclinationOptions: FilterOptions = {
  label: "filters.model",
  options: [
    {
      value: "S4GT",
      label: "S4GT",
    },
    {
      value: "S4TH",
      label: "S4TH",
    },
    {
      value: "S4W",
      label: "S4W",
    },
  ],
};

export const appTypeDeclinationMap: ApplicationTypeDeclinationMap = {
  "S4-Manager": {
    applicationDeclinations: [],
  },
  "S4G-Tools": {
    applicationDeclinations: ["S4GT", "S4TH", "S4W"],
  },
  SG4000: {
    applicationDeclinations: [],
  },
  "S4-View": {
    applicationDeclinations: [],
  },
};

export const appStateOptionNew = AppDeviceState.REGISTERED;

export const appStateOptions: FilterOptions = {
  label: "filters.state",
  options: [
    {
      value: AppDeviceState.ACTIVATED,
      label: "application.states.activated",
    },
    {
      value: AppDeviceState.DEACTIVATED,
      label: "application.states.deactivated",
    },
    {
      value: appStateOptionNew,
      label: "application.states.registered",
    },
  ],
};

export const appConnectivityOptions: FilterOptions = {
  label: "filters.connectivity",
  options: [
    {
      value: "Active",
      label: "filters.connectivityState.active",
    },
    {
      value: "Inactive",
      label: "filters.connectivityState.inactive",
    },
  ],
};
