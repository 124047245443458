<script setup lang="ts">
import type { CustomElementEvent } from "@lxc/app-device-common";
import { useConfirmLeavePageStore } from "~/stores/useConfirmLeavePageStore";
import { useUserSession } from "~/stores/useUserSession";

defineProps<{
  dtwinUid?: string;
}>();

const { userSession } = useUserSession();

const proxyUrl = `${import.meta.env.LXC_AS_DEVICE_URL}proxy`;

const customElementError = async (error: CustomEvent) => {
  if (error.detail[0].response.status === 401) {
    const { logout } = await import("~/utils/auth.utils");
    await logout();
  }
};

/**
 * Listen to the Web Component events
 */
const onEvent = (event: CustomEvent) => {
  for (const detail of event.detail) {
    const customElementEvent = detail as CustomElementEvent;

    // case of event "CHANGES": the Web Component has (no) changes on its context => activate/deactivate the confirm leave page message
    if (customElementEvent.type === "CHANGES") {
      if (customElementEvent.args && customElementEvent.args.length > 0) {
        useConfirmLeavePageStore().activate(customElementEvent.args[0]);
      }
    }
  }
};
</script>

<template>
  <v-front-page
    :device-id="dtwinUid"
    :proxy-url="proxyUrl"
    :lang="userSession?.language"
    @error="customElementError"
    @event="onEvent"
  />
</template>
