<script lang="ts" setup>
import type { TabNavigation } from "~/components/shared/tabs/LxcTabsWithNavigation.vue";
import { PATHS } from "~/constants/paths";
import ILxcPlus from "~icons/lxc/plus";

const { t } = useI18n();
const router = useRouter();

const profileInterfaceShown = ref(false);

async function onCreateUser() {
  await router.push(PATHS.USER_MANAGEMENT_USERS_NEW);
}

async function onCreateUserGroup() {
  await router.push(PATHS.USER_MANAGEMENT_GROUPS_NEW);
}

function onCreateProfile() {
  onUpdateInterfaceFormShown(true);
}

const onUpdateInterfaceFormShown = (show: boolean) => {
  profileInterfaceShown.value = show;
};

//
// Navigation section. Logic / configuration responsible for the navigation.
//
const usersTabIndex = 0;
const groupsTabIndex = 1;
const profilesTabIndex = 2;

const tabNavigations: TabNavigation[] = [
  {
    index: usersTabIndex,
    redirectPath: PATHS.USER_MANAGEMENT_USERS,
    labelTranslationKey: "tab.users.label",
  },
  {
    index: groupsTabIndex,
    redirectPath: PATHS.USER_MANAGEMENT_GROUPS,
    labelTranslationKey: "tab.userGroups.label",
  },
  {
    index: profilesTabIndex,
    redirectPath: PATHS.USER_MANAGEMENT_PROFILES,
    labelTranslationKey: "tab.userProfiles.label",
  },
];

// The current tab index is stored here as it is needed to display buttons above the tab components.
const selectedTabIndex: Ref<number | undefined> = ref();

const isUsersTabSelected = computed(
  (): boolean => selectedTabIndex.value === usersTabIndex,
);

const isGroupsTabSelected = computed(
  (): boolean => selectedTabIndex.value === groupsTabIndex,
);

const isProfilesTabSelected = computed(
  (): boolean => selectedTabIndex.value === profilesTabIndex,
);
</script>

<template>
  <lxc-container>
    <div class="flex justify-between">
      <lxc-breadcrumb
        :name="t('layout.menu.users')"
        should-not-translate
        readonly
      />

      <div class="self-end">
        <div v-if="isUsersTabSelected" class="flex justify-end gap-4">
          <lxc-button
            :icon="ILxcPlus"
            :title="t('user.button.create')"
            class="self-center"
            html-type="button"
            type="primary"
            @click="onCreateUser"
          >
            {{ t("user.button.create") }}
          </lxc-button>
        </div>
        <div v-else-if="isGroupsTabSelected">
          <lxc-button
            :icon="ILxcPlus"
            :title="t('userGroup.button.create')"
            class="self-center"
            html-type="button"
            type="primary"
            @click="onCreateUserGroup"
          >
            {{ t("userGroup.button.create") }}
          </lxc-button>
        </div>
        <div v-else-if="isProfilesTabSelected">
          <lxc-button
            :icon="ILxcPlus"
            :title="t('profile.new')"
            class="self-center"
            html-type="button"
            type="primary"
            @click="onCreateProfile"
          >
            {{ t("profile.new") }}
          </lxc-button>
        </div>
      </div>
    </div>

    <LxcTabsWithNavigation
      :tab-navigations="tabNavigations"
      @update:selected-tab-index="
        (newSelectedTabIndex) => (selectedTabIndex = newSelectedTabIndex)
      "
    >
      <template #[usersTabIndex]>
        <lxc-user-list :actions="true" />
      </template>
      <template #[groupsTabIndex]>
        <lxc-user-group-list />
      </template>
      <template #[profilesTabIndex]>
        <lxc-profiles
          :interface-form-shown="profileInterfaceShown"
          @update:side-canvas-shown="onUpdateInterfaceFormShown"
        />
      </template>
    </LxcTabsWithNavigation>
  </lxc-container>
</template>
