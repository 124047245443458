<script setup lang="ts">
import { ExtendedKeyUsageId } from "@lxc/app-device-types";

interface Option {
  label: string;
  value: ExtendedKeyUsageId;
}

interface Props {
  modelValue: ExtendedKeyUsageId[];
  isEditable: boolean;
}
const { modelValue } = defineProps<Props>();
const emit = defineEmits(["update:modelValue"]);

const { t } = useI18n();

const selectedOptions: ComputedRef<ExtendedKeyUsageId[]> = computed({
  get() {
    return modelValue;
  },
  set(selectedOption: ExtendedKeyUsageId[]) {
    emit("update:modelValue", selectedOption);
  },
});
// For the moment, we only support server and client authentication in the UI.
// Check the RFC 5280 for more details about the available ID (https://datatracker.ietf.org/doc/html/rfc5280#section-4.2.1.12)
const supportedExtendedKeyUsageIds = [
  ExtendedKeyUsageId.SERVER_AUTHENTICATION,
  ExtendedKeyUsageId.CLIENT_AUTHENTICATION,
];
const extendedKeyUsageIdsOptions: Ref<Option[]> = ref([]);
extendedKeyUsageIdsOptions.value = supportedExtendedKeyUsageIds
  .map((option) => {
    return {
      label: t(`certificates.extendedKeyUsage.${option}`),
      value: option,
    };
  })
  .sort((option1, option2) => option1.value - option2.value);
</script>

<template>
  <div v-if="modelValue.length === 0 && !isEditable" class="pb-1">
    <span>{{ t("certificates.extendedKeyUsage.empty") }}</span>
  </div>
  <div v-else class="pt-1.5">
    <lxc-checkbox
      v-for="option in extendedKeyUsageIdsOptions"
      :key="option.value"
      v-model="selectedOptions"
      :label="option.label"
      :value="option.value"
      :disabled="!isEditable"
      class="mb-2 select-none"
    />
  </div>
</template>
