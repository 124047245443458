<script setup lang="ts">
import { SelectionModeEnum, SortMode } from "@lxc/app-device-common";
import type LxcTable from "@lxc/app-device-common/src/components/LxcTable.vue";
import { FleetI } from "@lxc/app-device-types";
import { displayColumns } from "~/components/fleets/fleetsList/fleetsList.type";
import { useFleet } from "~/composables/useFleet";
import type { SearchMode } from "~/composables/useSearch";
import { PATHS } from "~/constants/paths";
import { Filters } from "~/types";
import { formatIsoDate } from "~/utils/date-tools";
import ILxcInfo from "~icons/lxc/info";

const props = defineProps<{
  reload?: boolean;
  columns?: displayColumns[];
  searchMode?: SearchMode;
  searchBarClass?: string | string[];
  selectedFleets?: FleetI[];
  useQueryParametersForPagination?: boolean;
  compatibilityCriteria?: string;
  rowClickSelectionMode?: SelectionModeEnum;
  noRedirection?: boolean;
}>();

const emit = defineEmits([
  "update:reload",
  "update:selected-fleets",
  "rowClick",
]);

const { t } = useI18n();
const router = useRouter();
const {
  isLoading,
  error,
  results,
  setFilter,
  setCompatibilityCriteria,
  fetchData,
  search,
  filters,
  onSearch,
  onSortChange,
} = useFleet(
  props.searchMode,
  props.useQueryParametersForPagination,
  props.compatibilityCriteria,
);

const searchQuery = computed({
  get() {
    return (filters.get(Filters.FLEET_NAME) ?? "") as string;
  },
  set(searchQuery) {
    setFilter(Filters.FLEET_NAME, searchQuery);
  },
});

async function reloadData(page?: number, pageSize?: number) {
  await fetchData(page, pageSize);
  setSelection();
}

async function executeSearch(replaceHistory?: boolean) {
  // call search in order to update the query parameters with filters
  await search(replaceHistory);
}

const tableRef = ref<InstanceType<typeof LxcTable>>();

/**
 * Select fleets
 */
const setSelection = () => {
  if (props.selectedFleets && tableRef.value && tableRef.value.data) {
    tableRef.value.toggleTableSelectionMode(props.selectedFleets.length > 0);
    for (const row of tableRef.value.data) {
      tableRef.value.toggleRowSelection(
        row,
        props.selectedFleets?.some(
          (selectedFleet) => selectedFleet.uid === row.uid,
        ),
      );
    }
  }
};

watch(() => props.selectedFleets, setSelection);

watch(
  () => props.compatibilityCriteria,
  (compatibilityCriteria) => {
    setCompatibilityCriteria(compatibilityCriteria);
    reloadData();
  },
);

const handleSelect = (selectedFleets: FleetI[]) => {
  const computeSelectedFleets = props.selectedFleets
    ?.filter(
      (selectedFleet: FleetI) =>
        !tableRef.value ||
        !tableRef.value.data?.find(
          (row: FleetI) => row.uid === selectedFleet.uid,
        ),
    )
    .filter(
      (selectedFleet: FleetI) =>
        !selectedFleets.find((row) => row.uid === selectedFleet.uid),
    )
    .concat(selectedFleets);

  emit("update:selected-fleets", computeSelectedFleets);
};

interface TooltipInfoI {
  display: boolean;
  message?: string;
}

const initialSelectedFleet: FleetI[] = props.selectedFleets
  ? [...props.selectedFleets]
  : [];

const getTooltipMessage = (fleet: FleetI): TooltipInfoI => {
  const fleetInSelectedFleet = initialSelectedFleet.find(
    (sFleet) => sFleet.uid === fleet.uid,
  );

  if (fleet.compatibleDeviceTwinCount === 0) {
    return {
      display: true,
      message: t("fleet.table.column.information.noDeviceCompatible"),
    };
  } else if (fleetInSelectedFleet && fleetInSelectedFleet.hash !== fleet.hash) {
    return {
      display: true,
      message: t("fleet.table.column.information.fleetHasChanged"),
    };
  } else if (fleet.deviceTwinCount !== fleet.compatibleDeviceTwinCount) {
    return {
      display: true,
      message: t("fleet.table.column.information.incompatibleDevices"),
    };
  } else {
    return {
      display: false,
    };
  }
};

const disabledFleetSelection = (fleet: FleetI): boolean => {
  return fleet.compatibleDeviceTwinCount === 0;
};

watch(
  () => props.reload,
  (reload) => {
    if (reload) {
      reloadData();
      emit("update:reload", false);
    }
  },
);

const onRowClick = (fleet: FleetI) => {
  emit("rowClick", fleet);
  if (!props.noRedirection) {
    router.push(`${PATHS.FLEETS}/${fleet.uid}`);
  }
};

onMounted(async () => {
  onSearch(reloadData, false);
  await executeSearch(true);
});
</script>

<template>
  <div :class="`mb-6 mt-2.5 ${searchBarClass}`">
    <lxc-search-bar
      v-model:search-query="searchQuery"
      :search-placeholder="t('filters.searchByName')"
      @clear="search"
      @search="search"
    />
  </div>
  <lxc-table
    ref="tableRef"
    min-width="71rem"
    :is-loading="isLoading"
    :context="results?.context"
    :data="results?.data"
    :error="error?.toError()"
    data-cy="fleet-table"
    clickable
    :empty-text="t('fleet.table.empty')"
    :row-click-selection-mode="rowClickSelectionMode"
    :is-row-disabled="disabledFleetSelection"
    @change-page-and-page-size="reloadData"
    @row-click="onRowClick"
    @select="handleSelect"
    @select-all="handleSelect"
    @sort-change="onSortChange"
  >
    <lxc-table-column v-if="selectedFleets" type="selection" />
    <lxc-table-column
      v-if="!columns || columns.includes(displayColumns.FRIENDLY_NAME)"
      prop="friendlyName"
      :label="t('fleet.table.column.fleet')"
      :sort-mode="SortMode.CUSTOM"
      min-width="20rem"
    />
    <lxc-table-column
      v-if="!columns || columns.includes(displayColumns.DEVICE_TWIN_COUNT)"
      prop="deviceTwinCount"
      :label="t('fleet.table.column.devices')"
      align="right"
      width="10rem"
    />
    <lxc-table-column
      v-if="!columns || columns.includes(displayColumns.CREATED_AT)"
      prop="createdAt"
      :label="t('fleet.table.column.createdAt')"
      :sort-mode="SortMode.CUSTOM"
      width="15rem"
    >
      <template #default="scope">
        {{ formatIsoDate(scope.row.createdAt, t("dateFormat.datetime")) }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      v-if="!columns || columns.includes(displayColumns.MODIFIED_AT)"
      prop="modifiedAt"
      :label="t('fleet.table.column.updatedAt')"
      :sort-mode="SortMode.CUSTOM"
      min-width="20rem"
    >
      <template #default="scope">
        {{ formatIsoDate(scope.row.modifiedAt, t("dateFormat.datetime")) }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      v-if="
        columns &&
        columns.includes(displayColumns.INCOMPATIBLE_DEVICE_TWIN_COUNT)
      "
      prop="incompatibleDeviceTwinCount"
      :label="t('fleet.table.column.incompatibleDevices')"
      align="right"
      width="18rem"
    >
      <template #default="scope">
        {{
          isNaN(scope.row.compatibleDeviceTwinCount)
            ? 0
            : scope.row.deviceTwinCount - scope.row.compatibleDeviceTwinCount
        }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      v-if="columns && columns?.includes(displayColumns.TOOLTIP)"
      class="!py-0"
      width="6rem"
    >
      <template #default="scope">
        <span
          v-if="getTooltipMessage(scope.row).display"
          :title="getTooltipMessage(scope.row).message"
        >
          <ILxcInfo class="text-gray-700" width="1.25rem" height="1.25rem" />
        </span>
      </template>
    </lxc-table-column>
    <lxc-table-column
      v-if="!columns || columns.includes(displayColumns.ACTION)"
      class="!py-0"
      width="6rem"
    >
      <template #default="scope">
        <lxc-fleet-delete-action
          :fleet-uid="scope.row.uid"
          @deleted="reloadData"
        />
      </template>
    </lxc-table-column>
  </lxc-table>
</template>
<style lang="scss" scoped>
:deep(table) {
  tbody {
    tr {
      &:hover {
        button {
          visibility: visible;
          svg {
            height: 20px;
            width: 20px;
          }
        }
      }
    }
  }
}
</style>
