import type { PaginatedListI } from "@lxc/app-device-types";

const defaultPageSize = 10;

interface UseFetchAllWithPaginationI<T> {
  callback: (page: number, pageSize: number) => Promise<PaginatedListI<T>>;
  pageSize?: number;
}

/**
 * Fetch all resources using a pagination callback (typically a call to another micro-service endpoint).
 * @param callback Pagination callback used to fetch resources.
 * @param pageSize Batch size used during the pagination fetch. Default: 10.
 */
export async function fetchAllWithPagination<T>({
  callback,
  pageSize = defaultPageSize,
}: UseFetchAllWithPaginationI<T>) {
  let result: T[] = [];
  let page = 0;
  let totalCount = 0;
  do {
    try {
      page++;
      const data = await callback(page, pageSize);
      if (page === 1) {
        totalCount = data.pagination.totalCount;
      }
      result = [...result, ...data.results];
    } catch (e) {
      console.error(
        `An error occurred during the retrieval of paginated items. Exception: ${e}`,
      );
      return result;
    }
  } while (shouldContinueFetch(page, pageSize, totalCount));
  return result;
}

function shouldContinueFetch(
  page: number,
  pageSize: number,
  totalCount: number,
) {
  return page * pageSize < totalCount;
}
