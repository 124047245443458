import type { CartographySettingsI } from "@lxc/app-device-types/dist/interfaces/cartography.interface";
import RestService from "~/services/rest.service";

class CartographyService extends RestService {
  private BASE_URL = "/cartography/settings";

  getCartographySettings(): Promise<CartographySettingsI> {
    return this.$api.get(this.BASE_URL);
  }
}

export default new CartographyService();
