import RestService from "./rest.service";
import type {
  ApiListResult,
  AppDeviceState,
  ApplicationI,
  ApplicationModelConfigurationI,
  DeviceConfigsI,
  OperationI,
  OperationType,
} from "@lxc/app-device-types";
import type { FiltersSelection } from "~/types";

export class ApplicationService extends RestService {
  protected BASE_URL = "/applications";
  private PARAMETERS_BASE_URL =
    "/parameters/application-certificate-management";

  /**
   * Retrieve applications
   * @param page
   * @param pageSize
   * @param search
   * @param sort
   * @param otherParams
   */
  public getAllApplications(
    page: number,
    pageSize: number,
    search?: string | FiltersSelection,
    sort?: string | null,
    otherParams?: Map<string, any>,
  ): Promise<ApiListResult<ApplicationI>> {
    const params = {
      page,
      pageSize,
      search,
      sort,
      ...(otherParams ? Object.fromEntries(otherParams) : {}),
    };
    return this.$api.get(this.BASE_URL, { params });
  }

  /**
   * Get application details
   * @param appId
   */
  public getApplicationDetails(appId: string): Promise<ApplicationI> {
    return this.$api.get(`${this.BASE_URL}/${appId}`);
  }

  /**
   * Get multiple device details
   * @param deviceIds
   */
  public getApplicationsDetails(appIds: string[]): Promise<ApplicationI[]> {
    const data = {
      id: appIds,
    };
    const config = {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
    };
    return this.$api.post(`${this.BASE_URL}/getAppsDetails`, data, config);
  }

  /**
   * Update application state
   * @param appId
   * @param state
   */
  public updateApplicationState(
    appId: string,
    state: AppDeviceState,
  ): Promise<any> {
    return this.$api.patch(`${this.BASE_URL}/${appId}`, { state });
  }

  /**
   * Retrieve apps configuration
   */
  public getAppsConfiguration(): Promise<DeviceConfigsI> {
    return this.$api.get(`${this.PARAMETERS_BASE_URL}`);
  }

  /**
   * Update apps configuration
   * @param appModels
   */
  public updateAppsConfiguration(appModels: ApplicationModelConfigurationI[]) {
    return this.$api.patch(`${this.PARAMETERS_BASE_URL}`, { appModels });
  }

  /**
   * Delete an application
   * @param applicationId
   */
  public deleteApplication(applicationId: string): Promise<any> {
    return this.$api.delete(`${this.BASE_URL}/${applicationId}`);
  }

  /**
   * Create a renew certificate operation
   * @param device
   */
  public createOperationRenewCertificate(
    application: ApplicationI,
    startTime: string,
  ): Promise<OperationI> {
    return this.$api.post(
      `${this.BASE_URL}/${application.id}/renewCertificate/${startTime}`,
    );
  }

  /**
   * Get an application operations
   * @param applicationId
   * @param page
   * @param pageSize
   * @returns
   */
  public getApplicationOperations(
    applicationId: string,
    page?: number,
    pageSize?: number,
    sort?: string,
    operationType?: OperationType,
    hasCallbackUrl?: boolean,
  ): Promise<ApiListResult<OperationI>> {
    let params = {};

    if (sort) {
      params = { ...params, sort };
    }

    if (page) {
      params = { ...params, page };
    }

    if (pageSize) {
      params = { ...params, pageSize };
    }

    if (operationType) {
      params = { ...params, operationType };
    }

    if (hasCallbackUrl !== undefined) {
      params = { ...params, hasCallbackUrl };
    }

    return this.$api.get(`${this.BASE_URL}/${applicationId}/operations`, {
      params,
    });
  }
}

export default new ApplicationService();
