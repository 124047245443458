<script setup lang="ts">
import { SelectionModeEnum } from "@lxc/app-device-common";
import type { DtwinI, FleetI } from "@lxc/app-device-types";
import type { Ref, WritableComputedRef } from "vue";
import { DisplayColumns } from "~/components/dtwins/dtwinsList/dtwinsList.type";
import { SearchMode } from "~/composables/useSearch";

const props = defineProps<{
  attachDevicesPanelShown: boolean;
  reloadDtwinList: boolean;
  fleet?: FleetI;
  isUpdatingFleet?: boolean;
}>();
const emit = defineEmits([
  "update:attachDevicesPanelShown",
  "update:reloadDtwinList",
  "addToFleet",
]);

const { t } = useI18n();

const showSidePanel: WritableComputedRef<boolean> = computed({
  get: () => {
    return props.attachDevicesPanelShown;
  },
  set: (shown: boolean) => {
    emit("update:attachDevicesPanelShown", shown);
  },
});

const reloadDtwins: WritableComputedRef<boolean> = computed({
  get: () => {
    return props.reloadDtwinList;
  },
  set: (reload: boolean) => {
    emit("update:reloadDtwinList", reload);
  },
});

const dtwinsListRef = ref();
const selectedDtwins: Ref<DtwinI[]> = ref([]);
const isValidateDisabled: ComputedRef<boolean> = computed(
  () => selectedDtwins.value.length === 0,
);

const onClose = () => {
  showSidePanel.value = false;
  dtwinsListRef.value.onCancelSelection();
  selectedDtwins.value = [];
};
defineExpose({
  onClose,
});
</script>
<template>
  <lxc-side-canvas
    v-model:show="showSidePanel"
    :header="t('fleet.attachDevices.title')"
    :close-tooltip="t('button.close')"
    body-class="!py-6"
    size="2/3"
    :confirm-enabled="selectedDtwins.length > 0"
    :confirm-message="t('fleet.attachDevices.confirmMessage')"
    :confirm-ok-label="t('button.confirm')"
    :confirm-cancel-label="t('button.cancel')"
    @hidden="onClose"
  >
    <lxc-dtwins-list
      ref="dtwinsListRef"
      v-model:reload="reloadDtwins"
      v-model:selected-dtwins="selectedDtwins"
      :columns="[
        DisplayColumns.FRIENDLY_NAME,
        DisplayColumns.TYPE,
        DisplayColumns.SERIAL_NUMBER,
        DisplayColumns.SECTOR,
        DisplayColumns.STATE,
      ]"
      :row-click-selection-mode="SelectionModeEnum.FORCE"
      hide-add-to-fleet-panel
      no-quick-action-menu
      no-quick-filters
      clickable
      :search-mode="SearchMode.FILTER_SEARCH"
      :not-in-fleet-uid="fleet?.uid"
    />

    <template #footer>
      <div class="flex justify-end">
        <div class="flex flex-row-reverse gap-6 w-1/2">
          <lxc-button
            :title="t('button.validate')"
            :disabled="isValidateDisabled || isUpdatingFleet"
            :is-loading="isUpdatingFleet"
            class="flex-1"
            @click="$emit('addToFleet', selectedDtwins)"
          >
            {{ t("button.validate") }}
          </lxc-button>
          <lxc-button
            type="secondary"
            :title="t('button.close')"
            @click="showSidePanel = false"
          >
            {{ t("button.close") }}
          </lxc-button>
        </div>
      </div>
    </template>
  </lxc-side-canvas>
</template>
