import type { TranslatedLogAttributeI } from "@lxc/app-device-types";
import { defineStore } from "pinia";
import type LxcError from "~/utils/LxcError";

interface AllActionActionTypes {
  allActions: TranslatedLogAttributeI[] | LxcError | undefined;
  allActionTypes: TranslatedLogAttributeI[] | LxcError | undefined;
  allActionsLanguage: string | undefined;
  allActionTypesLanguage: string | undefined;
}

export const useLogActionActionTypeStore = defineStore(
  "logActionActionTypeStore",
  () => {
    const allActionsActionTypes = reactive<AllActionActionTypes>({
      allActions: undefined,
      allActionTypes: undefined,
      allActionsLanguage: undefined,
      allActionTypesLanguage: undefined,
    });

    return {
      ...toRefs(allActionsActionTypes),
    };
  },
);
