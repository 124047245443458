export type LogFilterType =
  | "componentId"
  | "entityClass"
  | "entityId"
  | "level"
  | "eventType"
  | "sortBy"
  | "sortDirection"
  | "action";

export interface LogLabelI {
  authorId: string;
  assetId?: string;
  changes?: any;
}

export enum ActionsOrActionTypesContext {
  ACTION = "action",
  ACTION_TYPES = "actionTypes",
}
