<script lang="ts" setup>
import LxcInformationRow from "~/components/shared/LxcInformationRow.vue";

const { t } = useI18n();

interface Props {
  showConfirm?: boolean;
  isDialogVisible: boolean;
  title: string;
  description: string;
  info?: string;
  disabledConfirm?: boolean;
  okLabel?: string;
  cancelLabel?: string;
  icon?: string | object;
  iconColorTheme?: "success" | "warning" | "error";
  colorTheme?: "primary" | "danger";
}

withDefaults(defineProps<Props>(), {
  showConfirm: true,
});

defineEmits(["confirm", "cancel"]);
</script>

<template>
  <lxc-confirm
    :show="isDialogVisible"
    :title="title"
    :message="description"
    :ok-label="okLabel ?? t('button.confirm')"
    :cancel-label="cancelLabel ?? t('button.cancel')"
    :disable-confirm-button="disabledConfirm"
    :show-confirm-button="showConfirm"
    :icon="icon"
    :icon-color-theme="iconColorTheme"
    :color-theme="colorTheme"
    @confirm="$emit('confirm')"
    @cancel="$emit('cancel', false)"
  >
    <span>{{ description }}</span>
    <lxc-information-row v-if="info" :title="info" />
  </lxc-confirm>
</template>
