<script lang="ts" setup>
import ILxcAlertCircle from "~icons/lxc/alert-circle";

const { t } = useI18n();
</script>
<template>
  <div class="w-1/4 min-w-52">
    <lxc-alert :icon="ILxcAlertCircle" type="error" class="flex items-center">
      <p>{{ t("dtwins.cartography.devicesMap.noDevices") }}</p>
    </lxc-alert>
  </div>
</template>
