import { onMounted, onUnmounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import type { TabNavigation } from "~/components/shared/tabs/LxcTabsWithNavigation.vue";

/**
 * Composable used to handle the tabs navigations in a page. This composable is
 * typically used on pages using multiple tabs with dedicated paths via the
 * usage of the `LxcTabs` component.

 * @param tabNavigations Representations of the tabs used in the page.
 */
export function useTabsNavigation({
  tabNavigations,
}: {
  tabNavigations: TabNavigation[];
}) {
  const route = useRoute();
  const router = useRouter();

  /**
   * The selected tab index. The value can be `undefined` if invalid data is
   * provided to the composable. Otherwise, the selected index is either the tab
   * index desired by the user or the first tab in the `tabNavigations`
   * configuration if the index cannot be found.
   */
  const selectedTabIndex = ref(setDefaultSelectedTabIndex(route.path));

  function setDefaultSelectedTabIndex(path: string): number | undefined {
    for (const tabNavigation of tabNavigations) {
      if (path.startsWith(tabNavigation.redirectPath)) {
        return tabNavigation.index;
      }
    }
    return undefined;
  }

  function setSelectedTabIndex(index: number): number | undefined {
    const allIndexes = tabNavigations.map(
      (tabNavigation) => tabNavigation.index,
    );
    if (!allIndexes.includes(index)) {
      if (allIndexes.length > 0) {
        return allIndexes[0];
      }
      return undefined;
    }
    return index;
  }

  /**
   * Update the `selectedTabIndex` index and perform the navigation to the tab.
   * If the selected index is not valid the user is automatically redirected to
   * the first tab in the `tabNavigations` configuration.
   * @param index Index of the tab to be displayed to the user.
   */
  async function onTabSelected(index: number) {
    selectedTabIndex.value = setSelectedTabIndex(index);
    if (selectedTabIndex.value !== undefined) {
      const selectedTabNavigation = tabNavigations.find(
        (tabNavigation) => tabNavigation.index === selectedTabIndex.value,
      );
      if (selectedTabNavigation) {
        await router.replace(selectedTabNavigation.redirectPath);
      }
    }
  }

  /**
   * Handle the redirection to the tab when using the browser back and forward
   * buttons.
   */
  const handlePopState = () => {
    selectedTabIndex.value = setDefaultSelectedTabIndex(route.path);
  };

  onMounted(() => {
    window.addEventListener("popstate", handlePopState);
  });
  onUnmounted(() => {
    window.removeEventListener("popstate", handlePopState);
  });

  return {
    selectedTabIndex,
    onTabSelected,
  };
}
