import RestService from "./rest.service";
import type { ApiListResult, UserGroupI } from "@lxc/app-device-types";
import type { FiltersSelection } from "~/types";

export class UserGroupsService extends RestService {
  protected BASE_URL = "/groups";

  /**
   * Retrieve/search through all userGroups
   * @param page
   * @param pageSize
   * @param search
   * @param sort
   * @param otherParams
   */
  getUserGroups(
    page: number,
    pageSize: number,
    search?: string | FiltersSelection,
    sort?: string | null,
    otherParams?: Map<string, any>,
  ): Promise<ApiListResult<UserGroupI>> {
    const params = {
      page,
      pageSize,
      search,
      sort,
      ...(otherParams ? Object.fromEntries(otherParams) : {}),
    };

    return this.$api.get(this.BASE_URL, { params });
  }

  /**
   * Retrieve a userGroup with a specific code
   * @param code
   */
  getUserGroupByCode(code: string | number): Promise<UserGroupI> {
    return this.$api.get(`${this.BASE_URL}/${code}`);
  }

  /**
   * Get multiple users groups
   * @param codes
   */
  getUserGroupsByCode(codes: string[]): Promise<UserGroupI[]> {
    const data = {
      codes,
    };
    const config = {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
    };
    return this.$api.post(
      `${this.BASE_URL}/getUserGroupsByCodes`,
      data,
      config,
    );
  }

  /**
   * Create a userGroup
   * @param userGroup
   */
  createUserGroup(userGroup: UserGroupI): Promise<string> {
    return this.$api.post(this.BASE_URL, { userGroup });
  }

  /**
   * Update a userGroup
   * @param userGroup
   */
  updateUserGroup(userGroup: UserGroupI): Promise<UserGroupI> {
    return this.$api.put(`${this.BASE_URL}/${userGroup.code}`, { userGroup });
  }

  /**
   * Remove a userGroup
   * @param userGroupCode
   */
  removeUserGroup(userGroupCode: string) {
    return this.$api.delete(`${this.BASE_URL}/${userGroupCode}`);
  }
}

export default new UserGroupsService();
