<script setup lang="ts">
import { useExportAsCsv } from "@lxc/app-device-common";
import type { AsyncLogI } from "@lxc/app-device-types";
import Handlebars from "handlebars";
import { computed } from "vue";
import type { LogLabelI } from "~/types";
import { formatIsoDateToUtc } from "~/utils/date-tools";
import { showNotificationError } from "~/utils/notifications-tools";
import ILxcDownload from "~icons/lxc/download";
import ILxcInfo from "~icons/lxc/info";

const { t } = useI18n();
const { exportAsCsv } = useExportAsCsv();

const props = defineProps<{
  data: AsyncLogI[];
}>();

const filenamePrefix = "log-export";

const exportDisabled = computed((): boolean => {
  return props.data == null || props.data.length === 0;
});

const exportOptions = Object.freeze({
  fields: [
    {
      label: "timestamp",
      value: "timestamp",
      default: "",
    },
    {
      label: t("logs.level.label"),
      value: "level",
      default: "",
    },
    {
      label: t("logs.eventType.label"),
      value: "eventType",
      default: "",
    },
    {
      label: t("logs.list.column.label.label"),
      value: "label",
      default: "",
    },
    {
      label: t("logs.action.label"),
      value: "action",
      default: "",
    },
  ],
  delimiter: ",",
  withBOM: true,
});

const convertLog: (
  log: AsyncLogI,
) => Record<string, string | undefined | null> = (log: AsyncLogI) => {
  const { timestamp, level, eventType, label, action } = log;
  let resultLabel;

  if (label) {
    const labelModel: LogLabelI = {
      authorId: log.authorName ?? log.authorId,
    };

    if (log.assetId) {
      labelModel.assetId = log.assetName ?? log.assetId;
    }

    if (log.changes?.changesId) {
      labelModel.changes = {
        changesId: log.changes?.changesName ?? log.changes?.changesId,
      };
    }

    const template = Handlebars.compile(label);
    resultLabel = template(labelModel);
  }

  return {
    action: action as string,
    eventType: eventType as string,
    label: resultLabel,
    level: level as string,
    timestamp: formatIsoDateToUtc(timestamp),
  };
};

const onExportClick = () => {
  const logExport: Record<string, string | undefined | null>[] =
    props.data.map(convertLog);
  try {
    exportAsCsv(logExport, exportOptions, filenamePrefix);
  } catch (error) {
    showNotificationError(t("logs.export.error"));
  }
};
</script>
<template>
  <lxc-button
    html-type="button"
    type="primary"
    title=""
    :disabled="exportDisabled"
    @click="onExportClick"
  >
    <span class="download-icon mr-1.5">
      <ILxcDownload class="h-5 w-5" />
    </span>
    {{ t("logs.export.label") }}
    <span class="download-icon ml-1.5" :title="t('logs.export.tooltip')">
      <ILxcInfo class="h-5 w-5"
    /></span>
  </lxc-button>
</template>
<style scoped lang="scss">
.download-icon {
  :deep(svg) {
    display: inline;
  }
}
</style>
