<script lang="ts" setup>
import type { SelectionModeEnum } from "@lxc/app-device-common";
import type LxcTable from "@lxc/app-device-common/src/components/LxcTable.vue";
import type {
  ApiListResult,
  ApplicationI,
  DeviceI,
  DtwinI,
  UserDataI,
  UserGroupI,
} from "@lxc/app-device-types";
import { DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE } from "~/constants/constants";
import { SectorResource } from "~/types";

const props = defineProps<{
  primaryKey: string;
  isLoading: boolean;
  selection?: boolean;
  items: ApiListResult<any> | null;
  displayActions?: boolean;
  modelValue: any[];
  resource: string;
  rowClickSelectionMode?: SelectionModeEnum;
  getDescription:
    | ((item: ApplicationI) => string)
    | ((item: DeviceI) => string)
    | ((item: DtwinI) => string)
    | ((item: UserDataI) => string)
    | ((item: UserGroupI) => string);
}>();

const emit = defineEmits([
  "pagination:getNextPage",
  "actions:detach",
  "update:modelValue",
  "rowClick",
]);

const { t } = useI18n();

const detailTable = ref();
const rowSelection = ref([]);

watch(
  () => props.items,
  async () => {
    await nextTick();
    refreshTableSelection();
  },
);

watch(
  () => props.modelValue,
  async (value) => {
    rowSelection.value = value;
    refreshTableSelection();
  },
);

/**
 * When changing selection from input
 */
function onChangeSelectInput() {
  refreshTableSelection();
  updateModel();
}

/**
 * When selecting a specific row
 * @param selection
 */
function onSelectRow(
  selection: (ApplicationI | DeviceI | DtwinI | UserDataI | UserGroupI)[],
) {
  const temporarySet = new Set<
    ApplicationI | DeviceI | DtwinI | UserDataI | UserGroupI
  >();

  rowSelection.value
    .filter(
      (selected) =>
        !detailTable.value.data.find((row) => {
          if (props.resource === SectorResource.DTWINS) {
            return row.uid === selected.uid;
          } else {
            return row.id === selected.id;
          }
        }),
    )
    .forEach((value) => temporarySet.add(value));
  selection.forEach((value) => temporarySet.add(value));
  rowSelection.value = Array.from(temporarySet.values());
  updateModel();
}

function updateModel() {
  emit("update:modelValue", rowSelection.value);
}

function refreshTableSelection() {
  detailTable.value.toggleTableSelectionMode(rowSelection.value.length > 0);
  detailTable.value.data.forEach(
    (row: ApplicationI | DeviceI | DtwinI | UserDataI | UserGroupI) => {
      const selected = rowSelection.value.some(
        (item: ApplicationI | DeviceI | DtwinI | UserDataI | UserGroupI) => {
          if (props.resource === SectorResource.DTWINS) {
            return row.uid === item.uid;
          } else {
            return row.id === item.id;
          }
        },
      );
      detailTable.value.toggleRowSelection(row, selected);
    },
  );
}

async function getNextByPageAndSize(
  page: number = DEFAULT_FIRST_PAGE,
  pageSize: number = DEFAULT_PAGE_SIZE,
) {
  emit("pagination:getNextPage", page, pageSize);
}
</script>

<template>
  <div v-if="rowSelection.length !== 0" class="footer-wrapper mb-4">
    <el-select
      v-if="rowSelection.length !== 0"
      v-model="rowSelection"
      class="footer-selection"
      :value-key="props.resource === SectorResource.DTWINS ? 'uid' : 'id'"
      multiple
      collapse-tags
      collapse-tags-tooltip
      :max-collapse-tags="3"
      reserve-keyword
      :placeholder="t('sectors.details.currentSelection')"
      :teleported="false"
      @change="onChangeSelectInput"
    >
      <el-option
        v-for="item in rowSelection"
        :key="item.code"
        :label="getDescription(item)"
        :value="item"
      />
    </el-select>
  </div>

  <lxc-table
    ref="detailTable"
    min-width="65rem"
    class="mb-4"
    :is-loading="isLoading"
    :data="items?.data"
    :context="items?.context"
    :empty-text="t(`${resource}.empty`)"
    clickable
    :row-click-selection-mode="rowClickSelectionMode"
    @select="onSelectRow"
    @select-all="onSelectRow"
    @row-click="$emit('rowClick', $event)"
    @change-page-and-page-size="getNextByPageAndSize"
  >
    <lxc-table-column v-if="selection" type="selection" />
    <slot />
    <lxc-table-column width="6rem">
      <template #default="scope">
        <lxc-sector-item-actions
          :display-actions="displayActions"
          @detach="emit('actions:detach', scope.row)"
        />
      </template>
    </lxc-table-column>
  </lxc-table>
</template>
