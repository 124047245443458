<script setup lang="ts">
import type { Ref } from "vue";
import { appTypeDeclinationMap } from "~/constants/applicationsFilters.config";
import type { Option } from "~/types";

const { t } = useI18n();

const props = defineProps<{
  modelValue: string[];
  types: string[];
}>();
const emit = defineEmits(["selected", "update:modelValue"]);

const loading: Ref<boolean> = ref(false);

const selectedItem = computed({
  get() {
    return props.modelValue ?? [];
  },
  set(selected?: string[]) {
    emit("update:modelValue", selected);
  },
});

const declinations = computed((): Option[] => {
  if ((props.types?.length ?? 0) === 0) {
    return [];
  } else {
    let options: Option[] = [];
    for (const type of props.types) {
      const appTypeDelination = appTypeDeclinationMap[type];
      if (appTypeDelination) {
        options = options.concat(
          appTypeDelination.applicationDeclinations.map(
            (declination: string): Option => {
              return {
                label: declination,
                value: declination,
              };
            },
          ),
        );
      }
    }
    return options;
  }
});
</script>

<template>
  <LxcFilterSelectableList
    v-model="selectedItem"
    :data="declinations"
    :is-loading="loading"
    :empty-text="t('logs.filters.applications.declination.empty')"
    :header="t('application.model.declination')"
    prop="label"
    item-prop="value"
  />
</template>
