export enum DisplayColumns {
  FRIENDLY_NAME,
  TYPE,
  SERIAL_NUMBER,
  SECTOR,
  CONNECTIVITY,
  METASTATUS,
  STATE,
  CREATED_AT,
  ACTION,
}

export enum DtwinsListDisplayContext {
  DTWINS,
  FLEET,
  DTWIN_DETAIL,
}

export enum DeviceCompatibilityQuickFilter {
  ALL_DEVICES = "allDevices",
  COMPATIBLE_DEVICES = "compatibleDevices",
  INCOMPATIBLE_DEVICES = "incompatibleDevices",
}
