<script setup lang="ts">
import type { UniqueLabel } from "@lxc/app-device-common";
import { filterEmptyValues } from "@lxc/app-device-common";
import type { TranslatedLogAttributeI } from "@lxc/app-device-types";
import { ref } from "vue";
import type { ActionsOrActionTypesContext } from "~/types";
import filtersUtils from "~/utils/filters.utils";

const { t } = useI18n();

const props = defineProps<{
  label: string;
  context: ActionsOrActionTypesContext;
  modelValue?: TranslatedLogAttributeI[];
}>();
const emit = defineEmits(["update:modelValue"]);

const name = ref("");

const selectedItems = computed({
  get(): TranslatedLogAttributeI[] {
    return props.modelValue ?? [];
  },
  set(selected?: TranslatedLogAttributeI[]) {
    emit("update:modelValue", selected);
  },
});
const isAnyAppliedFilter = computed(() => {
  return (props.modelValue?.length ?? 0) > 0;
});

const filterTags = computed((): UniqueLabel[] => {
  return filterEmptyValues<UniqueLabel>(
    (props.modelValue ?? []).map((item) =>
      filtersUtils.getTag(props.label, item.rawValue, item.translation),
    ),
  );
});

const onTagDeleteClick = (label: string, rawValue?: string) => {
  if (selectedItems.value) {
    const index = selectedItems.value?.findIndex(
      (item) => item.rawValue === rawValue,
    );

    if (index >= 0) {
      selectedItems.value?.splice(index, 1);
    }
  }
};
</script>
<template>
  <div
    v-if="isAnyAppliedFilter"
    class="flex items-center justify-start pt-2 pb-6"
  >
    <lxc-tag-set
      border-visible
      deletable
      type="primary"
      :data="filterTags"
      :delete-tooltip="t('filters.deleteSelectedFilter')"
      @delete="onTagDeleteClick"
    />
  </div>
  <div class="rounded-lg bg-white text-gray-900 pt-6">
    <lxc-log-action-action-type-name-filter v-model="name" />
    <lxc-log-action-action-type-list-filter
      v-model="selectedItems"
      :context="context"
      :header="label"
      :name="name"
    />
  </div>
</template>
