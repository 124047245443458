import RestService from "./rest.service";
import type {
  ApiListResult,
  CSRI,
  KeystoreCertificateDetailI,
  KeystoreCertificateI,
  KeystoreCertificateSubjectI,
  KeystoreCSRRequestI,
  KeystoreSelfsignedCertificateRequestI,
} from "@lxc/app-device-types";
import type { AxiosProgressEvent, AxiosRequestConfig } from "axios";
import type { FiltersSelection } from "~/types";

export class KeystoreService extends RestService {
  protected BASE_URL = "/keystore";
  protected CSR_PATH = "/csr";
  protected CERTIFICATE_PATH = "/certificate";
  protected PARSE_CERTIFICATE_PATH = "/parse-certificate";
  /**
   * Get list of certificates on Keystore
   * @param page
   * @param pageSize
   * @param search
   * @param sort
   * @return List of keystore certificates with context
   */
  getListOfCertificates(
    page: number,
    pageSize: number,
    search?: string | FiltersSelection,
    sort?: string | null,
  ): Promise<ApiListResult<KeystoreCertificateI>> {
    const params = { page, pageSize, search, sort };
    return this.$api.get(this.BASE_URL, { params });
  }

  /**
   * Get a certificate by its alias
   * @param alias Identifier of the certificate
   * @returns The certificate detail for the alias
   */
  getCertificate(alias: string): Promise<KeystoreCertificateDetailI> {
    return this.$api.get(`${this.BASE_URL}/${alias}`);
  }

  getCsr(alias: string): Promise<CSRI> {
    return this.$api.get(`${this.BASE_URL}/${alias}/csr`);
  }

  /**
   * Parse a certificate on backend
   * Do not manage progress
   * @param certificate
   */
  parseCertificate(certificate: File): Promise<KeystoreCertificateSubjectI> {
    const formData = new FormData();
    formData.append("certificate", certificate, certificate.name);

    const config: AxiosRequestConfig<FormData> = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    return this.$api.post(
      `${this.BASE_URL}${this.PARSE_CERTIFICATE_PATH}`,
      formData,
      config,
    );
  }

  /**
   * Request a self-signed certificate
   * @param certificateRequest self-signed certificate request data
   */
  generateSelfSignedCertificate(
    certificateRequest: KeystoreSelfsignedCertificateRequestI,
  ): Promise<void> {
    const config = {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
    };

    return this.$api.post(
      `${this.BASE_URL}${this.CERTIFICATE_PATH}`,
      { certificateRequest },
      config,
    );
  }

  /**
   * Request a CSR
   * @param csrRequest the CSR request data
   * @returns the CSR for the alias
   */
  generateCertificateSigningRequest(
    csrRequest: KeystoreCSRRequestI,
  ): Promise<CSRI> {
    const config = {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
    };

    return this.$api.post(
      `${this.BASE_URL}${this.CSR_PATH}`,
      { csrRequest },
      config,
    );
  }

  /**
   * Post a certificate update into keystore for an alias
   * @param alias Identifier of the certificate
   * @param progress
   * @param certificate certificate data
   * @param tags Array of objects (Root Type for Tags)
   */
  updateCertificate(
    alias: string,
    progress: Ref<number | undefined | null>,
    tags: string[],
    certificate?: File,
  ): Promise<void> {
    const formData = new FormData();
    const isUploading = certificate != null;
    if (isUploading) {
      formData.append("certificate", certificate, certificate.name);
    }

    if (tags.length) {
      tags.forEach((tag) => formData.append("tags[]", tag));
    } else {
      formData.append("tags[]", "");
    }

    const config: AxiosRequestConfig<FormData> = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        if (isUploading) {
          progress.value = progressEvent.total
            ? (progressEvent.loaded / progressEvent.total) * 100
            : 0;
        }
      },
    };

    return this.$api.put(
      `${this.BASE_URL}${this.CERTIFICATE_PATH}/${alias}`,
      formData,
      config,
    );
  }

  /**
   * Delete a key entry by alias
   * @param alias Identifier of the certificate
   */
  deleteCertificate(alias: string): Promise<void> {
    return this.$api.delete(`${this.BASE_URL}/${alias}`);
  }
}

export default new KeystoreService();
