<script setup lang="ts">
import type { OperationStatus } from "@lxc/app-device-types";
import type { DeviceModelUiConfigI } from "@lxc/app-device-types";
import type { PointExpression } from "leaflet";
import type {
  FeatureCollectionWithStyle,
  LxcMapCenter,
} from "~/components/cartography/LxcMap.model";
import { computeIconClass } from "~/components/cartography/devicesMap/deviceMapTools.utils";
import {
  EMPTY_PLACEHOLDER,
  NO_CONTENT,
} from "~/components/dtwins/dtwinsForm/LxcDtwinsFormType";
import dtwinsService from "~/services/dtwins.service";
import { useConfigStore } from "~/stores/useConfigStore";
import { useConfirmLeavePageStore } from "~/stores/useConfirmLeavePageStore";
import LxcError from "~/utils/LxcError";
import ILxcDropletDown from "~icons/lxc-custom/droplet-down";

export interface LxcDtwinsDescriptionGeolocationI {
  address: string;
  latitude: string;
  longitude: string;
  isAllowedToEdit: boolean;
  doesUpdateSucceed: boolean | undefined;
}

interface Props {
  modelValue: LxcDtwinsDescriptionGeolocationI;
  dtwinModelUid?: string;
  operationStatuses?: Record<string, OperationStatus>;
}

const props = defineProps<Props>();
const emit = defineEmits(["update:address"]);

const { t } = useI18n();
const { isCartographyEnabled } = useConfigStore();
const address: Ref<string> = ref(props.modelValue.address);
const isEditing: Ref<boolean> = ref(false);
const isSending: Ref<boolean> = ref(false);
const latLongRepresentation: Ref<string> = ref(
  t("dtwins.form.description.geolocation.attributes.latlong", {
    latitude: props.modelValue.latitude,
    longitude: props.modelValue.longitude,
  }),
);
const isEdited: ComputedRef<boolean> = computed(
  () => address.value !== props.modelValue.address,
);
const deviceModelUiConfig: Ref<DeviceModelUiConfigI | undefined> = ref();
const isLoadingModelUiConfig: Ref<boolean> = ref(true);

const fetchDeviceModelUiConfig = async () => {
  if (props.dtwinModelUid) {
    const deviceModelUiConfigResponse =
      await dtwinsService.getDeviceModelUiConfigByDeviceModelUid(
        props.dtwinModelUid,
      );
    if (!LxcError.check(deviceModelUiConfigResponse)) {
      deviceModelUiConfig.value = deviceModelUiConfigResponse;
    }
  }
};

// When the request to the backend succeed, reset the editing flag to true
watch(
  () => props.modelValue,
  (modelValue) => {
    if (modelValue.doesUpdateSucceed) {
      isEditing.value = false;
    }
    isSending.value = false;
  },
);

const shouldDisplayMap: ComputedRef<boolean> = computed(() => {
  return (
    props.modelValue.latitude !== NO_CONTENT &&
    props.modelValue.longitude !== NO_CONTENT &&
    isCartographyEnabled
  );
});

// By default, use the max zoom to easily see the point on the map.
const mapZoom: ComputedRef<object> = computed(() => {
  return {
    default: 18,
    min: 5,
    max: 20,
  };
});

// By default, center on the point.
const mapCenter: ComputedRef<LxcMapCenter> = computed(() => {
  return {
    latitude:
      props.modelValue.latitude !== NO_CONTENT
        ? parseFloat(props.modelValue.latitude)
        : undefined,
    longitude:
      props.modelValue.longitude !== NO_CONTENT
        ? parseFloat(props.modelValue.longitude)
        : undefined,
  };
});

/**
 * These magic numbers place the tip of the icon on the device's gps point.
 */
const iconAnchorMagicNumbers: PointExpression = [13.1, 32];

const mapGeoJson: ComputedRef<FeatureCollectionWithStyle> = computed(() => {
  const coordinates = [];
  if (
    props.modelValue.latitude !== NO_CONTENT &&
    props.modelValue.longitude !== NO_CONTENT
  ) {
    coordinates.push(parseFloat(props.modelValue.latitude));
    coordinates.push(parseFloat(props.modelValue.longitude));
  }
  return {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates,
        },
        properties: {
          icon: ILxcDropletDown,
          iconAnchor: iconAnchorMagicNumbers,
          overlayIcon: deviceModelUiConfig.value?.properties.svgIcon,
          class: computeIconClass(
            props.operationStatuses,
            deviceModelUiConfig.value?.properties.svgIcon,
          ),
        },
      },
    ],
  };
});

function onEdit(): void {
  isEditing.value = !isEditing.value;
}

function onSave(): void {
  isSending.value = true;
  emit("update:address", address.value);
}

function onCancel(): void {
  isEditing.value = false;
  address.value = props.modelValue.address;
}

// activate the leave page confirm message if edited
watch(
  () => isEdited.value,
  (isEdited) => useConfirmLeavePageStore().activate(isEdited),
);

onMounted(async () => {
  await fetchDeviceModelUiConfig();
  isLoadingModelUiConfig.value = false;
});
</script>

<template>
  <LxcDescriptionContainer
    :title="t(`dtwins.form.description.geolocation.title`)"
  >
    <template #left>
      <lxc-form-item
        :label="t('dtwins.form.description.geolocation.attributes.address')"
      >
        <lxc-input
          v-model="address"
          type="text"
          :placeholder="EMPTY_PLACEHOLDER"
          :disabled="!isEditing"
          style="padding-bottom: 10px"
        />
        <lxc-button
          v-if="!isEditing"
          :disabled="!modelValue.isAllowedToEdit"
          :title="t('button.edit')"
          type="secondary"
          @click="onEdit"
        >
          {{ t("button.edit") }}
        </lxc-button>
        <div v-if="isEditing" class="flex gap-2.5">
          <lxc-button
            :disabled="!isEdited"
            :title="t('button.validate')"
            @click="onSave"
          >
            <div class="flex relative">
              {{ t("button.validate") }}
              <lxc-loader v-if="isSending" class="ml-2" :size="5" />
            </div>
          </lxc-button>
          <lxc-button
            type="secondary"
            :title="t('button.cancel')"
            @click="onCancel"
          >
            {{ t("button.cancel") }}
          </lxc-button>
        </div>
      </lxc-form-item>
      <lxc-form-item
        :label="t('dtwins.form.description.geolocation.attributes.status')"
      >
        <lxc-input v-model="latLongRepresentation" type="text" read-only />
      </lxc-form-item>
    </template>
    <template #right>
      <lxc-container :px="0" :py="0" :is-loading="isLoadingModelUiConfig">
        <lxc-map
          v-if="shouldDisplayMap"
          :center="mapCenter"
          :zoom="mapZoom"
          :points="mapGeoJson"
          class="flex aspect-video ml-8"
        />
      </lxc-container>
    </template>
  </LxcDescriptionContainer>
</template>
<style lang="scss">
// This css property is define here because tailwind css doesn't allow to target a grandchildren
.default-icon {
  svg {
    path:nth-child(2) {
      color: white;
    }
  }
}
</style>
