<script lang="ts" setup>
import type { KeystoreCertificateI } from "@lxc/app-device-types";
import { useCertificatesACLRoles } from "~/composables/useCertificates";
import truststoreService from "~/services/truststore.service";
import LxcError from "~/utils/LxcError";
import {
  NotificationKey,
  showNotificationSuccess,
} from "~/utils/notifications-tools";
import ILxcLightTrash2 from "~icons/lxc-light/trash-2";

const props = defineProps<{
  certificate: KeystoreCertificateI;
}>();
const emit = defineEmits(["change"]);

const { t } = useI18n();
const { canManageCertificates } = useCertificatesACLRoles();
const [deleteConfirmDialogVisible, toggleDeleteConfirmVisible] = useToggle();

const isDeleteLoading = ref<boolean>(false);
const deleteDisabled: ComputedRef<boolean> = computed(
  () => !canManageCertificates() || isDeleteLoading.value,
);
const error = ref<LxcError | null>(null);

const onDeleteCertificateAndPropagateChange = async () => {
  error.value = null;
  isDeleteLoading.value = true;
  toggleDeleteConfirmVisible();
  const response = await truststoreService.deleteCertificate(
    props.certificate.alias,
  );

  if (LxcError.check(response)) {
    response.notify(NotificationKey.removeError);
  } else {
    showNotificationSuccess(t(NotificationKey.removeSuccess));
    emit("change");
  }

  isDeleteLoading.value = false;
};

const onDeleteCertificateClick = () => {
  toggleDeleteConfirmVisible(true);
};
</script>

<template>
  <lxc-button
    html-type="button"
    type="borderless"
    :disabled="deleteDisabled"
    :icon="!isDeleteLoading ? ILxcLightTrash2 : undefined"
    :title="t('certificates.delete')"
    class="invisible"
    @click.stop.prevent="onDeleteCertificateClick"
  >
    <lxc-loader v-if="isDeleteLoading" />
  </lxc-button>

  <lxc-confirm-modal
    :is-dialog-visible="deleteConfirmDialogVisible"
    :title="t('certificates.delete')"
    :description="
      t('certificates.list.action.delete.description', {
        name: certificate.alias,
      })
    "
    :icon="ILxcLightTrash2"
    icon-color-theme="error"
    color-theme="danger"
    @cancel="toggleDeleteConfirmVisible"
    @confirm="onDeleteCertificateAndPropagateChange"
  />
</template>
<style lang="scss" scoped>
button {
  :deep(svg) {
    height: 20px;
    width: 20px;
  }
}
</style>
