import { onMounted, onUnmounted } from "vue";
import { useRoute } from "vue-router";

/**
 * Composable used to handle the tabs navigations in a page using a side canvas
 * component as child.
 */
export function useTabsSideCanvasNavigation({
  rootPath,
}: {
  rootPath: string;
}) {
  const route = useRoute();

  /**
   * Side canvas desired state. Set to true if the side canvas should be
   * displayed.
   */
  const sideCanvasShown = ref(false);

  const handlePopState = () => {
    // The side canvas must always be displayed if the user is in the child
    // path.
    sideCanvasShown.value = route.path !== rootPath;
  };

  onMounted(() => {
    window.addEventListener("popstate", handlePopState);
  });
  onUnmounted(() => {
    window.removeEventListener("popstate", handlePopState);
  });

  return {
    sideCanvasShown,
  };
}
