<script setup lang="ts">
import { DisplayableColumns } from "./LxcFirmwaresList.type";
import { SortMode } from "@lxc/app-device-common";
import type LxcTable from "@lxc/app-device-common/src/components/LxcTable.vue";
import type { FirmwareI } from "@lxc/app-device-types";
import dayjs from "dayjs";
import { useFirmware } from "~/composables/useFirmware";
import type { SearchMode } from "~/composables/useSearch";
import { useTabsSideCanvasNavigation } from "~/composables/useTabsSideCanvasNavigation";
import { PATHS } from "~/constants/paths";
import type { FilterOptions } from "~/types";
import { Filters } from "~/types";

const props = defineProps<{
  noAction?: boolean;
  selectable?: boolean;
  defaultFilters?: Map<Filters, any>;
  searchMode?: SearchMode;
  selectedFirmware?: FirmwareI | null;
  firmwareDetail?: FirmwareI;
  columns?: DisplayableColumns[];
  triggerSearch?: boolean;
  displayInfo?: boolean;
  statusOptions?: FilterOptions;
  rangeOptions?: FilterOptions;
  useQueryParametersForPagination?: boolean;
}>();

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const emit = defineEmits([
  "update:selectedFirmware",
  "openFirmwareDetail",
  "loaded",
]);

const {
  isLoading,
  results,
  filters,
  error,
  fetchData,
  setFilter,
  onSortChange,
  onSearch,
  search,
} = useFirmware(props.searchMode, props.useQueryParametersForPagination);

const { sideCanvasShown } = useTabsSideCanvasNavigation({
  rootPath: PATHS.PARAMETERS_FIRMWARES,
});

const tableRef = ref<InstanceType<typeof LxcTable>>();

const searchQuery = computed({
  get() {
    return (filters.get(Filters.FIRMWARE_NAME_OR_VERSION) ?? "") as string;
  },
  set(searchQuery) {
    setFilter(Filters.FIRMWARE_NAME_OR_VERSION, searchQuery);
  },
});

const uuid = ref<string | undefined | null>();

/**
 * Retrieve selected firmware
 */
const setSelection = () => {
  if (tableRef.value?.data) {
    tableRef.value.setCurrentRow(
      tableRef.value.data.find(
        (row: FirmwareI) => row.uuid === props.selectedFirmware?.uuid,
      ),
    );
  }
};

watch(() => props.selectedFirmware, setSelection);

/**
 * Retrieve firmwares and set selected firmware if needed
 * @param page
 * @param pageSize
 */
async function loadData(page?: number, pageSize?: number) {
  await fetchData(page, pageSize);
  emit("loaded", results.value?.data);
  setTimeout(setSelection, 0);
}

const updateEditedFirmware = (paramFirmware: FirmwareI) => {
  if (results.value?.data) {
    const firmware = results.value.data.find(
      (currentFirmware: FirmwareI) =>
        currentFirmware.uuid === paramFirmware.uuid,
    );
    if (firmware) {
      firmware.status = paramFirmware.status;
    }
  }
};

function reloadWithContext() {
  loadData(results.value?.context?.page, results.value?.context?.pageSize);
}

const computedSelectedFirmware = computed({
  get(): FirmwareI | undefined | null {
    return props.selectedFirmware;
  },
  set(selectedFirmware: FirmwareI | undefined | null) {
    emit("update:selectedFirmware", selectedFirmware);
  },
});

/**
 * Update the selected firmware or redirect to firmware details page
 */
async function onSelectFirmware(firmware: FirmwareI) {
  if (props.selectable) {
    computedSelectedFirmware.value = firmware;
  } else {
    uuid.value = firmware.uuid;

    if (firmware?.uuid) {
      const targetUrl = {
        path: `${PATHS.PARAMETERS_FIRMWARES}/${firmware?.uuid}`,
        query: route.query,
      };
      await router.push(targetUrl);
      sideCanvasShown.value = true;
    }
  }
}

const onCloseFirmwareDetail = () => {
  uuid.value = null;
  sideCanvasShown.value = false;
  router.push({
    path: PATHS.PARAMETERS_FIRMWARES,
    query: route.query,
  });
};

const initDisplayFromRoute = () => {
  if (route.path.startsWith(PATHS.PARAMETERS_FIRMWARES)) {
    uuid.value = route?.params?.uuid as string | undefined;
    if (uuid.value) {
      sideCanvasShown.value = true;
    }
  }
};

const onDeleteFirmware = () => {
  uuid.value = null;
  sideCanvasShown.value = false;
  search();
};

if (props.defaultFilters) {
  for (const [filter, value] of props.defaultFilters) {
    setFilter(filter, value ?? "");
  }
}

watch(() => props.triggerSearch, search);

onMounted(async () => {
  onSearch(loadData);
  initDisplayFromRoute();
});
</script>

<template>
  <div class="mt-1">
    <lxc-search-bar
      v-model:search-query="searchQuery"
      :search-placeholder="t('firmware.filters.searchByNameOrVersion')"
      class="grow shrink basis-0"
      @clear="search"
      @search="search"
    />
  </div>
  <lxc-firmware-filters
    :filters="filters"
    :status-options="statusOptions"
    :range-options="rangeOptions"
    @change="setFilter"
    @enter="search"
  />
  <lxc-table
    ref="tableRef"
    min-width="72rem"
    class="mb-4"
    :data="results?.data"
    :context="results?.context"
    :is-loading="isLoading"
    :error="error?.toError()"
    clickable
    :empty-text="t('firmware.noFirmware')"
    @change-page-and-page-size="loadData"
    @row-click="onSelectFirmware"
    @sort-change="onSortChange"
  >
    <lxc-table-column
      v-if="!columns || columns?.includes(DisplayableColumns.NAME)"
      prop="name"
      :label="t('firmware.column.versionName')"
      :sort-mode="SortMode.CUSTOM"
      min-width="12rem"
    />
    <lxc-table-column
      v-if="!columns || columns?.includes(DisplayableColumns.RANGE)"
      prop="range"
      :label="t('firmware.column.type')"
      width="8rem"
    />
    <lxc-table-column
      v-if="!columns || columns?.includes(DisplayableColumns.DECLINATION)"
      prop="declination"
      :label="t('firmware.column.declination')"
      width="8rem"
    />
    <lxc-table-column
      v-if="!columns || columns?.includes(DisplayableColumns.VERSION)"
      prop="version"
      :label="t('firmware.column.version')"
      :sort-mode="SortMode.CUSTOM"
      width="12rem"
    />
    <lxc-table-column
      v-if="!columns || columns?.includes(DisplayableColumns.CREATION_DATE)"
      prop="creationDate"
      :label="t('firmware.column.creationDate')"
      :sort-mode="SortMode.CUSTOM"
      width="12rem"
    >
      <template #default="scope">
        {{
          (scope.row as FirmwareI).creationDate
            ? dayjs((scope.row as FirmwareI).creationDate).format(
                t("firmware.column.dateFormat"),
              )
            : ""
        }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      v-if="!columns || columns?.includes(DisplayableColumns.STATUS)"
      prop="status"
      :label="t('firmware.column.status')"
      class="!py-0"
      width="8rem"
    >
      <template #default="scope">
        <lxc-status-badge :status="scope.row.status" />
      </template>
    </lxc-table-column>
    <lxc-table-column v-if="!noAction" class="w-16 !py-0" width="12rem">
      <template #default="scoped">
        <div class="flex">
          <lxc-firmware-activate-action
            :firmware="scoped.row"
            @change="updateEditedFirmware"
          />
          <lxc-firmware-deactivate-action
            :firmware="scoped.row"
            @change="updateEditedFirmware"
          />
          <lxc-firmware-delete-action
            :key="scoped.row.id"
            :firmware="scoped.row"
            @change="reloadWithContext"
          />
        </div>
      </template>
    </lxc-table-column>
  </lxc-table>
  <lxc-firmware-details
    :side-canvas-shown="sideCanvasShown"
    :uuid="uuid"
    @close="onCloseFirmwareDetail"
    @delete="onDeleteFirmware"
  />

  <lxc-firmware-info
    v-if="displayInfo"
    v-model:firmware="computedSelectedFirmware"
  />
</template>

<style lang="scss" scoped>
:deep(table) {
  tbody {
    tr {
      &:hover {
        button {
          visibility: visible;
        }
      }
    }
  }
}
</style>
