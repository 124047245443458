<script lang="ts" setup>
import type { Breadcrumb } from "./breadcrumb.config";
import ILxcArrowLeft from "~icons/lxc/arrow-left";

const props = defineProps<{
  name?: string | Breadcrumb | (string | Breadcrumb)[];
  shouldNotTranslate?: boolean;
  back?: boolean;
  backCallback?: () => Promise<void>;
}>();

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const breadcrumb = route.meta.breadcrumb as Breadcrumb;

const titleAsString: ComputedRef<string> = computed((): string =>
  props.shouldNotTranslate ? props.name : t(props.name),
);

async function performBackNavigation() {
  if (props.backCallback) {
    await props.backCallback();
  } else {
    router.back();
  }
}
</script>

<template>
  <div class="h-11 flex justify-start items-center">
    <lxc-button
      v-if="props.back"
      type="tertiary"
      :title="t('button.back')"
      class="h-11 w-11 mr-4"
      @click="performBackNavigation"
    >
      <ILxcArrowLeft class="w-5 h-5 text-gray-700" />
    </lxc-button>

    <h2 class="text-3xl font-semibold text-gray-900">
      <span v-if="typeof props.name === 'string'">
        {{ titleAsString }}
      </span>
      <span v-else>
        {{ t(breadcrumb.title) }}
      </span>
    </h2>
  </div>
</template>
