<script setup lang="ts">
import { LicenseStatus } from "@lxc/app-device-types";
import type { Ref } from "vue";
import { useAcl } from "vue-simple-acl";
import type { TabNavigation } from "~/components/shared/tabs/LxcTabsWithNavigation.vue";
import { PATHS } from "~/constants/paths";
import { useConfigStore } from "~/stores/useConfigStore";
import { useLicenseStatusStore } from "~/stores/useLicenseStatusStore";
import { ACL_ROLES } from "~/types";
import { FeaturesName } from "~/types/deviceFeatures";
import ILxcPlus from "~icons/lxc/plus";

const { t } = useI18n();

const route = useRoute();
const router = useRouter();
const acl = useAcl();
const licenseStatusStore = useLicenseStatusStore();

const canManageCampaign = computed(() => acl.can(ACL_ROLES.MANAGE_CAMPAIGNS));

const disableNewCampaign = computed(() => {
  switch (route.path) {
    case PATHS.CAMPAIGNS_S4_LOCAL_STATION: {
      const { isLicenseFeatureAdvancedFleetManagementActive } =
        useLicenseStatusStore();
      return (
        !isLicenseFeatureAdvancedFleetManagementActive ||
        isBeyondLicenseDateValidity.value ||
        isBeyondLicenseLimitOfAllDeviceTypes.value
      );
    }
    default:
      return false;
  }
});

// License Check
const isBeyondLicenseDateValidity: Ref<boolean | undefined> = ref(true);
const isBeyondLicenseLimitOfAllDeviceTypes: Ref<boolean | undefined> =
  ref(true);
const checkLicenseValidityDate = (arg: boolean | undefined) =>
  (isBeyondLicenseDateValidity.value = arg);
const checkLimitOfAllDeviceTypes = (arg: boolean | undefined) =>
  (isBeyondLicenseLimitOfAllDeviceTypes.value = arg);

const { isFeatureCampaignDtwinsEnabled } = useConfigStore();

const showCampaignFunnel: Ref<boolean> = ref(false);
const showCampaignOperationMgrFunnel: Ref<boolean> = ref(false);
function showNewCampaignFunnel() {
  switch (route.path) {
    case PATHS.CAMPAIGNS_S4_LOCAL_STATION:
      showCampaignFunnel.value = true;
      break;
    case PATHS.CAMPAIGNS_DEVICES:
      showCampaignOperationMgrFunnel.value = true;
      break;
  }
}

//
// Navigation section. Logic / configuration responsible for the navigation.
//
const campaignsTabIndex = 0;
const legacyCampaignsTabIndex = 1;

const tabNavigations = computed(() => {
  const tabNavigations: TabNavigation[] = [];
  if (isFeatureCampaignDtwinsEnabled) {
    tabNavigations.push({
      index: campaignsTabIndex,
      redirectPath: PATHS.CAMPAIGNS_DEVICES,
      labelTranslationKey: "layout.menu.dtwins.label",
      forceRefresh: true,
    });
    tabNavigations.push({
      index: legacyCampaignsTabIndex,
      redirectPath: PATHS.CAMPAIGNS_S4_LOCAL_STATION,
      labelTranslationKey: "layout.menu.devices",
      forceRefresh: true,
    });
  }
  return tabNavigations;
});

watch(
  () => licenseStatusStore.licenseStatus,
  async () => {
    if (
      licenseStatusStore.licenseStatus?.statusOfLicense == undefined ||
      licenseStatusStore.licenseStatus?.statusOfLicense ==
        LicenseStatus.NOT_ACTIVATED
    ) {
      await router.replace("/");
    }
  },
);
watch(
  () => licenseStatusStore.isLicenseFeatureAdvancedFleetManagementPresent,
  async () => {
    if (
      licenseStatusStore.isLicenseFeatureAdvancedFleetManagementPresent !== true
    ) {
      await router.replace("/");
    }
  },
);
</script>

<template>
  <lxc-container class="mb-4">
    <div class="px-8">
      <lxc-license-warning-messages
        :context="FeaturesName.ADVANCED_FLEET_MGT"
        @is-beyond-expiration-date="checkLicenseValidityDate($event)"
        @is-beyond-limit-of-all-device-type="checkLimitOfAllDeviceTypes($event)"
      />
    </div>
    <div class="flex justify-between">
      <lxc-breadcrumb />
      <lxc-button
        v-if="canManageCampaign"
        :icon="ILxcPlus"
        class="h-11"
        size="large"
        type="primary"
        :title="t('campaign.newCampaign')"
        :disabled="disableNewCampaign"
        @click="showNewCampaignFunnel"
      >
        {{ t("campaign.newCampaign") }}
      </lxc-button>
      <lxc-campaign-operation-mgr-funnel
        v-model:show="showCampaignOperationMgrFunnel"
        @saved="(uid) => router.push(`${PATHS.CAMPAIGNS}/${uid}`)"
      />
      <lxc-campaign-funnel v-model:show="showCampaignFunnel" />
    </div>
    <LxcTabsWithNavigation
      v-if="tabNavigations.length > 1"
      :tab-navigations="tabNavigations"
    >
      <template #[campaignsTabIndex]>
        <LxcCampaignOperationMgrList />
      </template>
      <template #[legacyCampaignsTabIndex]>
        <LxcCampaignList />
      </template>
    </LxcTabsWithNavigation>
    <div v-else class="mt-8">
      <LxcCampaignList />
    </div>
  </lxc-container>
</template>
