<script setup lang="ts">
import type { SectorI } from "@lxc/app-device-types";
import sectorsService from "~/services/sectors.service";
import { useSectorStore } from "~/stores/useSectorStore";
import LxcError from "~/utils/LxcError";
import {
  NotificationKey,
  showNotificationSuccess,
} from "~/utils/notifications-tools";
import ILxcLightTrash2 from "~icons/lxc-light/trash-2";

const props = defineProps<{
  isVisible: boolean;
  sector: SectorI | null;
}>();
const emit = defineEmits(["change", "close"]);

const { t } = useI18n();
const { resetSectorsSelection } = useSectorStore();

/**
 * Create a sector/sub sector
 */
async function onRemoveSector() {
  if (!props.sector?.id) {
    return;
  }

  const response = await sectorsService.removeSector(props.sector.id);

  if (LxcError.check(response)) {
    response.notify(NotificationKey.removeError);
  } else {
    showNotificationSuccess(t(NotificationKey.removeSuccess));
    await resetSectorsSelection();
    emit("change");
  }
}

/**
 * Close
 * @param refresh
 */
function close(refresh = false) {
  emit("close", refresh);
}
</script>

<template>
  <lxc-confirm-modal
    :is-dialog-visible="isVisible"
    :title="t('sectors.modals.remove.title', { sectorName: sector?.label })"
    :description="t('sectors.modals.remove.description')"
    :icon="ILxcLightTrash2"
    icon-color-theme="error"
    color-theme="danger"
    @cancel="close"
    @confirm="onRemoveSector"
  />
</template>
