<script setup lang="ts">
import { SortMode } from "@lxc/app-device-common";
import type {
  DeviceTypeI,
  MobileInterfaceI,
} from "@lxc/app-device-types/dist/interfaces/mobile.interface";
import { useAcl } from "vue-simple-acl";
import LxcTagForList from "~/components/shared/LxcTagForList.vue";
import { useMobileInterface } from "~/composables/useMobileInterface";
import { PATHS } from "~/constants/paths";
import { ACL_ROLES } from "~/types";
import { formatIsoDate } from "~/utils/date-tools";

const props = defineProps<{
  interfaceFormShown: boolean;
}>();
const emits = defineEmits(["update:interfaceFormShown"]);

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const acl = useAcl();
const { isLoading, results, error, fetchData, onSearch, onSortChange } =
  useMobileInterface();

const selectedInterfaceClientId: Ref<string | undefined> = ref();

const canManageMobileApp = computed(() =>
  acl.can(ACL_ROLES.DVTM_APPM_MOBILEAPP_ADM),
);

const formSideCanvasShown = computed({
  get: () => {
    return props.interfaceFormShown;
  },
  set: (shown: boolean) => {
    emits("update:interfaceFormShown", shown);
  },
});

const formatDeviceTypes = (deviceTypes: DeviceTypeI[]): string[] => {
  const extractedDeviceTypeFriendlyName: string[] = [];
  for (const deviceType of deviceTypes) {
    extractedDeviceTypeFriendlyName.push(deviceType.friendlyName ?? "unknown");
  }

  let res: string[] = [];
  if (extractedDeviceTypeFriendlyName.length > 0) {
    res = extractedDeviceTypeFriendlyName;
  }
  return res;
};

const loadData = async (page?: number, pageSize?: number) => {
  if (page && pageSize) {
    await fetchData(page, pageSize);
  } else {
    await fetchData(
      results.value?.context.page,
      results.value?.context.pageSize,
    );
  }
};

const displaySelectedInterfaceDetails = (interfaceClientId: string) => {
  selectedInterfaceClientId.value = interfaceClientId;
  formSideCanvasShown.value = true;
};

const onSelectInterface = async (selectedInterface: MobileInterfaceI) => {
  if (selectedInterface.clientId) {
    displaySelectedInterfaceDetails(selectedInterface.clientId);
    await router.push(
      `${PATHS.PARAMETERS_APPLICATIONS_MOBILE_APP}/${selectedInterface.clientId}`,
    );
  }
};

const initDisplayFromRoute = () => {
  if (
    route.path.startsWith(PATHS.PARAMETERS_APPLICATIONS_MOBILE_APP) &&
    route.params.clientId
  ) {
    displaySelectedInterfaceDetails(route.params.clientId as string);
  }
};

const onClearInterfaceClientId = () => {
  selectedInterfaceClientId.value = undefined;
};

onSearch(async () => {
  await loadData();
  initDisplayFromRoute();
});
</script>
<template>
  <lxc-table
    min-width="71rem"
    :is-loading="isLoading"
    :data="results?.data"
    :context="results?.context"
    :error="error?.toError()"
    data-cy="interface-table"
    :clickable="true"
    :empty-text="t('applicationMgt.tabs.mobileApp.empty')"
    @change-page-and-page-size="loadData"
    @row-click="onSelectInterface"
    @sort-change="onSortChange"
  >
    <lxc-table-column
      prop="organization.name"
      :label="t('applicationMgt.tabs.mobileApp.column.organizationName')"
      width="15rem"
    />
    <lxc-table-column
      prop="name"
      :label="t('applicationMgt.tabs.mobileApp.column.name')"
      :sort-mode="SortMode.CUSTOM"
      min-width="15rem"
    />
    <lxc-table-column
      :label="t('applicationMgt.tabs.mobileApp.column.deviceTypes')"
      class="!py-0"
      width="20rem"
    >
      <template #default="scope">
        <div class="flex gap-1">
          <LxcTagForList
            :tags="formatDeviceTypes(scope.row.deviceTypes)"
            :max="3"
          />
        </div>
      </template>
    </lxc-table-column>
    <lxc-table-column
      prop="updatedAt"
      :label="t('applicationMgt.tabs.mobileApp.column.updatedAt')"
      :sort-mode="SortMode.CUSTOM"
      width="15rem"
    >
      <template #default="scope">
        {{ formatIsoDate(scope.row.updatedAt, t("dateFormat.datetime")) }}
      </template>
    </lxc-table-column>
    <lxc-table-column class="!py-0" width="6rem">
      <template #default="scope">
        <div class="flex">
          <lxc-mobile-application-delete-action
            :interface-client-id="scope.row.clientId"
            :interface-name="scope.row.name"
            :disabled="!canManageMobileApp"
            @deleted="loadData"
          />
        </div>
      </template>
    </lxc-table-column>
  </lxc-table>
  <lxc-mobile-application-form
    v-model:side-canvas-shown="formSideCanvasShown"
    :selected-interface-client-id="selectedInterfaceClientId"
    @interface-created="loadData"
    @interface-updated="loadData"
    @clear-interface-client-id="onClearInterfaceClientId"
  />
</template>
<style lang="scss" scoped>
:deep(table) {
  tbody {
    tr {
      &:hover {
        button {
          visibility: visible;
        }
      }
    }
  }
}
</style>
