<script setup lang="ts">
import type { FilterSelectionDefinition } from "~/types";

const props = defineProps<{
  searchQuery?: string | FilterSelectionDefinition;
  searchPlaceholder: string;
  class?: string | string[];
}>();
const emit = defineEmits([
  "search",
  "clear",
  "inputChange",
  "update:searchQuery",
]);

const searchInputValue = computed<string>({
  get(): string {
    if (props.searchQuery) {
      return typeof props.searchQuery === "string"
        ? props.searchQuery
        : (props.searchQuery.value as string);
    } else {
      return "";
    }
  },
  set(input?: string) {
    emit("inputChange", input);
    emit("update:searchQuery", input);
  },
});
</script>

<template>
  <lxc-form class="block" @submit.prevent="$emit('search')">
    <lxc-search-input
      v-model="searchInputValue"
      :placeholder="searchPlaceholder"
      class="grow shrink basis-0 h-11"
      prefix-class="h-[2.625rem]"
      :filter-search-txt-min-length="0"
      @clear="$emit('clear')"
    />
  </lxc-form>
  <slot />
</template>
