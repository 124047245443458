<script setup lang="ts">
export interface DeviceMetadataModule {
  location: number;
  module: string;
}

const props = defineProps<{
  modules?: any[];
}>();

const { t } = useI18n();

const modulesSorted = computed(() => {
  const modulesSorted = props.modules;

  modulesSorted?.sort(
    (module1, module2) => module1.location - module2.location,
  );

  return modulesSorted;
});
</script>

<template>
  <lxc-table :data="modulesSorted">
    <lxc-table-column
      prop="location"
      :label="t('device.additionalMetadata.modules.location')"
    />
    <lxc-table-column
      prop="module"
      :label="t('device.additionalMetadata.modules.module')"
    />
  </lxc-table>
</template>
