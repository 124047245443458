<script setup lang="ts">
import type { ApplicationI, SectorI } from "@lxc/app-device-types";
import type { Ref } from "vue";
import { onMounted } from "vue";
import { useApplication } from "~/composables/useApplication";
import { DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE } from "~/constants/constants";
import { PATHS } from "~/constants/paths";
import { router } from "~/plugins/router";
import { Filters, SectorResource } from "~/types";

const props = defineProps<{
  sector: SectorI;
}>();

const { t } = useI18n();
const {
  isLoading,
  results: applications,
  error,
  fetchData,
  setFilter,
  canManageItems,
  getDescription,
} = useApplication();

const selectedItems: Ref<any[]> = ref([]);
const displayActions = computed(() => canManageItems(props.sector));

onMounted(async () => {
  setFilter(Filters.SECTORS, [props.sector.code]);
  await fetchData();
});

async function refresh() {
  await fetchData();
  selectedItems.value = [];
}

function onRowClick(application: ApplicationI) {
  router.push(`${PATHS.APPLICATIONS}/${application.id}`);
}

async function getNextByPageAndSize(
  page: number = DEFAULT_FIRST_PAGE,
  pageSize: number = DEFAULT_PAGE_SIZE,
) {
  await fetchData(page, pageSize);
}
</script>

<template>
  <lxc-sector-toolbar
    ref="sectorToolbar"
    primary-key="id"
    :selected-items="selectedItems"
    :sector="sector"
    :resource="SectorResource.APPLICATION"
    @change="refresh"
  >
    <lxc-table-column
      prop="name"
      :label="t('application.name')"
      min-width="20rem"
    />
    <lxc-table-column
      prop="model.type"
      :label="t('application.model.type')"
      width="8rem"
    />
    <lxc-table-column
      prop="model.declination"
      :label="t('application.model.declination')"
      width="8rem"
    />
    <lxc-table-column :label="t('application.state')" width="8rem">
      <template #default="scope">
        {{ scope.row.state ? t(`application.states.${scope.row.state}`) : "" }}
      </template>
    </lxc-table-column>
  </lxc-sector-toolbar>

  <lxc-container
    :px="0"
    :py="0"
    class="mt-4"
    :is-loading="isLoading && !applications"
    :error="error"
  >
    <lxc-sectors-details-table
      v-model="selectedItems"
      primary-key="id"
      :is-loading="isLoading"
      :selection="displayActions"
      :items="applications"
      :display-actions="displayActions"
      :resource="SectorResource.APPLICATION"
      :get-description="getDescription"
      @pagination:get-next-page="getNextByPageAndSize"
      @actions:detach="(item) => $refs.sectorToolbar.openDetachModal(item)"
      @row-click="onRowClick"
    >
      <lxc-table-column
        prop="name"
        :label="t(`application.name`)"
        min-width="20rem"
      />
      <lxc-table-column
        prop="model.type"
        :label="t(`application.model.type`)"
        width="8rem"
      />
      <lxc-table-column
        prop="model.declination"
        :label="t('application.model.declination')"
        width="8rem"
      />
      <lxc-table-column :label="t('application.state')" width="20rem">
        <template #default="scope">
          {{
            scope.row.state ? t(`application.states.${scope.row.state}`) : ""
          }}
        </template>
      </lxc-table-column>
    </lxc-sectors-details-table>
  </lxc-container>
</template>
