<script setup lang="ts">
import type {
  DeviceModelUiConfigI,
  DtwinI,
  DtwinModelI,
} from "@lxc/app-device-types";
import type { Feature, Point } from "geojson";
import type { PointExpression } from "leaflet";
import type {
  FeatureCollectionWithStyle,
  GeoJsonPropertiesWithStyle,
} from "~/components/cartography/LxcMap.model";
import { computeIconClass } from "~/components/cartography/devicesMap/deviceMapTools.utils";
import { useDtwinModels } from "~/composables/useDtwinModels";
import { useDtwin, useDtwins } from "~/composables/useDtwins";
import dtwinsService from "~/services/dtwins.service";
import LxcError from "~/utils/LxcError";
import { NotificationKey } from "~/utils/notifications-tools";
import ILxcDropletDown from "~icons/lxc-custom/droplet-down";

const { getDtwinTypeLabel } = useDtwin();
const { fetchAllDtwins } = useDtwins();
const { fetchAllModelsNoPagination: fetchAllModels } = useDtwinModels();

const isLoading = ref(true);

const dtwinsModels: Ref<DtwinModelI[] | undefined> = ref();
const selectedDtwin: Ref<DtwinI | undefined> = ref();
const mapGeoJson: Ref<FeatureCollectionWithStyle | undefined> = ref();
const deviceModelUiConfigs: Ref<DeviceModelUiConfigI[]> = ref([]);

const getDeviceModelUiConfig = async (deviceModelUid: string) => {
  return await dtwinsService.getDeviceModelUiConfigByDeviceModelUid(
    deviceModelUid,
  );
};

/**
 * These magic numbers place the tip of the icon on the device's gps point.
 */
const iconAnchorMagicNumbers: PointExpression = [13.1, 32];

const dtwinsToGeoJson: (dtwins: DtwinI[]) => FeatureCollectionWithStyle = (
  dtwins: DtwinI[],
) => {
  const dtwinsGeoJsons: Feature<Point, GeoJsonPropertiesWithStyle>[] = [];
  for (const dtwin of dtwins) {
    if (
      dtwin.features?.location?.reported?.latitude !== undefined &&
      dtwin.features?.location?.reported?.longitude !== undefined
    ) {
      const deviceModelUiConfig = deviceModelUiConfigs.value.find(
        (modelUiConfig) =>
          modelUiConfig.deviceModelUid === dtwin.deviceModelUid,
      );

      dtwinsGeoJsons.push({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(dtwin.features?.location?.reported?.latitude),
            parseFloat(dtwin.features?.location?.reported?.longitude),
          ],
        },
        properties: {
          icon: ILxcDropletDown,
          iconAnchor: iconAnchorMagicNumbers,
          overlayIcon: deviceModelUiConfig?.properties.svgIcon,
          class: computeIconClass(
            dtwin.operationStatuses,
            deviceModelUiConfig?.properties.svgIcon,
          ),
          onClick() {
            if (toRaw(selectedDtwin.value?.uid) !== dtwin.uid) {
              selectedDtwin.value = dtwin;
            } else {
              selectedDtwin.value = undefined;
            }
          },
        },
      });
    }
  }
  return {
    type: "FeatureCollection",
    features: dtwinsGeoJsons,
  };
};

const onClose = () => (selectedDtwin.value = undefined);

onMounted(async () => {
  const [dtwins, models] = await Promise.all([
    fetchAllDtwins(),
    fetchAllModels(),
  ]);
  if (LxcError.check(models)) {
    models.notify(NotificationKey.error);
  } else if (LxcError.check(dtwins)) {
    dtwins.notify(NotificationKey.error);
  } else {
    dtwinsModels.value = models;
    const deviceModelUiConfigPromises: Promise<DeviceModelUiConfigI>[] = [];
    for (const dtwinModel of dtwinsModels.value) {
      deviceModelUiConfigPromises.push(getDeviceModelUiConfig(dtwinModel.uid));
    }
    const deviceModelUiConfigResolvedPromises: DeviceModelUiConfigI[] =
      await Promise.all(deviceModelUiConfigPromises);
    for (const deviceModelUiConfig of deviceModelUiConfigResolvedPromises) {
      if (!LxcError.check(deviceModelUiConfig)) {
        deviceModelUiConfigs.value.push(deviceModelUiConfig);
      }
    }
    mapGeoJson.value = dtwinsToGeoJson(dtwins);
  }

  // If an error occurred during the models or the dtwins fetch, an empty map
  // should be displayed to the user with a message describing the error.
  if (mapGeoJson.value === undefined) {
    mapGeoJson.value = dtwinsToGeoJson([]);
  }
  isLoading.value = false;
});
</script>

<template>
  <lxc-container
    :is-loading="isLoading"
    class="w-full h-full relative"
    :px="0"
    :py="0"
  >
    <lxc-devices-map-tile
      v-if="selectedDtwin"
      class="absolute mt-3 ml-14 !z-[900]"
      :dtwin="selectedDtwin"
      :model-label="getDtwinTypeLabel(dtwinsModels ?? [], selectedDtwin)"
      @close="onClose"
    />
    <lxc-devices-map-tile-no-device
      v-if="mapGeoJson && mapGeoJson.features.length <= 0"
      class="absolute inset-x-0 m-auto top-1/2 -translate-y-1/2 !z-[900]"
    />
    <lxc-map class="w-full h-full" :points="mapGeoJson" />
  </lxc-container>
</template>
<style lang="scss">
// This css property is define here because tailwind css doesn't allow to target a grandchildren
.default-icon {
  svg {
    path:nth-child(2) {
      color: white;
    }
  }
}
</style>
