import type { Ref } from "vue";
import { useRoute } from "vue-router";
import { DEFAULT_FIRST_PAGE, DEFAULT_PAGE_SIZE } from "~/constants/constants";
import type { FiltersSelection } from "~/types";
import LxcError from "~/utils/LxcError";

interface UseFetchParameters<T> {
  service: (
    page: number,
    pageSize: number,
    searchParams: string | FiltersSelection,
    sort?: string | null,
    params?: Map<string, any>,
    disableWatchOnSort?: boolean,
  ) => Promise<T>;
  searchParams: Ref<string | FiltersSelection>;
  sort: Ref<string | null | undefined>;
  useQueryParametersForPagination: boolean | undefined;
  disableWatchOnSort: boolean;
}

export function useFetchPage<T>({
  service,
  searchParams,
  sort,
  useQueryParametersForPagination,
  disableWatchOnSort,
}: UseFetchParameters<T>) {
  const results: Ref<T | null> = ref(null);
  const error: Ref<LxcError | null | undefined> = ref(null);
  const isLoading = ref(false);

  const router = useRouter();
  const route = useRoute();

  if (useQueryParametersForPagination) {
    watch(route, async () => await setPageAndPageSizeFromQueryParams());
  }

  if (!disableWatchOnSort) {
    watch(sort, async () => await fetchData());
  }

  // Hardcoded values in the common project used to choose a page size (see: https://gitlab.tools.lx-connect.com/lxconnect/lxc-app/lacroix-app-device/lxc-app-device-common/-/blob/main/src/components/LxcTable.vue#L82)
  // TODO: remove the magic numbers when the page sizes will be passed as props.
  const pageSizeMagicNumbers = [10, 20, 50];

  let currentPage: number;
  let currentPageSize: number;

  let storeFetchDataParams: Map<string, any> | undefined;

  async function fetchData(
    page?: number,
    pageSize?: number,
    params?: Map<string, any>,
  ) {
    // The input parameters can either be extracted from the `LxcTable` component (via the usage of the `page` and
    // `pageSize` function parameters) or from the URI via query parameter (in this case, `page` and `pageSize` function
    // parameters are undefined).
    if (useQueryParametersForPagination) {
      if (page === undefined) {
        page = parseInt(route.query.page as string);
      }
      if (pageSize === undefined) {
        pageSize = parseInt(route.query.pageSize as string);

        if (pageSize !== undefined) {
          pageSize = computeClosestPageSize(pageSize);
        }
      }
    }
    if (page === undefined || isNaN(page)) {
      page = DEFAULT_FIRST_PAGE;
    }
    if (
      pageSize === undefined ||
      isNaN(pageSize) ||
      !pageSizeMagicNumbers.includes(pageSize)
    ) {
      pageSize = DEFAULT_PAGE_SIZE;
    }

    if (useQueryParametersForPagination) {
      updatePageAndPageSizeQueryParamsAndRedirect(page, pageSize, params);
    }

    // Setting the current page & pageSize avoid the service to be executed after route changed, service is always called here
    currentPage = page;
    currentPageSize = pageSize;

    await callService(page, pageSize, params);
  }

  /**
   * Update the page and pageSize query parameters
   */
  function updatePageAndPageSizeQueryParamsAndRedirect(
    page: number,
    pageSize: number,
    params?: Map<string, any>,
  ) {
    storeFetchDataParams = params;

    const query = { ...route.query };
    query.page = page.toString();
    query.pageSize = pageSize.toString();

    const newRoute = {
      path: route.path,
      query,
    };

    // page and pageSize are not defined in query parameters => the route is replaced by the new one including page and pageSize query parameters
    if (route.query.page === undefined && route.query.pageSize === undefined) {
      router.replace(newRoute);
    }
    // page or pageSize are defined in query parameters => the new route is pushed for navigation history
    else {
      router.push(newRoute);
    }
  }

  /**
   * Use the page and pageSize query parameters to call the service
   * only if at least one of the parameters is present and has changed
   */
  async function setPageAndPageSizeFromQueryParams() {
    if (route.query.page || route.query.pageSize) {
      const page = parseInt(route.query.page as string);
      let pageSize = parseInt(route.query.pageSize as string);
      pageSize = computeClosestPageSize(pageSize);

      if (page !== currentPage || pageSize !== currentPageSize) {
        currentPage = page || DEFAULT_FIRST_PAGE;
        currentPageSize = pageSize || DEFAULT_PAGE_SIZE;

        await callService(currentPage, currentPageSize, storeFetchDataParams);
      }
    }
  }

  function computeClosestPageSize(pageSize: number = DEFAULT_PAGE_SIZE) {
    return pageSizeMagicNumbers.reduce(function (prev, curr) {
      return Math.abs(curr - pageSize) < Math.abs(prev - pageSize)
        ? curr
        : prev;
    });
  }

  /**
   * Call the provided service and set results and error
   */
  async function callService(
    page: number = DEFAULT_FIRST_PAGE,
    pageSize: number = DEFAULT_PAGE_SIZE,
    params?: Map<string, any>,
  ) {
    isLoading.value = true;

    const response = await service(
      page,
      pageSize,
      searchParams.value,
      sort.value,
      params,
    );

    if (LxcError.check(response)) {
      results.value = null;
      error.value = response;
    } else {
      results.value = response ?? null;
      error.value = null;
    }
    isLoading.value = false;
  }

  return {
    isLoading,
    results,
    error,
    fetchData,
  };
}
