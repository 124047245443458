<script setup lang="ts">
import ILxcChevronLeft from "~icons/lxc/chevron-left";
import ILxcChevronRight from "~icons/lxc/chevron-right";

const props = defineProps<{
  page: number;
  pageSize: number;
  totalCount: number;
}>();
const emits = defineEmits(["update:page"]);
const { t } = useI18n();
const computedPage = computed({
  get() {
    return props.page ?? [];
  },
  set(newPage?: number) {
    emits("update:page", newPage);
  },
});

const isPreviousButtonDisabled = computed(() => computedPage.value <= 1);
const isNextButtonDisabled = computed(
  () =>
    !props.totalCount || computedPage.value * props.pageSize > props.totalCount,
);

const previousPage = () => {
  computedPage.value--;
};
const nextPage = () => {
  computedPage.value++;
};
</script>

<template>
  <div class="flex">
    <lxc-button
      html-type="button"
      :title="t('button.previous')"
      type="tertiary"
      class="rounded-r-none rounded-l-lg border-r-0 !py-2.5 !px-3 disabled:!opacity-100 disabled:bg-gray-50 hover:!border-gray-300"
      :disabled="isPreviousButtonDisabled"
      @click="previousPage"
    >
      <ILxcChevronLeft width="20" height="20" class="text-gray-800" />
    </lxc-button>
    <lxc-button
      html-type="button"
      :title="t('button.next')"
      type="tertiary"
      class="rounded-l-none rounded-r-lg !py-2.5 !px-3 disabled:!opacity-100 disabled:bg-gray-50 hover:!border-gray-300"
      :disabled="isNextButtonDisabled"
      @click="nextPage"
    >
      <ILxcChevronRight width="20" height="20" class="text-gray-800" />
    </lxc-button>
  </div>
</template>
