<script lang="ts" setup>
import type { DateShortcutCallback, UniqueLabel } from "@lxc/app-device-common";
import { isWritable } from "@lxc/app-device-common";
import type { Ref } from "vue";
import type {
  FilterFormSection,
  FilterSelectionDefinition,
  FilterSelectionValue,
  FiltersSelection,
  Option,
} from "~/types";
import { FilterInputType, Visibility } from "~/types";
import type { Filters } from "~/types/filters";
import filtersUtils from "~/utils/filters.utils";
import ILxcFilterMenu from "~icons/lxc-custom/filter-menu";

const props = defineProps<{
  appliedTags?: UniqueLabel[];
  dateShortcuts?: DateShortcutCallback;
  disabled?: boolean;
  displayedPanel?: string | null;
  displayMenu?: number;
  filters: FiltersSelection;
  filtersByType?: (string | any[] | null | undefined)[];
  filterSections: FilterFormSection[];
  isAnyAppliedFilter?: boolean;
  isButtonRight?: boolean;
  isEntityLoading?: boolean;
  selectedFilters?: FiltersSelection;
  selectedTags?: UniqueLabel[];
  teleportedTags?: string;
}>();

const { t } = useI18n();

const tagDateFormatter = t("filters.date.formatter.tag");
const datePeriodSeparator = t("filters.date.period.separator");

/**
 * Initialize the filters to be sure that there is not any undefined filters
 */
function initSelectedFilters() {
  const filterSelections: FiltersSelection = new Map<
    Filters,
    FilterSelectionDefinition
  >();

  for (const filterForm of props.filterSections) {
    const filterSelectionDef = props.filters.get(filterForm.filter);

    if (filterSelectionDef != null) {
      filterSelections.set(filterForm.filter, filterSelectionDef);

      if (filterForm.additionalFilter) {
        const additionalFilterSelectionDef = props.filters.get(
          filterForm.additionalFilter,
        );

        if (additionalFilterSelectionDef) {
          filterSelections.set(
            filterForm.additionalFilter,
            additionalFilterSelectionDef,
          );
        } else {
          throw new Error(
            `The FilterSelectionDefinition of key ${filterForm.additionalFilter} is missing in the filters property.`,
          );
        }
      }
    } else {
      throw new Error(
        `The FilterSelectionDefinition of key ${filterForm.filter} is missing in the filters property.`,
      );
    }
  }
  return filterSelections;
}

const currentSelectedFilters: Ref<FiltersSelection> = ref(
  initSelectedFilters(),
);
const displayMenuLocal: Ref<number> = ref(0);
// Side canvas
const sideCanvasShown = ref(false);

const emit = defineEmits([
  "appliedFilterChange",
  "change",
  "delete",
  "discard",
  "apply",
  "enter",
  "reset",
  "deleteSelected",
  "update:displayMenu",
  "update:displayedPanel",
  "showing",
  "hidden",
  "shown",
]);

const onShowFilterClick = (event: MouseEvent) => {
  event.preventDefault();
  event.stopPropagation();
  sideCanvasShown.value = true;
  emit("showing", true);
};

const filterVisiblity = ref<Visibility>(Visibility.HIDDEN);
const periodPanelVisibliy = ref<Visibility>(Visibility.HIDDEN);

const displayMenu = computed({
  get() {
    if (props.displayMenu !== undefined) {
      return props.displayMenu;
    } else {
      return displayMenuLocal.value;
    }
  },
  set(pDisplayMenu: number) {
    if (props.displayMenu !== undefined) {
      emit("update:displayMenu", pDisplayMenu);
    } else {
      displayMenuLocal.value = pDisplayMenu;
    }
  },
});

const periodPickerFilterIdList: ComputedRef<Record<string, boolean>> = computed(
  () => {
    const list: Record<string, boolean> = {};
    props.filterSections
      .filter(
        (filterForm) => filterForm.inputType === FilterInputType.PERIOD_PICKER,
      )
      .forEach((filterForm) => (list[filterForm.id] = true));
    return list;
  },
);

const filterTypeCount = computed<number>(() => {
  const selectedBuiltinFiltersCount =
    props.filterSections.filter((filterForm) => {
      let isCurrentSelected = false;
      switch (filterForm.inputType) {
        case FilterInputType.CHECKBOX:
        case FilterInputType.RADIO:
        case FilterInputType.PERIOD_PICKER:
          isCurrentSelected =
            (currentSelectedFilters.value.get(filterForm.filter)?.value
              ?.length ?? 0) > 0;
          break;
      }
      return isCurrentSelected;
    }).length ?? 0;
  const selectedCustomFiltersCount =
    props.filtersByType?.filter(
      (filter) =>
        filter != null &&
        ((typeof filter === "string" && (filter as string).length !== 0) ||
          (Array.isArray(filter) &&
            (filter as any[]).length !== 0 &&
            (filter as any[]).every(
              (filterItem) =>
                typeof filterItem === "string" && filterItem.length !== 0,
            ))),
    ).length ?? 0;
  return selectedBuiltinFiltersCount + selectedCustomFiltersCount;
});

function applyFilter(event?: Event) {
  let isAnyCustomFilter = false;
  for (const filterForm of props.filterSections) {
    const filterSelectionDefinition: FilterSelectionDefinition | undefined =
      currentSelectedFilters.value.get(filterForm.filter);

    if (filterSelectionDefinition) {
      switch (filterForm.inputType) {
        case FilterInputType.PERIOD_PICKER:
          applyPeriodFilter(filterForm);
          break;
        case FilterInputType.CHECKBOX:
        case FilterInputType.RADIO:
          applyOptionFilter(filterForm);
          break;
        case FilterInputType.CUSTOM:
          isAnyCustomFilter = true;
          break;
        default:
      }
    }
  }

  if (isAnyCustomFilter) {
    emit("apply", event);
  } else {
    emit("enter", event);
  }
}

const apply = (event: Event) => {
  applyFilter(event);
  sideCanvasShown.value = false;
  filterVisiblity.value = Visibility.HIDING;
};

const validate = () => {
  displayMenu.value++;
};

const formDisplayedPanel = computed({
  get(): string | undefined | null {
    return props.displayedPanel;
  },
  set(newPanel?: string | null) {
    emit("update:displayedPanel", newPanel);
  },
});

const buttonWrapperClass = computed<string[]>((): string[] => {
  let vClass = [];

  if (props.isButtonRight) {
    vClass = ["flex", "items-center", "box-border", "justify-end", "pt-4"];
  } else {
    vClass.push("inline-block");
  }

  return vClass;
});

function translateOption(
  paramOption: Option | Record<string, any>,
): Option | Record<string, any> {
  if (paramOption.label != null && paramOption.value != null) {
    const { label, value } = paramOption;
    const translatedOption: Option = {
      label: t(label),
      value,
    };
    if (paramOption.disabled != null) {
      translatedOption.disabled = paramOption.disabled;
    }
    return translatedOption;
  } else {
    return paramOption;
  }
}

function translatedAllFilterOptions() {
  for (const filterForm of props.filterSections) {
    if (
      filterForm.options &&
      filterForm.translate &&
      filterForm.options?.value &&
      Array.isArray(filterForm.options.value) &&
      isWritable(filterForm, "options")
    ) {
      filterForm.options.value = filterForm.options?.value.map(translateOption);
    }
  }
}

function getOptionLabel(
  uid: string,
  option?: Option | Record<string, any>,
): string {
  return option?.label ?? uid;
}

function initOptionTagByFilter(
  uid: string,
  filterForm: FilterFormSection,
): UniqueLabel | undefined {
  let tag: UniqueLabel | undefined;

  if (filterForm.tagPrefix) {
    const option = filterForm.options?.value.find(
      (option) => option.value === uid,
    );

    if (option) {
      const optionLabel: string = getOptionLabel(uid, option);
      tag = filtersUtils.getTag(
        filterForm.tagPrefix,
        uid,
        optionLabel,
        option.disabled,
      );
    }
  }

  return tag;
}

function getPeriodTag(
  filterForm: FilterFormSection,
  serializedStartDate: FilterSelectionValue,
  serializedEndDate?: FilterSelectionValue,
): UniqueLabel | undefined {
  return filtersUtils.getPeriodTag(
    serializedStartDate as string,
    tagDateFormatter,
    filterForm.tagPrefix,
    datePeriodSeparator,
    serializedEndDate as string | undefined,
  );
}

function deletePeriodFilter(
  isApplied: boolean,
  filters: FiltersSelection,
  filterForm: FilterFormSection,
  uid?: string,
) {
  const startFilterSelection = filters.get(filterForm.filter)?.value;

  if (startFilterSelection) {
    let endFilterSelection: FilterSelectionValue | undefined =
      filterForm.additionalFilter;

    if (filterForm.additionalFilter) {
      endFilterSelection = filters.get(filterForm.additionalFilter)?.value;
    }

    const periodTag = getPeriodTag(
      filterForm,
      startFilterSelection,
      endFilterSelection,
    );

    if (!uid || periodTag?.uid === uid) {
      const startSelectedFilterSelectionDefinition =
        currentSelectedFilters.value.get(
          filterForm.filter,
        ) as FilterSelectionDefinition;
      startSelectedFilterSelectionDefinition.value = "";

      if (filterForm.additionalFilter) {
        const endFilterSelectionDefinition = currentSelectedFilters.value.get(
          filterForm.additionalFilter,
        ) as FilterSelectionDefinition;

        if (endFilterSelectionDefinition) {
          endFilterSelectionDefinition.value = "";
        }
      }

      if (isApplied) {
        applyPeriodFilter(filterForm);
      }
    }
  }
}

const onResetFilter = () => {
  let isAnyCustomFilter = false;

  // Set the current selected map to its initial state.
  // That way we will prevent to have desynchronisation between currentSelectedFilters and props values.
  currentSelectedFilters.value = initSelectedFilters();

  for (const filterForm of props.filterSections) {
    let selectedFilterSelection;

    switch (filterForm.inputType) {
      case FilterInputType.PERIOD_PICKER:
        deletePeriodFilter(true, props.filters, filterForm);
        break;
      case FilterInputType.CHECKBOX:
      case FilterInputType.RADIO:
        selectedFilterSelection = currentSelectedFilters.value.get(
          filterForm.filter,
        ) as FilterSelectionDefinition;

        if (
          filterForm.inputType === FilterInputType.CHECKBOX &&
          Array.isArray(selectedFilterSelection?.value)
        ) {
          selectedFilterSelection.value = filtersUtils.getDisabledOptionValues(
            selectedFilterSelection?.value,
            filterForm.options?.value as Option[],
          );
        } else {
          selectedFilterSelection.value = filtersUtils.findDisabledOptionValue(
            selectedFilterSelection?.value,
            filterForm.options?.value as Option[],
          ) as FilterSelectionValue;
        }

        // The parent component has to propagate the "change" events
        emit(
          "change",
          filterForm.filter,
          selectedFilterSelection.value.slice(0),
        );
        break;
      case FilterInputType.CUSTOM:
        isAnyCustomFilter = true;
        break;
      default:
    }

    filterVisiblity.value = Visibility.HIDING;
  }

  // The parent component has to propagate the "change" events

  if (isAnyCustomFilter) {
    // The parent component has apply the custom value and emit the "enter" event
    emit("reset");
  } else {
    // The parent component has to propagate the "enter" event
    applyFilter();
    emit("enter");
  }
};

const reset = (evt: Event) => {
  onResetFilter();

  emit("reset", evt);
  sideCanvasShown.value = false;
};

const onFilterShow = (event: Event) => {
  filterVisiblity.value = Visibility.SHOWN;
  emit("shown", event);
};

const onFilterHidden = (event: Event) => {
  filterVisiblity.value = Visibility.HIDDEN;
  emit("hidden", event);
};

function getMenuItemByHtmlId(
  pHtmlId?: string | null,
): FilterFormSection | undefined {
  return props.filterSections.find((item) => item.htmlId === pHtmlId);
}

const onEndSlideToPanel = (htmlId: string | null) => {
  periodPanelVisibliy.value = periodPickerFilterIdList.value[
    getMenuItemByHtmlId(htmlId)?.id ?? ""
  ]
    ? Visibility.SHOWN
    : Visibility.HIDDEN;
};

const onStartBackToMenu = () => {
  if (periodPanelVisibliy.value === Visibility.SHOWN) {
    periodPanelVisibliy.value = Visibility.HIDDEN;
  }
};

function discardFilterSelectionDefinition(
  filter: Filters,
  filterForm: FilterFormSection,
) {
  if (props.filters.has(filter)) {
    currentSelectedFilters.value.set(
      filter,
      filtersUtils.cloneFilterSelectionDefinition(
        props.filters.get(filter) as FilterSelectionDefinition,
      ),
    );
  } else {
    const defaultFilterSelection: FilterSelectionDefinition =
      filtersUtils.cloneFilterSelectionDefinition(
        currentSelectedFilters.value.get(filter) as FilterSelectionDefinition,
      );
    defaultFilterSelection.value = Array.isArray(currentSelectedFilters.value)
      ? []
      : "";
    currentSelectedFilters.value.set(filter, defaultFilterSelection);
  }
}

function discardSingleFilterSelectionDefinition(
  filter: Filters,
  filterForm: FilterFormSection,
) {
  if (props.filters.has(filter)) {
    selectSingleOptionFilter(
      filterForm,
      filtersUtils.cloneFilterSelectionDefinition(
        props.filters.get(filter) as FilterSelectionDefinition,
      ),
    );
  } else {
    const defaultFilterSelection: FilterSelectionDefinition =
      filtersUtils.cloneFilterSelectionDefinition(
        currentSelectedFilters.value.get(filter) as FilterSelectionDefinition,
      );
    defaultFilterSelection.value = Array.isArray(currentSelectedFilters.value)
      ? []
      : "";
    currentSelectedFilters.value.set(filter, defaultFilterSelection);
  }
}

function discardFilter(event: Event) {
  let isAnyCustomFilter = false;

  for (const filterForm of props.filterSections) {
    switch (filterForm.inputType) {
      case FilterInputType.PERIOD_PICKER:
        discardFilterSelectionDefinition(filterForm.filter, filterForm);

        if (filterForm.additionalFilter != null) {
          discardFilterSelectionDefinition(
            filterForm.additionalFilter,
            filterForm,
          );
        }
        break;
      case FilterInputType.CHECKBOX:
        discardFilterSelectionDefinition(filterForm.filter, filterForm);
        break;
      case FilterInputType.RADIO:
        discardSingleFilterSelectionDefinition(filterForm.filter, filterForm);
        break;
      case FilterInputType.CUSTOM:
        isAnyCustomFilter = true;
        break;
      default:
    }
  }

  displayMenu.value++;

  if (isAnyCustomFilter) {
    emit("discard", event);
  }

  filterVisiblity.value = Visibility.HIDING;
}

function deleteValueFromFilter(
  isApplied: boolean,
  filters: FiltersSelection,
  filterForm: FilterFormSection,
  uid: string,
) {
  const selectedFilterSelection = filters.get(
    filterForm.filter,
  ) as FilterSelectionDefinition;

  if (Array.isArray(selectedFilterSelection.value)) {
    const selectedIndex = selectedFilterSelection.value.indexOf(uid);

    if (selectedIndex >= 0) {
      if (
        !filterForm.options ||
        filterForm.options?.value.find(
          (option) => option.value === uid && !option.disabled,
        )
      ) {
        selectedFilterSelection.value.splice(selectedIndex, 1);
      }

      if (isApplied) {
        // The parent component has to propagate the "change" events
        emit(
          "change",
          filterForm.filter,
          selectedFilterSelection.value.slice(0),
        );
      }
    }
  } else if (
    !filterForm.options ||
    filterForm.options?.value.find(
      (option) => option.value === uid && !option.disabled,
    )
  ) {
    selectedFilterSelection.value = "";
  }
}

function updateFilterOnTagDelete(
  isApplied: boolean,
  eventDelete: "deleteSelected" | "delete",
  filters: FiltersSelection,
  label: string,
  uid?: string,
) {
  let isAnyCustomFilter = false;

  if (uid) {
    for (const filterForm of props.filterSections) {
      switch (filterForm.inputType) {
        case FilterInputType.PERIOD_PICKER:
          deletePeriodFilter(isApplied, filters, filterForm, uid);
          break;
        case FilterInputType.CHECKBOX:
        case FilterInputType.RADIO:
          deleteValueFromFilter(isApplied, filters, filterForm, uid);
          break;
        case FilterInputType.CUSTOM:
          isAnyCustomFilter = true;
          break;
        default:
      }
    }

    if (isAnyCustomFilter) {
      // The parent component has delete the custom value and emit the "enter" event
      emit(eventDelete, label, uid);
    } else if (isApplied) {
      // The parent component has to propagate the "enter" event
      emit("enter");
    }
  }
}

const onSelectedTagDeleteClick = (label: string, uid?: string) => {
  updateFilterOnTagDelete(
    false,
    "deleteSelected",
    currentSelectedFilters.value,
    label,
    uid,
  );
};

const onAppliedTagDeleteClick = (label: string, uid?: string) => {
  updateFilterOnTagDelete(true, "delete", props.filters, label, uid);
};

function buildPeriodTag(
  filters: FiltersSelection,
  filterForm: FilterFormSection,
  tags: UniqueLabel[],
) {
  const startFilterSelection = filters.get(filterForm.filter)?.value;

  if (startFilterSelection) {
    let endFilterSelection: FilterSelectionValue | undefined =
      filterForm.additionalFilter;

    if (filterForm.additionalFilter) {
      endFilterSelection = filters.get(filterForm.additionalFilter)?.value;
    }

    const periodTag = getPeriodTag(
      filterForm,
      startFilterSelection,
      endFilterSelection,
    );

    if (periodTag) {
      tags.push(periodTag);
    }
  }
}

function buildMultipleOptionTagFilter(
  filterSelectionDefinition: FilterSelectionDefinition,
  filterForm: FilterFormSection,
  tags: UniqueLabel[],
) {
  for (const uid of filterSelectionDefinition.value) {
    const multipleValueTag = initOptionTagByFilter(uid, filterForm);

    if (multipleValueTag) {
      tags.push(multipleValueTag);
    }
  }
}

function buildSingleOptionTagFilter(
  filterSelectionDefinition: FilterSelectionDefinition,
  filterForm: FilterFormSection,
  tags: UniqueLabel[],
) {
  let singleValueTag: UniqueLabel | undefined;

  if (
    Array.isArray(filterSelectionDefinition.value) &&
    filterSelectionDefinition.value.length
  ) {
    singleValueTag = initOptionTagByFilter(
      filterSelectionDefinition.value[0],
      filterForm,
    );
  } else {
    singleValueTag = initOptionTagByFilter(
      filterSelectionDefinition.value as string,
      filterForm,
    );
  }

  if (singleValueTag) {
    tags.push(singleValueTag);
  }
}

function buildFilterTags(
  filters: FiltersSelection,
  propsTags?: UniqueLabel[],
): UniqueLabel[] {
  let tags: UniqueLabel[] = [];
  let isAnyCustomFilter = false;

  for (const filterForm of props.filterSections) {
    const filterSelectionDefinition = filters.get(filterForm.filter);

    if (filterSelectionDefinition) {
      switch (filterForm.inputType) {
        case FilterInputType.PERIOD_PICKER:
          buildPeriodTag(filters, filterForm, tags);
          break;
        case FilterInputType.CHECKBOX:
          buildMultipleOptionTagFilter(
            filterSelectionDefinition,
            filterForm,
            tags,
          );
          break;
        case FilterInputType.RADIO:
          buildSingleOptionTagFilter(
            filterSelectionDefinition,
            filterForm,
            tags,
          );
          break;
        case FilterInputType.CUSTOM:
          isAnyCustomFilter = true;
          break;
        default:
      }
    }
  }

  if (isAnyCustomFilter && propsTags?.length) {
    tags = tags.concat(propsTags);
  }

  return tags;
}

const appliedFilterTags: ComputedRef<UniqueLabel[]> = computed(
  (): UniqueLabel[] => {
    return buildFilterTags(props.filters, props.appliedTags);
  },
);

const selectedFilterTags: ComputedRef<UniqueLabel[]> = computed(
  (): UniqueLabel[] => {
    return buildFilterTags(currentSelectedFilters.value, props.selectedTags);
  },
);

function checkAnyFilters(filters: FiltersSelection): boolean {
  return props.filterSections.some((filterForm) => {
    const filterSelectionDefinition = filters.get(filterForm.filter);
    return !!filterSelectionDefinition?.value.length;
  });
}

function applyPeriodFilter(filterForm: FilterFormSection) {
  const filterSelectionDefinition: FilterSelectionDefinition | undefined =
    currentSelectedFilters.value.get(filterForm.filter);

  if (filterSelectionDefinition) {
    emit(
      "change",
      filterForm.filter,
      filterSelectionDefinition.value?.slice(0) ?? "",
    );

    if (filterForm.additionalFilter) {
      const endFilterSelectionDefinition = currentSelectedFilters.value.get(
        filterForm.additionalFilter,
      );

      if (endFilterSelectionDefinition) {
        emit(
          "change",
          filterForm.additionalFilter,
          endFilterSelectionDefinition.value?.slice(0) ?? "",
        );
      }
    }
  }
}

function applyOptionFilter(filterForm: FilterFormSection) {
  const filterSelectionDefinition: FilterSelectionDefinition | undefined =
    currentSelectedFilters.value.get(filterForm.filter);

  if (filterSelectionDefinition) {
    if (Array.isArray(filterSelectionDefinition.value)) {
      if (filterForm.inputType !== FilterInputType.RADIO) {
        emit(
          "change",
          filterForm.filter,
          filterSelectionDefinition.value?.slice(0) ?? [],
        );
      } else {
        emit(
          "change",
          filterForm.filter,
          filterSelectionDefinition.value?.length
            ? [String(filterSelectionDefinition.value)]
            : [],
        );
      }
    } else {
      emit(
        "change",
        filterForm.filter,
        filterSelectionDefinition.value?.length
          ? String(filterSelectionDefinition.value)
          : "",
      );
    }
  }
}

function selectPeriodFilter(
  filters: FiltersSelection,
  filterForm: FilterFormSection,
  filterSelectionDefinition: FilterSelectionDefinition,
) {
  currentSelectedFilters.value.set(
    filterForm.filter,
    filtersUtils.cloneFilterSelectionDefinition(filterSelectionDefinition),
  );

  let endFilterSelectionDefinition: FilterSelectionDefinition | undefined;

  if (filterForm.additionalFilter) {
    endFilterSelectionDefinition = filters.get(filterForm.additionalFilter);

    if (endFilterSelectionDefinition) {
      currentSelectedFilters.value.set(
        filterForm.filter,
        filtersUtils.cloneFilterSelectionDefinition(
          endFilterSelectionDefinition,
        ),
      );
    }
  }
}

function selectMultipeOptionFilter(
  filterForm: FilterFormSection,
  appliedFilterSelectionDefinition: FilterSelectionDefinition,
) {
  const selectedFilterSelectionDefinition =
    filtersUtils.cloneFilterSelectionDefinition(
      appliedFilterSelectionDefinition,
    );

  selectedFilterSelectionDefinition.value = [];
  filterForm.options?.value?.forEach((option) => {
    if (
      Array.isArray(appliedFilterSelectionDefinition.value) &&
      Array.isArray(selectedFilterSelectionDefinition.value) &&
      appliedFilterSelectionDefinition.value.includes(option.value)
    ) {
      selectedFilterSelectionDefinition.value.push(String(option.value));
    }
  });

  currentSelectedFilters.value.set(
    filterForm.filter,
    selectedFilterSelectionDefinition,
  );
}

function selectSingleOptionFilter(
  filterForm: FilterFormSection,
  appliedFilterSelectionDefinition: FilterSelectionDefinition,
) {
  const selectedFilterSelectionDefinition =
    filtersUtils.cloneFilterSelectionDefinition(
      appliedFilterSelectionDefinition,
    );
  const isAppliedValueArray = Array.isArray(
    appliedFilterSelectionDefinition.value,
  );
  selectedFilterSelectionDefinition.value = isAppliedValueArray ? [] : "";

  filterForm.options?.value?.forEach((option) => {
    if (!isAppliedValueArray) {
      if (appliedFilterSelectionDefinition.value === option.value) {
        selectedFilterSelectionDefinition.value = String(option.value);
      }
    } else if (
      appliedFilterSelectionDefinition != null &&
      appliedFilterSelectionDefinition.value.length !== 0 &&
      appliedFilterSelectionDefinition.value[0] === (option.value as string)
    ) {
      selectedFilterSelectionDefinition.value = String(option.value);
    }
  });

  currentSelectedFilters.value.set(
    filterForm.filter,
    selectedFilterSelectionDefinition,
  );
}

const onAppliedFilterChange = (
  filterForm: FilterFormSection,
  appliedFilterSelectionDefinition?: FilterSelectionDefinition,
) => {
  let isAnyCustomFilter = false;
  if (appliedFilterSelectionDefinition) {
    switch (filterForm.inputType) {
      case FilterInputType.PERIOD_PICKER:
        selectPeriodFilter(
          props.filters,
          filterForm,
          appliedFilterSelectionDefinition,
        );
        break;
      case FilterInputType.CHECKBOX:
        selectMultipeOptionFilter(filterForm, appliedFilterSelectionDefinition);
        break;
      case FilterInputType.RADIO:
        selectSingleOptionFilter(filterForm, appliedFilterSelectionDefinition);
        break;
      case FilterInputType.CUSTOM:
        isAnyCustomFilter = true;
        break;
    }
  }

  if (isAnyCustomFilter) {
    emit("appliedFilterChange");
  }
};

const onSelectedFilterChange = (
  filterForm: FilterFormSection,
  propFilterSelectionDefinition?: FilterSelectionDefinition,
) => {
  if (
    propFilterSelectionDefinition !== undefined &&
    currentSelectedFilters.value !== undefined
  ) {
    switch (filterForm.inputType) {
      case FilterInputType.PERIOD_PICKER:
        selectPeriodFilter(
          currentSelectedFilters.value,
          filterForm,
          propFilterSelectionDefinition,
        );
        break;
      case FilterInputType.CHECKBOX:
        selectMultipeOptionFilter(filterForm, propFilterSelectionDefinition);
        break;
      case FilterInputType.RADIO:
        selectSingleOptionFilter(filterForm, propFilterSelectionDefinition);
        break;
      case FilterInputType.CUSTOM:
      default:
    }
  }
};

const checkAnySelectedFilters: ComputedRef<boolean> = computed(() => {
  const isAnyEmbeddedFilters = checkAnyFilters(currentSelectedFilters.value);
  const isAnyCustomFilter = props.selectedFilters
    ? checkAnyFilters(props.selectedFilters)
    : false;
  return isAnyEmbeddedFilters || isAnyCustomFilter;
});

const checkAnyAppliedFilters: ComputedRef<boolean> = computed(() =>
  checkAnyFilters(props.filters),
);

translatedAllFilterOptions();

for (const filterForm of props.filterSections) {
  const appliedFilter = props.filters.get(filterForm.filter);
  watch(
    () => appliedFilter,
    (newAppliedFilter) => onAppliedFilterChange(filterForm, newAppliedFilter),
    { deep: true },
  );
  let additionalAppliedFilter: FilterSelectionDefinition | undefined;

  if (filterForm.additionalFilter !== undefined) {
    additionalAppliedFilter = props.filters.get(filterForm.additionalFilter);
    watch(
      () => additionalAppliedFilter,
      (newAppliedFilter) => onAppliedFilterChange(filterForm, newAppliedFilter),
      { deep: true },
    );
  }

  const propsSelectedFilter = props.selectedFilters?.get(filterForm.filter);

  if (propsSelectedFilter) {
    watch(
      () => propsSelectedFilter,
      (newSelectedFilter) =>
        onSelectedFilterChange(filterForm, newSelectedFilter),
      { deep: true },
    );
  }

  if (currentSelectedFilters.value) {
    const selectedFilter = currentSelectedFilters.value.get(filterForm.filter);
    watch(
      () => selectedFilter,
      (newSelectedFilter) =>
        onSelectedFilterChange(filterForm, newSelectedFilter),
      { deep: true },
    );
    let additionalSelectedFilter: FilterSelectionDefinition | undefined;

    if (filterForm.additionalFilter !== undefined) {
      additionalSelectedFilter = currentSelectedFilters.value.get(
        filterForm.additionalFilter,
      );
      watch(
        () => additionalSelectedFilter,
        (newAdditionalSelectedFilter) =>
          onSelectedFilterChange(filterForm, newAdditionalSelectedFilter),
        { deep: true },
      );

      const additionalPropsSelectedFilter = props.selectedFilters?.get(
        filterForm.additionalFilter,
      );
      if (additionalPropsSelectedFilter) {
        watch(
          () => additionalPropsSelectedFilter,
          (newAdditionalPropsFilter) =>
            onSelectedFilterChange(filterForm, newAdditionalPropsFilter),
          { deep: true },
        );
      }
    }
  }
}

onMounted(() => {
  for (const filterForm of props.filterSections) {
    onAppliedFilterChange(filterForm, props.filters.get(filterForm.filter));

    if (filterForm.additionalFilter !== undefined) {
      onAppliedFilterChange(
        filterForm,
        props.filters.get(filterForm.additionalFilter),
      );
    }
  }
});
</script>

<template>
  <div :class="buttonWrapperClass">
    <lxc-button
      html-type="button"
      type="tertiary"
      :title="t('filters.buttonLabel')"
      :disabled="props.disabled ?? false"
      class="leading-[1.0em] text-base h-11"
      @click="onShowFilterClick"
    >
      <div class="flex align-middle">
        <span class="filter-icon text-primary-700 pr-2">
          <ILxcFilterMenu class="w-5 h-5 mt-1" />
        </span>
        <span class="content-center">
          {{ t("filters.buttonLabel") }}
        </span>
      </div>
    </lxc-button>
  </div>

  <template v-if="checkAnyAppliedFilters">
    <template v-if="teleportedTags">
      <Teleport :to="teleportedTags">
        <div class="flex items-center justify-end my-4">
          <lxc-tag-set
            deletable
            type="primary"
            :data="appliedFilterTags"
            :delete-tooltip="t('filters.deleteSelectedFilter')"
            :is-loading="isEntityLoading"
            @delete="onAppliedTagDeleteClick"
          />
          <lxc-reset-filters-hyperlink
            :applied-tags="appliedFilterTags.length"
            @click="onResetFilter"
          />
        </div>
      </Teleport>
    </template>
    <template v-else>
      <div class="flex items-center justify-end my-4">
        <lxc-tag-set
          deletable
          type="primary"
          :data="appliedFilterTags"
          :delete-tooltip="t('filters.deleteSelectedFilter')"
          :is-loading="isEntityLoading"
          @delete="onAppliedTagDeleteClick"
        />
        <lxc-reset-filters-hyperlink
          :applied-tags="appliedFilterTags.length"
          @click="onResetFilter"
        />
      </div>
    </template>
  </template>

  <lxc-side-canvas
    v-model:show="sideCanvasShown"
    :header="t('filters.header')"
    :close-tooltip="t('filters.close')"
    body-class="!pt-10 !px-0 !pb-0"
    @discard="discardFilter"
    @hidden="onFilterHidden"
    @shown="onFilterShow"
  >
    <lxc-slide-menu
      v-model:display-menu="displayMenu"
      v-model:displayed-panel="formDisplayedPanel"
      :menu-items="props.filterSections"
      @end-slide-to-panel="onEndSlideToPanel"
      @start-back-to-menu="onStartBackToMenu"
    >
      <template v-if="checkAnySelectedFilters" #summary>
        <div class="mb-6">
          <lxc-tag-set
            type="primary"
            border-visible
            deletable
            :data="selectedFilterTags"
            :delete-tooltip="t('filters.deleteSelectedFilter')"
            @delete="onSelectedTagDeleteClick"
          />
        </div>
      </template>

      <template
        v-for="filterForm in props.filterSections"
        :key="filterForm.id"
        #[filterForm.id]
      >
        <div
          v-if="
            filterForm.inputType === FilterInputType.CHECKBOX &&
            currentSelectedFilters.get(filterForm.filter) !== undefined
          "
          class="rounded-lg bg-white text-gray-900 p-6"
        >
          <lxc-async-status
            :empty="!filterForm.options?.value.length"
            :empty-message="filterForm.emptyOptionsText"
            :loading="filterForm.loading?.value"
            :error="filterForm.loadingError?.value"
          >
            <lxc-checkbox
              v-for="option in filterForm.options?.value"
              :key="option.value"
              v-model="currentSelectedFilters.get(filterForm.filter).value"
              :value="option.value"
              :label="option.label"
              :disabled="option.disabled"
              class="mb-2"
            />
          </lxc-async-status>
        </div>
        <div
          v-else-if="
            filterForm.inputType === FilterInputType.RADIO &&
            currentSelectedFilters.get(filterForm.filter) !== undefined
          "
          class="rounded-lg bg-white text-gray-900 p-6"
        >
          <lxc-async-status
            :empty="!filterForm.options?.value.length"
            :empty-message="filterForm.emptyOptionsText"
            :loading="filterForm.loading?.value"
            :error="filterForm.loadingError?.value"
          >
            <lxc-radio
              v-for="(option, n) in filterForm.options?.value"
              :key="n"
              v-model="currentSelectedFilters.get(filterForm.filter).value"
              :name="`radio-${filterForm.id}`"
              :value="option.value"
              :label="option.label"
              :disabled="option.disabled"
              class="mb-2"
            />
          </lxc-async-status>
        </div>
        <template
          v-else-if="
            filterForm.inputType === FilterInputType.PERIOD_PICKER &&
            filterForm.additionalFilter !== undefined &&
            currentSelectedFilters.get(filterForm.filter) !== undefined &&
            currentSelectedFilters.get(filterForm.additionalFilter) !==
              undefined
          "
        >
          <lxc-period-filter
            v-model:start-date="
              currentSelectedFilters.get(filterForm.filter).value
            "
            v-model:end-date="
              currentSelectedFilters.get(filterForm.additionalFilter).value
            "
            :filter-panel-visibility="filterVisiblity"
            :visibility="periodPanelVisibliy"
            :shortcuts="dateShortcuts"
          />
        </template>
        <template v-else>
          <slot :name="filterForm.id" />
        </template>
      </template>

      <template
        v-for="menuItem in props.filterSections"
        :key="menuItem.id"
        #[menuItem.footerId]
      >
        <lxc-filter-button-bar>
          <div class="flex justify-end gap-x-6 gap-y-4">
            <lxc-button
              :title="t('filters.validateFilter')"
              :disabled="props.disabled"
              class="whitespace-nowrap basis-1/2 shrink-0"
              html-type="button"
              @click="validate"
            >
              {{ t("filters.validateFilter") }}
            </lxc-button>
          </div>
        </lxc-filter-button-bar>
      </template>

      <template #menu-footer>
        <lxc-filter-button-bar>
          <div class="grid grid-cols-[max-content_auto] gap-x-6 gap-y-4">
            <lxc-button
              :title="t('filters.resetFilter')"
              :disabled="props.disabled"
              class="whitespace-nowrap"
              html-type="button"
              type="tertiary"
              @click="reset"
            >
              {{ t("filters.resetFilter", filterTypeCount ?? 0) }}
            </lxc-button>
            <lxc-button
              :title="t('filters.apply', { count: filterTypeCount ?? 0 })"
              :disabled="props.disabled"
              class="whitespace-nowrap"
              html-type="button"
              @click="apply"
            >
              {{ t("filters.apply", { count: filterTypeCount ?? 0 }) }}
            </lxc-button>
          </div>
        </lxc-filter-button-bar>
      </template>
    </lxc-slide-menu>
  </lxc-side-canvas>
</template>

<style lang="scss" scoped>
.filter-icon {
  :deep(svg) {
    display: inline;
    vertical-align: baseline;
  }
}
</style>
