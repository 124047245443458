<script setup lang="ts">
import { LicenseStatus } from "@lxc/app-device-types";
import { BreadcrumbConfig } from "~/components/shared/breadcrumb/breadcrumb.config";
import type { TabNavigation } from "~/components/shared/tabs/LxcTabsWithNavigation.vue";
import { useAppInterface } from "~/composables/useAppInterface";
import { useLicenseStatus } from "~/composables/useLicenseStatus";
import { useTabsSideCanvasNavigation } from "~/composables/useTabsSideCanvasNavigation";
import { PATHS } from "~/constants/paths";
import { useLicenseStatusStore } from "~/stores/useLicenseStatusStore";
import { FeaturesName } from "~/types/deviceFeatures";
import ILxcPlus from "~icons/lxc/plus";

const isLoading = ref(false);

const { t } = useI18n();
const {
  canViewMobileApp,
  canManageMobileApp,
  canManageThirdPartyApps,
  canViewThirdPartyApps,
} = useAppInterface();
const { getLicenseStatus } = useLicenseStatus();

const { sideCanvasShown: mobileApplicationFormShown } =
  useTabsSideCanvasNavigation({
    rootPath: PATHS.PARAMETERS_APPLICATIONS_MOBILE_APP,
  });

const { sideCanvasShown: thirdPartyApplicationFormShown } =
  useTabsSideCanvasNavigation({
    rootPath: PATHS.PARAMETERS_APPLICATIONS_THIRD_PART_APP,
  });

const licenseStatusStore = useLicenseStatusStore();
const disableCreateActivateInterfaceButtons = computed(() => {
  return (
    (licenseStatusStore.licenseStatus?.datasharingFeature &&
      (licenseStatusStore.licenseStatus.datasharingFeature
        .currentNumberOfInterfaces >=
        licenseStatusStore.licenseStatus.datasharingFeature
          .maxNumberOfInterfaces ||
        licenseStatusStore.licenseStatus.statusOfLicense ===
          LicenseStatus.EXPIRED_DATE)) ??
    false
  );
});

const displayThirdPartyAppsTabs: Ref<boolean | undefined> = ref();

async function fetchLicenseStatus() {
  isLoading.value = true;
  await getLicenseStatus();
  isLoading.value = false;
}

const newInterfaceDisabled = computed(() => {
  if (selectedTabIndex.value === mobileApplicationTabIndex) {
    return !canManageMobileApp();
  } else if (selectedTabIndex.value === thirdPartyApplicationTabIndex) {
    return (
      !canManageThirdPartyApps() || disableCreateActivateInterfaceButtons.value
    );
  }
  return true;
});

const newInterfaceTooltip = computed(() => {
  return newInterfaceDisabled.value &&
    selectedTabIndex.value === thirdPartyApplicationTabIndex &&
    canManageThirdPartyApps()
    ? t("applicationMgt.tabs.thirdPartyApp.licenseMessage.disableNewInterface")
    : t("applicationMgt.newInterface");
});

const onNewInterfaceButtonClick = () => {
  if (isMobileApplicationTabSelected.value) {
    mobileApplicationFormShown.value = true;
    thirdPartyApplicationFormShown.value = false;
  } else if (isThirdPartyApplicationTabSelected.value) {
    mobileApplicationFormShown.value = false;
    thirdPartyApplicationFormShown.value = true;
  }
};

//
// Navigation section. Logic / configuration responsible for the navigation.
//
const mobileApplicationTabIndex = 0;
const thirdPartyApplicationTabIndex = 1;

const tabNavigations = computed(() => {
  const tabNavigations: TabNavigation[] = [];
  if (canViewMobileApp()) {
    tabNavigations.push({
      index: mobileApplicationTabIndex,
      redirectPath: PATHS.PARAMETERS_APPLICATIONS_MOBILE_APP,
      labelTranslationKey: "applicationMgt.tabs.mobileApp.title",
    });
  }
  if (canViewThirdPartyApps() && displayThirdPartyAppsTabs.value) {
    tabNavigations.push({
      index: thirdPartyApplicationTabIndex,
      redirectPath: PATHS.PARAMETERS_APPLICATIONS_THIRD_PART_APP,
      labelTranslationKey: "applicationMgt.tabs.thirdPartyApp.title",
    });
  }
  return tabNavigations;
});

// The current tab index is stored here as it is needed to display buttons above the tab components.
const selectedTabIndex: Ref<number | undefined> = ref();

const isMobileApplicationTabSelected = computed(
  (): boolean => selectedTabIndex.value === mobileApplicationTabIndex,
);

const isThirdPartyApplicationTabSelected = computed(
  (): boolean => selectedTabIndex.value === thirdPartyApplicationTabIndex,
);

//
// Lifecycle Hooks. See: https://vuejs.org/api/composition-api-lifecycle
//
onMounted(fetchLicenseStatus);
watch(
  () => licenseStatusStore.licenseStatus,
  async () => {
    displayThirdPartyAppsTabs.value =
      licenseStatusStore.isLicenseFeatureDataSharingPresent &&
      licenseStatusStore.licenseStatus?.statusOfLicense !==
        LicenseStatus.NOT_ACTIVATED;
  },
);
watch(selectedTabIndex, () => {
  mobileApplicationFormShown.value = false;
  thirdPartyApplicationFormShown.value = false;
});
</script>
<template>
  <lxc-container>
    <div class="flex justify-between">
      <lxc-breadcrumb :name="BreadcrumbConfig.APPLICATION_MGT.title" readonly />
      <lxc-button
        :icon="ILxcPlus"
        :title="newInterfaceTooltip"
        class="self-end"
        html-type="button"
        type="primary"
        :disabled="newInterfaceDisabled"
        @click="onNewInterfaceButtonClick"
      >
        {{ t("applicationMgt.newInterface") }}
      </lxc-button>
    </div>
    <LxcTabsWithNavigation
      v-if="!isLoading"
      :tab-navigations="tabNavigations"
      @update:selected-tab-index="
        (newSelectedTabIndex) => (selectedTabIndex = newSelectedTabIndex)
      "
    >
      <template #[mobileApplicationTabIndex]>
        <lxc-mobile-application-list
          v-model:interface-form-shown="mobileApplicationFormShown"
        />
      </template>
      <template #[thirdPartyApplicationTabIndex]>
        <lxc-license-warning-messages :context="FeaturesName.DATA_SHARING" />
        <lxc-third-party-application-list
          v-model:interface-form-shown="thirdPartyApplicationFormShown"
          :disable-activate-interface-button="
            disableCreateActivateInterfaceButtons
          "
        />
      </template>
    </LxcTabsWithNavigation>
  </lxc-container>
</template>
