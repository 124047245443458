<script setup lang="ts">
import ILxcChevronDown from "~icons/lxc/chevron-down";
import ILxcChevronRight from "~icons/lxc/chevron-right";

interface Props {
  /** Toggle state. If set to true, container content is displayed. */
  modelValue?: boolean;
  /** Toggle title. If not defined, display a default see more title. */
  title?: string;
}

const { modelValue = true, title } = defineProps<Props>();
const emit = defineEmits(["update:modelValue"]);

const { t } = useI18n();
const isDisplayed = computed({
  get() {
    return modelValue;
  },
  set(isDisplayed: boolean) {
    emit("update:modelValue", isDisplayed);
  },
});
const onToggle = () => {
  isDisplayed.value = !isDisplayed.value;
};
const toggleTitle = computed(() => {
  return title ?? t("input.information.more");
});
</script>

<template>
  <div class="flex justify-between clickable select-none" @click="onToggle">
    <span class="font-bold" :title="toggleTitle">{{ toggleTitle }}</span>
    <ILxcChevronDown v-if="isDisplayed"></ILxcChevronDown>
    <ILxcChevronRight v-else></ILxcChevronRight>
  </div>
  <div v-if="isDisplayed" class="pt-2">
    <slot />
  </div>
</template>
