import type { ApiListResult, PaginatedListI } from "@lxc/app-device-types";
import LxcError from "~/utils/LxcError";

export interface FetchCallbacksI<T> {
  fetchApiListResult?: (
    page: number,
    pageSize: number,
  ) => Promise<ApiListResult<T> | LxcError>;
  fetchPaginatedList?: (
    page: number,
    pageSize: number,
  ) => Promise<PaginatedListI<T> | LxcError>;
}

/**
 * Fetch all items from a service that return PaginatedListI
 * @deprecated This return is deprecated. We should migrate endpoints that return this type.
 * @param callback
 */
export async function fetchAllPaginatedList<T>(
  callback: (
    page: number,
    ageSize: number,
  ) => Promise<PaginatedListI<T> | LxcError>,
): Promise<T[] | LxcError> {
  return await fetchAll({
    fetchPaginatedList: (page: number, pageSize: number) =>
      callback(page, pageSize),
  });
}

/**
 * Fetch all items from a service that returns ApListResult
 * @param callback
 */
export async function fetchAllApiListResult<T>(
  callback: (
    page: number,
    pageSize: number,
  ) => Promise<ApiListResult<T> | LxcError>,
): Promise<T[] | LxcError> {
  return await fetchAll({
    fetchApiListResult: (page: number, pageSize: number) =>
      callback(page, pageSize),
  });
}

async function fetchAll<T>({
  fetchApiListResult,
  fetchPaginatedList,
}: FetchCallbacksI<T>): Promise<T[] | LxcError> {
  let page: number = 1;
  const pageSize: number = 100;
  let numberOfItems: number = 0;
  let totalCount: number = 0;
  let doesAllItemsFetched: boolean = false;
  const itemList: T[] = [];

  while (!doesAllItemsFetched) {
    if (fetchApiListResult) {
      const response = await fetchApiListResult(page, pageSize);

      if (LxcError.check(response)) {
        doesAllItemsFetched = true;
        return response;
      }

      itemList.push(...response.data);
      numberOfItems += response.context.pageSize;
      if (page === 1) {
        totalCount = response.context.totalCount;
      }
    }
    if (fetchPaginatedList) {
      const response = await fetchPaginatedList(page, pageSize);

      if (LxcError.check(response)) {
        doesAllItemsFetched = true;
        return response;
      }

      itemList.push(...response.results);
      numberOfItems += response.pagination.pageSize;
      if (page === 1) {
        totalCount = response.pagination.totalCount;
      }
    }
    page++;
    if (numberOfItems >= totalCount) {
      doesAllItemsFetched = true;
    }
  }
  return itemList;
}
