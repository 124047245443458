import type { OperationStatus } from "@lxc/app-device-types";
import { computeOperationStatusesColor } from "~/components/dtwins/dtwinsList/metastatus/LxcDtwinsListMetadataStatus.helper";

export const computeIconClass = (
  dtwinOperationStatuses?: Record<string, OperationStatus>,
  overlayIcon?: string,
) => {
  let iconClass: string = `text-${computeOperationStatusesColor(dtwinOperationStatuses)}`;
  if (!overlayIcon) {
    iconClass = iconClass.concat(" default-icon");
  }
  return iconClass;
};
